<template>
  <isc-modal-alert
    v-model="isNoAuth"
    :title="titleNoAuth"
    :paragraph="paragraph"
    :icon="icon"
    :iconColor="iconColor"
    :showBtn="showBtn"
    :showBtnClose="false"
    :showMainIcon="showMainIcon"
    @clicked="redirectToLogin"
  />
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import IscModalAlert  from "./IscModalAlert.vue";
import { alertSetting }  from "../../mixins/alertSetting";
export default {
  name: "NoAuthModal",
  components: {
    IscModalAlert
  },
  mixins: [ alertSetting ],
  data(){
    return {
      titleNoAuth: ""
    }
  },
  computed: {
    ...mapState("global/util", [
      "isNoAuth",
    ]),
    ...mapGetters("global/util", ["getMsgNoAuth"]),
  },
  watch: {
    getMsgNoAuth: {
      handler: function (data) {
        this.titleNoAuth = data.title;
        this.errorModal(data.message);
      },
      immediate: true, // Trigger the watcher immediately on component mount, if needed
    },
  },
  methods: {
    ...mapActions({
      closeModal: "global/util/CLOSE_MODAL_NO_AUTH",
    }),
    redirectToLogin() {
      this.closeModal();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = `${process.env.URL_MONITOR}/login`;
    },
  },
};
</script>

<style scoped >
</style>
