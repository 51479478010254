var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview-question__checkbox" }, [
    _c("label", { staticClass: "preview-question__label" }, [
      _vm._v(_vm._s(_vm.item.question) + " "),
      _vm.item.required ? _c("span", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pa-0" },
      _vm._l(_vm.item.answers, function (answer) {
        return _c(
          "li",
          {
            key: answer.id,
            staticClass: "list-style-none d-flex align-center mt-3",
          },
          [
            _c("input", {
              staticClass: "preview-question__item-radio mr-2",
              attrs: {
                type: "radio",
                name: "rdb-" + _vm.item.id,
                id: "rdb-" + _vm.item.id + "-" + answer.id,
              },
            }),
            _vm._v(" "),
            _c("label", {
              attrs: { for: "rdb-" + _vm.item.id + "-" + answer.id },
              on: {
                click: function ($event) {
                  return _vm.setAnswerRadioButtons(
                    _vm.item.id,
                    _vm.item.idQuestionDb,
                    _vm.item.question,
                    answer.text,
                    _vm.item.required
                  )
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "preview-question__item-label" }, [
              _vm._v(_vm._s(answer.text)),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }