export default {
  SET_TEXTS: (state, texts) => {
    state.texts = texts;
  },

  SET_FONT_FAMILY: (state, family) => {
    state.fontFamily = family;
  },
  SET_FONT_COLOR: (state, color) => {
    state.colorFont = color;
  },
  SET_FONT_SIZE: (state, size) => {
    state.fontSize = size;
  },
  SET_FONT_WEIGHT: (state, weight) => {
    state.fontWeightD = weight;
    state.fontWeight = weight;
  },
  SET_FONT_CURSIVE: (state, cursive) => {
    state.fontCursiveD = cursive;
    state.fontCursive = cursive;
  },
  SET_FONT_UNDERLINE: (state, underline) => {
    state.fontUnderlineD = underline;
    state.fontUnderline = underline;
  },
  SET_FONT_ALIGNC: (state, align) => {
    state.fontAlignC = align;
    state.fontAlignL = false;
    state.fontAlignR = false;
    state.fontAlignCD = align;
    state.fontAlignLD = false;
    state.fontAlignRD = false;
  },
  SET_FONT_ALIGNL: (state, align) => {
    state.fontAlignL = align;
    state.fontAlignC = false;
    state.fontAlignR = false;
    state.fontAlignLD = align;
    state.fontAlignCD = false;
    state.fontAlignRD = false;
  },
  SET_FONT_ALIGNR: (state, align) => {
    state.fontAlignR = align;
    state.fontAlignL = false;
    state.fontAlignC = false;
    state.fontAlignRD = align;
    state.fontAlignCD = false;
    state.fontAlignLD = false;
  },
};
