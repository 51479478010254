<template>
  <div class="logo">
    <div class="logo__container-img">
      <img
        v-if="message.logo"
        class="logo__img"
        :src="message.logo"
        alt="logo"
      />
    </div>
    <span class="text-center d-block logo__title">{{ message.title }}</span>
    <span class="text-center d-block logo__description">{{ message.description }}</span>
  </div>
</template>

<script>
export default {
  name: "PreviewLogo",
  props: {
    message: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style scoped lang="scss">
.logo {
  flex-direction: column;
  display: flex;
  align-items: center;
  &__container-img {
    align-self: center;
    padding: 32px 0 30px 0;
    width: 41%;
  }
  &__img {
    height: auto;
    width: 100%;
  }
  &__title{
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #2C3852;
    margin-bottom: 17px;
  }
  &__description{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #2C3852;
  }
}
</style>
