import saved from "./saved/index";
import util from "./util/index"
import redoundo from "./redoundo/index";
import redoundoAddon from "./redoundoAddon/index";
import redoundoAddonImage from "./redoundoAddonImage/index";
import auth from "./auth/index";

export default {
  modules: {
    saved,
    util,
    redoundo,
    redoundoAddon,
    redoundoAddonImage,
    auth
  },
  namespaced: true,
};
