export default {
  texts: [],
  read: {},
  fontFamily: "",
  colorFont: "",
  fontSize: "",
  fontWeight: "",
  fontWeightD: "",
  fontCursive: "",
  fontCursiveD: "",
  fontUnderline: "",
  fontUnderlineD: "",
  fontAlignC: "",
  fontAlignL: "",
  fontAlignR: "",
  fontAlignCD: "",
  fontAlignLD: "",
  fontAlignRD: "",
};
