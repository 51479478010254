var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-select", {
    staticClass: "select-input",
    attrs: {
      "hide-details": "",
      dense: "",
      flat: "",
      outlined: "",
      placeholder: "Search",
      label: "Select",
      solo: "",
      items: _vm.items,
      "item-value": _vm.id,
      "item-text": _vm.text,
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }