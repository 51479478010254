import gql from "graphql-tag";
//import { ADDON, COMMON_VAR } from '../../folders/graphql/variables';
import { LEAD } from "./variables";
export const CREATE_LEAD = gql(`
  mutation create($lead: LeadDTO!){
    lead: createLead(lead: $lead) {
      ${LEAD}
    }
  }
`);
/* export const UPDATE_ADDON = gql(`
  mutation update( $id: Int!, $addon: AddonsDTO!){
    addon: updateAddon( id: $id, addon: $addon) {
      ${ADDON}
      ${COMMON_VAR}
      questions {
				${QUESTION}
			}
    }
  }
`); */
