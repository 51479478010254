import gql from "graphql-tag";
import { BACKGROUND, OBJECT } from "./variables";

export const FIND_BACKGROUNDS = gql(`
	query find($page: Int!, $take: Int!) {
		backgrounds(page: $page, take: $take) {
			${BACKGROUND}
			header: object {
				${OBJECT}
			}
		}
	}
`);

export const FIND_BACKGROUNDS_WITH_KEYWORDS = gql(`
	query backgroundsByKeywords ($keywords: [String!]!){
		backgroundsByKeywords(keywords: $keywords) {
			${BACKGROUND}
			header: object {
				${OBJECT}
			}
		}
	}
`);

/* export const READ_TNC = gql(`
  query read($id: Int!){
    readTNC(id: $id) {
      ${TNC}
      assignment {
        ${ASSIGNMENT}
      }
    }
  }
`);

export const FIND = gql(`
  query {
    backgrounds {
      id
      name
      width
      height
      object{
        id
        uuid
        keywords
      }
    }
}`); */
