export default {
  addon: (state) => state.addon,
  contest: (state) => state.contest,
  survey: (state) => state.survey,
  promo: (state) => state.promo,
  audience: (state) => state.audience,
  addonsByKeywords: (state) => state.addonsByKeywords,
  addonsByFolder: (state) => state.addonsByFolder,
  folderView: (state) => state.folderView,
  moreAddon: (state) => state.moreAddon,
  isDeleteAddon: (state) => state.isDeleteAddon,
  isLoading: (state) => state.isLoading,
  isLoadingContest: (state) => state.isLoadingContest,
  isLoadingSurvey: (state) => state.isLoadingSurvey,
  isLoadingPromo: (state) => state.isLoadingPromo,
  isLoadingAudience: (state) => state.isLoadingAudience,
};
