import gql from "graphql-tag";

import { IMAGE } from "./variables";
import { OBJECT } from "../../backgrounds/graphql/variables";
export const FIND_IMAGES = gql(`
	query find($take: Int!, $page: Int!){
		images(take: $take, page: $page) {
			${IMAGE}
			header: object {
				${OBJECT}
			}
		}
	}
`);

export const FIND_IMAGES_WITH_KEYWORDS = gql(`
query findByKeywords ($keywords: [String!]!){
  imagesByKeywords: findByKeywords(keywords: $keywords) {
    ${IMAGE}
		header: object {
			${OBJECT}
		}
  }
}
`);
