<template>
  <div class="text-center">
    <v-dialog
      overlay-color="rgba(93, 107, 136, 0.5)"
      overlay-opacity="1"
      v-model="model"
      width="566"
      persistent
    >
      <div class="isc-modal__content">
        <div class="isc-modal__header d-flex justify-end">
          <v-btn class="isc-modal__content--btn-close" icon @click="close">
            <svg
              v-if="showBtnClose"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.37894 7.5039L14.8088 1.07402C15.057 0.834344 15.0639 0.438888 14.8242 0.190722C14.5845 -0.0574443 14.1891 -0.0643256 13.9409 0.175349C13.9357 0.180363 13.9306 0.185488 13.9255 0.190722L7.49561 6.62061L1.06572 0.190685C0.817555 -0.048989 0.422099 -0.0421077 0.182425 0.206059C-0.0513935 0.448149 -0.0513935 0.831928 0.182425 1.07402L6.61231 7.5039L0.182425 13.9338C-0.0614592 14.1777 -0.0614592 14.5732 0.182425 14.8171C0.426345 15.0609 0.8218 15.0609 1.06572 14.8171L7.49561 8.3872L13.9255 14.8171C14.1737 15.0568 14.5691 15.0499 14.8088 14.8017C15.0426 14.5596 15.0426 14.1758 14.8088 13.9338L8.37894 7.5039Z"
                fill="#696B76"
              />
            </svg>
          </v-btn>
        </div>
        <div class="isc-modal__icon">
          <i
            v-if="showMainIcon"
            class="isc-modal__icon_style"
            :class="icon"
            :style="{ color: iconColor }"
          ></i>
        </div>
        <p class="isc-modal__title">
          <strong>{{ title }}</strong>
        </p>
        <p class="isc-modal__subtitle">{{ paragraph }}</p>

        <div v-if="!showLoader" class="isc-modal__footer">
          <v-btn
            :color="colorBtn1"
            class="isc-modal__btn"
            @click="sendResultBtn1"
          >
            {{ btnName1 }}
          </v-btn>
          <v-btn
            :color="colorBtn2"
            class="isc-modal__btn"
            @click="sendResultBtn2"
          >
            {{ btnName2 }}
          </v-btn>
        </div>
        <div v-else class="isc-modal__loader">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "CpIscDoubleBtnModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    paragraph: {
      type: String,
      default: "",
    },
    colorBtn1: {
      type: String,
      default: "light",
    },
    colorBtn2: {
      type: String,
      default: "primary",
    },
    btnName1: {
      type: String,
      default: "No",
    },
    btnName2: {
      type: String,
      default: "Yes",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    showBtnClose: {
      type: Boolean,
      default: false,
    },
    showMainIcon: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    sendResultBtn1() {
      this.$emit("send-result-btn1");
    },
    sendResultBtn2() {
      this.$emit("send-result-btn2");
    },
    close() {
      this.$emit("input", false);
    },
    clicked() {
      this.$emit("clicked", false);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: none;
}
.isc-modal {
  &__content {
    background-color: #fff;
    border-radius: 6px;
    padding: 15px 15px 59px;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    &--btn-close {
      &:hover::before {
        display: none;
      }
    }
  }

  &__header {
    margin-bottom: 10px;
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2c3852;
    text-align: center;
    margin-bottom: 13px;
    padding-top: 1rem;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #2c3852;
    text-align: center;
    margin-bottom: 40px;
  }
  &__btn {
    box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
    padding: 9px 20px 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    &_style {
      font-size: 28px;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  &__loader {
    display: flex;
    justify-content: center;
  }
}
</style>
