var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.fill,
          d: "M16.9141 4.57812L13.6719 1.33594C13.3203 0.984375 12.8516 0.75 12.3438 0.75H1.875C0.820312 0.75 0 1.60938 0 2.625V16.375C0 17.4297 0.820312 18.25 1.875 18.25H15.625C16.6406 18.25 17.5 17.4297 17.5 16.375V5.90625C17.5 5.39844 17.2656 4.92969 16.9141 4.57812ZM8.75 15.75C7.34375 15.75 6.25 14.6562 6.25 13.25C6.25 11.8828 7.34375 10.75 8.75 10.75C10.1172 10.75 11.25 11.8828 11.25 13.25C11.25 14.6562 10.1172 15.75 8.75 15.75ZM12.5 3.875V7.78125C12.5 8.05469 12.2656 8.25 12.0312 8.25H2.96875C2.69531 8.25 2.5 8.05469 2.5 7.78125V3.71875C2.5 3.48438 2.69531 3.25 2.96875 3.25H11.875C11.9922 3.25 12.1094 3.32812 12.2266 3.40625L12.3438 3.52344C12.4219 3.64062 12.5 3.75781 12.5 3.875Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }