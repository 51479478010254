<template>
  <div>
    <label>
      {{ label }}
    </label>
    <v-text-field
      outlined
      dense
      class="base-input"
      :success="success"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :autofocus="autofocus"
      hide-details
      :error-messages="modelError"
      @input="verify"
      @blur="verify"
      :color="color"
      v-model="model"
      autocomplete="off"
      :prefix="prefix"
      :append-icon="icon"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: [String, Number],
    autocomplete: String,
    label: String,
    type: {
      default: () => {
        return "text";
      },
    },
    success: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    readonly: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    autofocus: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholder: String,
    icon: {
      default: () => {
        return "";
      },
    },
    color: {
      type: String,
      default: "#00A5FF",
    },
    prefix: String,
    validate: {
      type: Object,
      default: () => {
        return {
          required: false,
          email: false,
          numeric: false,
          alpha: false,
          alphaNum: false,
          maxLength: 0,
          minLength: 0,
          decimal: false,
          maxValue: 0,
          between: 0,
          password: false,
        };
      },
    },
    v: {
      type: Object,
      required: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        /*//console.log(val);*/
      },
    },
    modelError() {
      const errors = [];
      if (this.v) {
        if (!this.v.$dirty) return errors;
        if (this.validate.required) {
          !this.v.required && errors.push("Field required");
        }
        if (this.validate.between) {
          !this.v.between &&
            errors.push(`Number should be between ${this.validate.between}`);
        }
        if (this.validate.alpha) {
          !this.v.alpha && errors.push("This field only accepts letters");
        }
        if (this.validate.alphaNum) {
          !this.v.alphaNum &&
            errors.push("This field only accepts letters and numbers");
        }
        if (this.validate.email) {
          !this.v.email && errors.push("Must be a valid e-mail");
        }
        if (this.validate.maxLength) {
          !this.v.maxLength &&
            errors.push(
              `This field accepts upto ${this.validate.maxLength} characters`
            );
        }
        if (this.validate.minLength) {
          !this.v.minLength &&
            errors.push(
              `This field accepts at least ${this.validate.minLength} characters`
            );
        }
        if (this.validate.decimal) {
          !this.v.decimal && errors.push(`Should be a decimal number`);
        }
        if (this.validate.numeric) {
          !this.v.numeric && errors.push(`This field only accepts numbers`);
        }
        if (this.validate.maxValue) {
          !this.v.maxValue &&
            errors.push(
              `Maximum accepted value is : ${this.validate.maxValue}`
            );
        }
        if (this.validate.password) {
          !this.v.sameAsPassword && errors.push(`Passwords don't match`);
        }
      }
      return errors;
    },
  },
  methods: {
    verify() {
      if (this.v) {
        this.v.$touch();
      }
    },
  },
};
</script>
<style lang="scss">
.base-input input {
  font-weight: bold;
  font-size: 12px;
  line-height: 10px;
}

.base-input fieldset {
  min-height: 30px;
  border: 1px solid #dedfdd;
  border-radius: 6px;
  background-color: #ffffff;
}
</style>
