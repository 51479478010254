var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isVisible
    ? _c("isc-modal-alert", {
        attrs: {
          title: _vm.title,
          paragraph: _vm.paragraph,
          icon: _vm.icon,
          iconColor: _vm.iconColor,
          showBtn: _vm.showBtn,
          showBtnClose: false,
          showMainIcon: _vm.showMainIcon,
        },
        on: { clicked: _vm.redirectToLogin },
        model: {
          value: _vm.isVisible,
          callback: function ($$v) {
            _vm.isVisible = $$v
          },
          expression: "isVisible",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }