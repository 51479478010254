export default {
  SET_OBJECTS_ALL: (state, item) => {
    state.read.push(item);
  },
  SET_TEXT_SELECT: (state, item) => {
    state.type = "text";
    state.textSelect = item;
  },
  SET_OBJECTS: (state, items) => {
    state.read = items;
  },
  SET_IS_TEXT_SELECTED: (state, isTextSelected) => {
    state.isTextSelected = isTextSelected;
  },
};
