import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import IcoTemplates from "../components/icons/IcoTemplates.vue";
import IcoImages from "../components/icons/IcoImages.vue";
import IcoBackground from "../components/icons/IcoBackground.vue";
import IcoShapes from "../components/icons/IcoShapes.vue";
import IcoFont from "../components/icons/IcoFont.vue";
import IcoUploads from "../components/icons/IcoUploads.vue";
import IcoSaved from "../components/icons/IcoSaved.vue";
import IcoSearch from "../components/icons/IcoSearch.vue";
import IcoArrowRight from "../components/icons/IcoArrowRight.vue";
import IcoEdit from "../components/icons/IcoEdit.vue";
import IcoBackStep from "../components/icons/IcoBackStep.vue";
import IcoNextStep from "../components/icons/IcoNextStep.vue";
import IcoClone from "../components/icons/IcoClone.vue";
import IcoOrder from "../components/icons/IcoOrder.vue";
import IcoTransparency from "../components/icons/IcoTransparency.vue";
import IcoBlock from "../components/icons/IcoBlock.vue";
import IcoTrash from "../components/icons/IcoTrash.vue";

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00a5ff",
      },
    },
  },
  icons: {
    values: {
      templates: {
        component: IcoTemplates,
      },
      images: {
        component: IcoImages,
      },
      background: {
        component: IcoBackground,
      },
      shapes: {
        component: IcoShapes,
      },
      font: {
        component: IcoFont,
      },
      uploads: {
        component: IcoUploads,
      },
      saved: {
        component: IcoSaved,
      },
      search: {
        component: IcoSearch,
      },
      "arrow-right": {
        component: IcoArrowRight,
      },
      edit: {
        component: IcoEdit,
      },
      "back-step": {
        component: IcoBackStep,
      },
      "next-step": {
        component: IcoNextStep,
      },
      clone: {
        component: IcoClone,
      },
      order: {
        component: IcoOrder,
      },
      transparency: {
        component: IcoTransparency,
      },
      block: {
        component: IcoBlock,
      },
      trash: {
        component: IcoTrash,
      },
    },
  },
});
