<template>
  <div>
    <v-dialog 
      v-model="dialog" 
      overlay-color="rgba(93, 107, 136, 0.5)"
      overlay-opacity="1"
      width="566" 
      persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          @click="triggerInitDialog"
          dark
          height="35"
          width="67"
          class="font-weight-bold choose-shadow"
          v-bind="attrs"
          v-on="on"
          small
          :disabled="disabled"
          :style="[disabled &&  disabledButtonStyle ]"
        >
          Save
        </v-btn>
      </template>
      <v-card
        class="modalA"
        v-bind:style="bodyFolders ? 'height: 242px;' : 'height: 221px;'"
      >
        <v-card-title class="modalA_header" v-if="!loading.load">
          <i
            v-show="!bodyOk"
            class="fa fa-times"
            @click="closeDialog"
            aria-hidden="true"
          >
          </i>
        </v-card-title>
        <v-card-title v-else class="modalA_empty-icon"></v-card-title>
        <div class="modalA_body" v-show="bodyInit">
          Do you want to <b>save all the changes?</b>
        </div>
        <div class="modalA_folders" v-show="bodyFolders">
          Choose a folder to save your {{ type }}:
          <div class="select-folders">
            <v-select
              v-model="folderSelected"
              :items="folders"
              outlined
              dense
              item-text="name"
              return-object
              placeholder="Folders"
              :menu-props="{contentClass:'menuStyleDes'}"
            >
              <template v-slot:append-item>
                <v-list-item @click="newFolder">
                  <v-list-item-content>
                    <v-list-item-title class="d-flex align-center">
                      <v-icon size="12" color="#96a0b8">
                        fas fa-plus
                      </v-icon>
                      <span class="ml-2 bold"><b>New Folder</b></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
        <div class="modalA_body" :class="pSidesTop" v-show="bodyOk">
          Your {{ type }} " {{ nameProject }} "
          <b>has been saved successfully!</b>
        </div>
        <v-card-actions>
          <div class="buttons_wrapper" :class="marginTopButton">
            <div
              v-if="!loading.load"
              class="buttons buttons_back"
              v-show="!bodyOk"
              @click="closeDialog"
            >
              Back
            </div>
            <v-btn
              color="rgba(0, 165, 255, 1)"
              v-show="!bodyOk"
              :loading="loading.load"
              dark
              @click="changeDialog"
              :height="35"
              :width="67"
            >
              Save
            </v-btn>
            <div
              class="buttons buttons_save"
              v-show="bodyOk"
              @click="sendToHome"
            >
              Ok
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createFolder" width="566" class="save-modal" persistent>
      <v-card class="modalB">
        <v-card-title class="modalB_header">
          <i
            class="fa fa-times"
            @click="closeFolderModal"
            aria-hidden="true"
          ></i>
        </v-card-title>
        <div class="modalB_body">
          <v-col class="style_label">
            <h4 class="style_label_name">New folder name:</h4>
          </v-col>
          <v-col class="box_text">
            <input
              type="text"
              placeholder="Nombre del folder"
              class="style-input-text"
              v-model="folderNew"
            />
          </v-col>
          <v-col class="btn-save-folder">
            <v-btn
              color="rgba(0, 165, 255, 1)"
              @click="setFolder"
              :loading="loadingFolder"
              dark
              :height="35"
              :width="67"
            >
              Save
            </v-btn>
          </v-col>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      folderSelected: {},
      dialog: false,
      bodyInit: false,
      bodyFolders: false,
      bodyNewFolder: false,
      bodyOk: false,
      createFolder: false,
      folderNew: "",
      loadingFolder: false,
      setIdAddon: false,
      disabledButtonStyle:{
        backgroundColor: '#f3f4f7 !important', 
        color: '#a2acc4 !important'
      },
      marginTopButton: 'mt-6',
      pSidesTop:'px-0 pt-0'
    };
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    nameProject: {
      type: String,
      default: "",
    },
    idProject: {
      type: Number,
      default: null,
    },
    hideInitModal: {
      type: Boolean,
      default: false,
    },
		clientId: {
			type: Number,
			default: 0
		},
    disabled: {
      type: Boolean,
      default: false,
    },
    
  },
  created() {
    //this.$store.dispatch('post/folder/FIND', { type: this.type });
  },
  computed: {
    folders() {
      return this.$store.getters["post/folder/folders"];
    },
    loading() {
      return this.$store.getters["global/saved/loading"];
    },
    isEdit() {
      return this.$store.getters["global/saved/editProject"];
    },
  },
  watch: {
    folderSelected() {
      if (this.folderSelected.name === "+ New Folder") {
        this.folderNew = "";
        this.createFolder = true;
      }
    },
    loading(value) {
      if (value.status) {
        this.pSidesTop = "px-10 pt-5";
        this.marginTopButton = "pt-11";
        this.bodyFolders = false;
        this.bodyOk = true;
        this.bodyInit = false;
      }
    },
  },
  methods: {
    newFolder() {
      this.folderNew = "";
        this.createFolder = true;
    },
    triggerInitDialog() {
      this.marginTopButton = 'mt-6'
      if (!this.hideInitModal) {
        this.initDialog();
        this.setIdAddon = true;
      } else {
        this.setIdAddon = false;
        this.initDialog();
        this.changeDialog();
      }
    },
    setFolder() {
      let folder = {};
      if (this.folderNew === "") return;
      if (!this.clientId) {
        this.$emit('trigger-error', "Client does not exist",);
        return;
      }
      folder.name = this.folderNew;
      folder.type = this.type;
      folder.clientId = this.clientId;
      this.loadingFolder = true;
      this.$store.dispatch("post/folder/CREATE", { folder, responseFunc: this.responseMessageGraphql });
      this.createFolder = false;
      this.loadingFolder = false;
      this.folderSelected = {};
    },
    responseMessageGraphql(msgResp, typeResp ){
      const typeResponse = {
        "error":  () => {
          let modifiedErrMsg = msgResp.replace("GraphQL error:", "")
          this.$emit('trigger-error', modifiedErrMsg);
        },
        "success": () => this.$emit('trigger-success', msgResp)
      }      
      return typeResponse[typeResp]();
    },
    closeFolderModal() {
      this.createFolder = false;
      this.folderSelected = {};
      this.folderNew = "";
    },
    initDialog() {
      if (!this.idProject)
        this.$store.dispatch("post/folder/FIND", { type: this.type, clientId: this.clientId, responseFunc: this.responseMessageGraphql });
      this.folderSelected = {};
      this.dialog = true;
      this.bodyInit = true;
      this.bodyFolders = false;
      this.bodyNewFolder = false;
      this.bodyOk = false;
    },
    closeDialog() {
      this.dialog = false;
      this.bodyInit = false;
      this.bodyFolders = false;
      this.bodyNewFolder = false;
      this.bodyOk = false;
      this.folderSelected = {};
      this.marginTopButton = 'mt-10'

      console.log("close dialog ");
    },

    sendToHome() {
      this.closeDialog();
      if (this.type === "addon") {
        this.$emit("close-outside-dialog");
        if (this.hideInitModal) this.$store.commit("addons/create/RESET_ADDON");
      }
      if (this.isEdit) this.$router.push("/main");
      //if(this.type === 'addon') this.$store.commit("addons/create/RESET_ADDON");
      //if(!this.hideInitModal) this.$router.push("/");
    },
    changeDialog() {
      //console.log(' change dialog ');
      // validation if it is id = 0
      if (!this.idProject) {
        if (this.folderSelected.id === 0) return;
        if (this.bodyInit) {
          //console.log('this body init');
          this.bodyInit = false;
          this.bodyFolders = true;
          this.marginTopButton = 'mt-10'
        } else {
          if (!this.folderSelected.id) return;
          this.$emit("folder-id", this.folderSelected.id, this.setIdAddon);
        }
      } else {
        this.$emit("edit-addon", true);
      }
    },
  },
};
</script>
<style scoped lang="scss">
  ::v-deep .v-dialog {
    box-shadow: none;
  }
  
.modalA {
  &_header {
    padding: 25px 25px 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    i {
      color: #696b76;
      cursor: pointer;
      -webkit-text-stroke: 2px white;
    }
  }
  &_empty-icon {
    height: 50px;
  }
  &_body {
    text-align: center;
    font-size: 20px;
    width: 100%;
    height: 56px;
  }
  &_folders {
    margin-left: 40px;
    font-size: 15px;
    line-height: 25px;
    .select-folders {
      width: 486px;
      height: 20px;
      margin-top: 20px;

      .v-input
        .v-input--is-label-active
        .v-input--is-dirty
        .v-input--is-focused:focus {
        outline: none;
        border: 1px;
      }
      ::v-deep .v-input__slot {
        border: 1px solid #C5D0E4;
        box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
        border-radius: 3px;
        max-width: 486px;
        max-height: 35px;
        font-size: 12px;
        line-height: 16px;
      }

      
    }
  }
}
.modalB {
  height: 242px;
  &_header {
    padding: 25px 25px 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    i {
      color: #696b76;
      cursor: pointer;
      -webkit-text-stroke: 2px white;
    }
  }
  &_body {
    .style_label {
      padding: 0px;
      &_name {
        text-align: left;
        margin-left: 40px;
        font-size: 15px;
        font-weight: normal;
      }
    }
    .box_text {
      padding: 12px 0px 12px 10px;
      margin-bottom: 12px;
    }
    .style-input-text {
      background: #ffffff;
      border: 1px solid #c5d0e4;
      box-sizing: border-box;
      box-shadow: inset 0px 2px 5px rgb(197 208 228 / 62%);
      border-radius: 3px;
      width: 486px;
      height: 35px;
      font-size: 12px;
      padding-left: 14px;
      margin-left: 30px;
    }
    .style-input-text:focus {
      outline: none;
      border-color: #00a5ff;
    }
    .btn-save-folder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 24px;
      .v-btn {
        border-radius: 3px;
        font-size: 12px;
      }
    }
  }
}
.buttons_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-left: 0px;
  // margin-top: 25px;
  .v-btn {
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
    padding: 9px 20px 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
}

.buttons {
  height: 35px;
  width: 67px;
  border: 1px solid #c5d0e4;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 12px;
  text-align: center;
  padding-top: 8px;
  cursor: pointer;
  &_back {
    background: #ffffff;
    margin-right: 10px;
  }
  &_save {
    background: #00a5ff;
    color: white;
    margin-left: 10px;
  }
}
</style>
