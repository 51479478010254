var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "flex-column color-picker" },
    [
      _c(
        "v-col",
        { staticClass: "px-4 py-4" },
        [
          _c(
            "v-row",
            { attrs: { "justify-md": "center" } },
            [
              _c(
                "v-col",
                [
                  _c("v-color-picker", {
                    staticClass: "vue-color-picker",
                    attrs: {
                      "hide-inputs": "",
                      mode: "hexa",
                      width: "248",
                      "dot-size": "18",
                    },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-0 px-3 pb-2", attrs: { "align-md": "center" } },
            [
              _c("v-col", { staticClass: "py-0 pl-0", attrs: { md: "3" } }, [
                _c("div", {
                  staticClass: "v-color-picker__dot d-block",
                  style: { background: _vm.value },
                }),
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "py-0 ps-0", attrs: { md: "6" } }, [
                _c("p", { staticClass: "mb-0 text--value" }, [
                  _vm._v(_vm._s(_vm.value)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-right py-0", attrs: { md: "3" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { size: "13", color: "#3C3D41" },
                      on: {
                        click: function ($event) {
                          return _vm.openEyeDropper()
                        },
                      },
                    },
                    [_vm._v("fas fa-eye-dropper\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showOpacity
            ? _c(
                "v-row",
                {
                  staticClass: "px-3 mb-2 mt-5",
                  attrs: { "align-md": "center" },
                },
                [
                  _c("v-col", { staticClass: "py-0", attrs: { md: "12" } }, [
                    _c("p", { staticClass: "mb-0 text--primary" }, [
                      _vm._v("Opacity"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { md: "9" } },
                    [
                      _c("v-slider", {
                        attrs: {
                          max: "255",
                          min: "0",
                          "track-color": "#E9EAEE",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.colorOpacity,
                          callback: function ($$v) {
                            _vm.colorOpacity = $$v
                          },
                          expression: "colorOpacity",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "py-0", attrs: { md: "3" } }, [
                    _c("p", { staticClass: "mb-0 text--value text-right" }, [
                      _vm._v(_vm._s(_vm.percentOpacity) + "%"),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }