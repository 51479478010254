<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7188 0.5H10.5938C10.4531 0.5 10.3125 0.640625 10.3125 0.78125C10.3125 0.804688 10.3125 0.804688 10.3125 0.804688L10.4062 2.75C9.35156 1.48438 7.75781 0.6875 6 0.6875C2.78906 0.6875 0.164062 3.3125 0.1875 6.52344C0.1875 9.73438 2.78906 12.3125 6 12.3125C7.5 12.3125 8.85938 11.75 9.89062 10.8359C9.9375 10.7656 9.98438 10.6953 9.98438 10.625C9.98438 10.5312 9.9375 10.4609 9.89062 10.4141L9.09375 9.61719C9.04688 9.57031 8.97656 9.54688 8.90625 9.54688C8.83594 9.54688 8.76562 9.57031 8.71875 9.61719C7.99219 10.25 7.03125 10.625 6 10.625C3.70312 10.625 1.875 8.79688 1.875 6.5C1.875 4.22656 3.70312 2.375 6 2.375C7.40625 2.375 8.67188 3.10156 9.42188 4.20312L7.03125 4.08594C7.03125 4.08594 7.03125 4.08594 7.00781 4.08594C6.86719 4.08594 6.72656 4.22656 6.72656 4.36719H6.75V5.49219C6.75 5.63281 6.86719 5.77344 7.03125 5.77344H11.7188C11.8594 5.77344 12 5.63281 12 5.49219V0.78125C12 0.640625 11.8594 0.5 11.7188 0.5Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: "IcoBackStep",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#5D6B88";
      },
    },
  },
};
</script>
