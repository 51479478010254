const actions = { // AQUI SE GUARDAN LOS CAMBIOS DEL EDIT-IMAGE
    CAN_REDO: ( { commit }, index) => {
        commit('CAN_REDO' , index)
    },
    CAN_UNDO: ( { commit }, index) => {
        commit('CAN_UNDO' , index)
    },
    REDO: ({commit, getters}) => { // avanzar
        const history = getters.history;
        const nextState = history[getters.historyIndex + 1];

        commit('UPDATE_INDEX', 1)
        commit('addons/create/SET_ROTATE', nextState ? nextState.create.addon.transform.rotate : 0, { root: true })
        commit('addons/create/SET_WIDTH', nextState ? nextState.create.addon.width : 491, { root: true });
        commit('addons/create/SET_HEIGHT', nextState ? nextState.create.addon.height : 256, { root: true });
        commit('addons/create/SET_HORIZONTAL', nextState ? nextState.create.addon.x : 0, { root: true });
        commit('addons/create/SET_VERTICAL', nextState ? nextState.create.addon.y : 0, { root: true });
        commit('addons/create/SET_CROPPED_POSITION', nextState ? {
            x: nextState.create.addon.cropped.x,
            y: nextState.create.addon.cropped.y
        } : {
            x: 0,
            y: 0,
          }, { root: true })
        commit('addons/create/SET_CROPPED_SIZE', nextState ? {
            width: nextState.create.addon.cropped.width,
            height: nextState.create.addon.cropped.height
        } : {
            width: 491,
            height: 256,
        }, { root: true });
        commit('addons/create/SET_TRANSFORM_FLIP', nextState ? nextState.create.addon.transform.scale : {
            x: 1,
            y: 1
        }, { root: true })
        commit('addons/create/SET_IS_LOCKED', nextState ? nextState.create.addon.locked : false, { root: true })
    },
    UNDO: ({commit, getters, rootState}) => { // retroceder 
        const history = getters.history;
        const prevState = history[getters.historyIndex - 1];
        commit('UPDATE_INDEX', -1)
        
        // commit('addons/create/SET_BACKGROUND_ADDON', prevState ? prevState.create.addon : null, { root: true })

        commit('addons/create/SET_ROTATE', prevState ? prevState.create.addon.transform.rotate : 0, { root: true })
        commit('addons/create/SET_WIDTH', prevState ? prevState.create.addon.width : rootState.addons.create.addon.origin.width, { root: true });
        commit('addons/create/SET_HEIGHT', prevState ? prevState.create.addon.height : rootState.addons.create.addon.origin.height, { root: true });
        commit('addons/create/SET_HORIZONTAL', prevState ? prevState.create.addon.x : rootState.addons.create.addon.origin.x, { root: true });
        commit('addons/create/SET_VERTICAL', prevState ? prevState.create.addon.y : rootState.addons.create.addon.origin.y, { root: true });
        commit('addons/create/SET_CROPPED_POSITION', prevState ? {
            x: prevState.create.addon.cropped.x,
            y: prevState.create.addon.cropped.y
        } : {
            x: 0,
            y: 0,
          }, { root: true })
        commit('addons/create/SET_CROPPED_SIZE', prevState ? {
            width: prevState.create.addon.cropped.width,
            height: prevState.create.addon.cropped.height
        } : {
            width: rootState.addons.create.addon.origin.width,
            height: rootState.addons.create.addon.origin.height,
        }, { root: true });
        commit('addons/create/SET_TRANSFORM_FLIP', prevState ? prevState.create.addon.transform.scale : {
            x: 1,
            y: 1
        }, { root: true })
        commit('addons/create/SET_IS_LOCKED', prevState ? prevState.create.addon.locked : false, { root: true })
    }
}

export default actions;