import gql from "graphql-tag";
import { UPLOAD } from "./variables";
import { OBJECT } from "../../backgrounds/graphql/variables";
export const CREATE_UPLOAD = gql(`
  mutation createUpload($upload: UploadsDTO!, $object: ObjectsDTO!){
    upload: createUpload(upload: $upload, object: $object) {
      ${UPLOAD}
			header: objects {
        ${OBJECT}
      }
    }
  }
`);

export const DELETE_UPLOAD = gql(`
  mutation removeUpload($id: Int!){
    upload: removeUpload(id: $id) 
  }
`);