<template>
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M9.81445 2.11328C9.7168 2.01562 9.57031 2.01562 9.47266 2.11328L4.15039 7.43555L3.90625 9.70605C3.85742 9.99902 4.12598 10.2676 4.41895 10.2188L6.68945 9.97461L12.0117 4.65234C12.1094 4.55469 12.1094 4.4082 12.0117 4.31055L9.81445 2.11328ZM13.7695 1.55176L12.5732 0.355469C12.207 -0.0107422 11.5967 -0.0107422 11.2305 0.355469L10.376 1.20996C10.2783 1.30762 10.2783 1.4541 10.376 1.55176L12.5732 3.74902C12.6709 3.84668 12.8174 3.84668 12.915 3.74902L13.7695 2.89453C14.1357 2.52832 14.1357 1.91797 13.7695 1.55176ZM9.375 8.53418V11H1.5625V3.1875H7.15332C7.25098 3.1875 7.32422 3.16309 7.37305 3.11426L8.34961 2.1377C8.52051 1.94238 8.39844 1.625 8.12988 1.625H1.17188C0.512695 1.625 0 2.16211 0 2.79688V11.3906C0 12.0498 0.512695 12.5625 1.17188 12.5625H9.76562C10.4004 12.5625 10.9375 12.0498 10.9375 11.3906V7.55762C10.9375 7.28906 10.6201 7.16699 10.4248 7.33789L9.44824 8.31445C9.39941 8.36328 9.375 8.43652 9.375 8.53418Z"
    />
  </svg>
</template>
<script>
export default {
  name: "IcoEdit",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#5D6B88";
      },
    },
  },
};
</script>
