export const doubleBtnMixin = {
  data() {
    return {
      isShowModalBtn: false,
      titleModalBtn: "",
      paragraphModalBtn: "",
    };
  },
  methods: {
    showModalBtn(title, paragraph){
      this.isShowModalBtn = true
      this.titleModalBtn = title
      this.paragraphModalBtn = paragraph
    }
  },
};