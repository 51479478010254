import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";
import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import quillConfig from "../functions/quillFunctions";

export default {
  RESIZE: ({ commit, state }, { uuid, width, height, x, y, cropped }) => {
    const images = state.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          width,
          height,
          x,
          y,
          cropped: cropped
            ? {
                x: cropped.x,
                y: cropped.y,
                width: cropped.width,
                height: cropped.height,
              }
            : null,
        };
      } else {
        return item;
      }
    });
    commit("UPDATE_IMAGE", images);
  },
  TRANSLATE_SELECTED: ({ commit, state }, { uuid, x, y }) => {
    const images = state.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          x,
          y,
        };
      } else {
        return item;
      }
    });
    commit("UPDATE_IMAGE", images);
  },
  EVERY_RESIZE: ({ commit }, { uuid, width, height, x, y }) => {
    commit("EVERY_RESIZE", { uuid, width, height, x, y });
  },
  ROTATE: ({ commit, state }, { uuid, rotate }) => {
    const images = state.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          rotate,
        };
      } else {
        return item;
      }
    });
    commit("UPDATE_IMAGE", images);
  },
  EVERY_ROTATE: ({ commit }, { uuid, rotate }) => {
    commit("EVERY_ROTATE", { uuid, rotate });
  },
  ADD_TEXT_IN_CANVAS: ({ commit, state, rootState }, text) => {
    const images = state.images;
    const uuid = uuidv4();
    images.push({
      ...text,
      uuid,
      original: {
        width: text.width,
        height: text.height,
      },
      isTemplateEdit: false,
      isEdit: false,
      typeItem: "text",
      transparency:1,
      isEditing: false,
      transform: {
        scale: {
          x: 1,
          y: 1,
        },
      },
    });
    images.map((item,index) => {
      item.indexPosition = index;
      if(item.styles != undefined && item.styles.fontsList != undefined && !item.isTemplate) delete item.styles.fontsList;
      if(item.elementsStyles != undefined && !item.isTemplate) delete item.elementsStyles;
    });
    
    commit("SET_IMAGES", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  SET_TEXT_IN_CANVAS: ({ commit, state, rootState }, { text, uuid }) => {
    const images = state.images;
    const newImages = images.map((el) => {
      if (el.uuid == uuid) el.default = text;
      return el;
    });
    commit("SET_IMAGES", newImages);
    commit("post/SET_CHANGES", null, { root: true });
    commit(
      "global/redoundo/ADD_HISTORY",
      cloneDeep({
        images,
        background: rootState.post.background.background,
        type: rootState.post.background.type,
      }),
      { root: true }
    );
  },
  UPDATE_TEXT_HEIGHT_BY_INDEX: (
    { state, commit, rootState },
    { defaultHeight, uuid, index }
  ) => {
    //brings all height for every column

    const images = state.images.map((item) => {
      if (item.uuid === uuid) {
        // const { scale } = item.transform
        item.height = defaultHeight;
        item.original.height = defaultHeight;
        // const sum = item.content.reduce((accomulator, object) => {
        //   return accomulator + object.height
        // }, 0)

        // item.original.height = sum
        // item.height = scale.y * item.original.height
        // item.width = scale.x * item.original.width
      }
      return item;
    });

    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
    // commit(
    //   'global/redoundo/ADD_HISTORY',
    //   cloneDeep({
    //     images,
    //     background: rootState.post.background.background,
    //     type: rootState.post.background.type,
    //   }),
    //   { root: true },
    // )
  },
  UPDATE_TEXT_WIDTH_BY_INDEX: (
    { state, commit, rootState },
    { width, uuid, index }
  ) => {
    const images = state.images.map((item) => {
      if (item.uuid === uuid) {
        item.width = width;
        item.original.width = width;
      }
      return item;
    });
    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  UPDATE_IS_EDITING_TEXT_BY_UUID: (
    { state, commit, dispatch, rootState },
    payload
  ) => {
    const { uuidd, value } = payload;
    // dispatch('DISABLED_ALL_EDITING_TEXT')
    // console.log('UPDATE_IS_EDITING_TEXT_BY_UUID', uuidd, value);
    const images = state.images.map((item) => {
      if (item.uuid === uuidd) item.isEditing = value;
      return item;
    });
    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  UPDATE_TEXT_DEFAULT_BY_UUID: ({ state, commit }, payload) => {
    const { uidd, text } = payload;
    const images = state.images.map((item) => {
      if (item.uuid === uidd) item.default = text;
      return item;
    });
    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  DISABLED_ALL_EDITING_TEXT: ({ state, commit, dispatch, rootState }) => {
    // console.log('DISABLED_ALL_EDITING_TEXT');
    const images = state.images.map((item) => {
      if (item.isEditing) item.isEditing = false;
      return item;
    });
    dispatch("CHANGE_IS_OPEN_MENU_FONTS", false);
    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  ADD_SHAPE_IN_CANVAS: ({ commit, state, rootState }, shape) => {
    const images = state.images;
    const uuid = uuidv4();
    images.push({
      ...shape,
      uuid,
      transparency:1,
      typeItem: "shape",
    });
    commit("SET_IMAGES", images);
    commit("post/SET_CHANGES", null, { root: true });
    commit(
      "global/redoundo/ADD_HISTORY",
      cloneDeep({
        images,
        background: rootState.post.background.background,
        type: rootState.post.background.type,
      }),
      { root: true }
    );
  },
  ADD_IMAGE_IN_CANVAS: ({ commit, state, rootState }, image) => {
    const images = state.images;
    images.push({
      ...image,
      transparency:1,
      typeItem: "image",
    });
    commit("SET_IMAGES", images);
    commit("post/SET_CHANGES", null, { root: true });
    commit(
      "global/redoundo/ADD_HISTORY",
      cloneDeep({
        images,
        background: rootState.post.background.background,
        type: rootState.post.background.type,
      }),
      { root: true }
    );
  },
  UPDATE_STYLES_IN_SELECTION_RESIZE: ({ commit }, styles) => {
    commit("SET_STYLES_RESIZE", styles);
  },
  UPDATE_HOVER: ({ commit }, hover) => {
    commit("SET_HOVER", hover);
  },
  UPDATE_HOVER_STYLES_SELECTED: ({ commit }, hoverStyles) => {
    commit("SET_HOVER_STYLES_SELECTED", hoverStyles);
  },
  UPDATE_HEIGHT_STYLES_IN_SELECTION_RESIZE: ({ commit }, height) => {
    commit("SET_HEIGHT_STYLES_RESIZE", height);
  },
  UPDATE_WIDTH_STYLES_IN_SELECTION_RESIZE: ({ commit }, width) => {
    commit("SET_WIDTH_STYLES_RESIZE", width);
  },
  UPDATE_ROTATE: ({ commit }, rotate) => {
    commit("SET_ROTATE", rotate);
  },
  REMOVE_STYLES_IN_SELECTION_RESIZE: ({ commit }) => {
    commit("REMOVE_STYLES_SELECTED");
  },
  UPDATE_STYLES_IN_SELECTED_CROP: ({ commit }, styles) => {
    commit("SET_SELECTED_CROP", styles);
  },
  UPDATE_STYLES_IN_SELECTED_TEXT: ({ state, commit, dispatch }, styles) => {
    commit("SET_SELECTED_TEXT", styles);
    if (styles.typeItem == "text") {
      dispatch("DESTROY_QUILL");
      dispatch("UPDATE_IS_EDITING_TEXT_BY_UUID", {
        uuidd: styles.uuid,
        value: true,
      });
      // console.log("is editing check ");
      const editorQuillContent = document.getElementById("editorQuill");
      commit("SET_QUILL_INSTANCE", state.quill);
      dispatch("CREATE_QUILL", { id: editorQuillContent, uidd: styles.uuid });
    }
  },
  CAPTURE_IMAGES_IN_SELECT_BOX: (
    { state, commit },
    { width, height, left, top }
  ) => {
    const images = state.images;
    const imagesFilter = images.filter((image) => {
      return (
        image.x > left &&
        image.y > top &&
        image.x + image.width < left + width &&
        image.y + image.height < height + top
      );
    });
    const lstImagesUUID = imagesFilter.map(({ uuid }) => uuid);
    commit("UPDATE_IMAGES_SELECTED_BOX", lstImagesUUID);
  },
  TEXT_CLICK_COUNT({ state, commit }, count) {
    commit("SET_TEXT_CLICK_COUNT", count);
  },
  CREATE_QUILL({ state, commit, dispatch }, payload) {
    if (state.selectedText.elementsStyles) {
      // esta seccion valida si los elementos el texto cuenta con diferentes tamaños de fuentes y los regista en el QUILL.
      state.selectedText.elementsStyles.map((e) => {
        if (e.attributes) dispatch("ADD_FONT_SIZE", e.attributes.size);
        if (e.groups) dispatch("ADD_FONT_SIZE", e.groups[0].attributes.size);
      });
    }
    quillConfig.CONFIG_REGISTER_UPPERCASE(["uppercase", "lowercase"]);
    quillConfig.CONFIG_REGISTER_LINE_HEIGHT(["1-0", "1-25", "1-5", "2-0", "2-5", "3-0"]);
    quillConfig.CONFIG_REGISTER_FONT_FAMILY(state.fontsToEditor);
    quillConfig.CONFIG_REGISTER_FONT_SIZE(state.fontSizeList);
    const { id, uidd } = payload;
    const quill = new Quill(id, {
      // opciones de configuración de Quill
      modules: {
        clipboard: {
          matchVisual: false,
        },
      },
      // theme: 'snow',
    });
    quill.setContents(state.selectedText.delta); // agrega contenido del quill del texto selccionado
    const parentItemIndex = state.images.findIndex(
      ({ uuid }) => uuid == state.selectedText.uuid
    );
    let listofPar = [];
    let paragraphList = quill.container.firstChild.childNodes;
    dispatch("ADD_ID_QUILL_CONTENT", { paragraphList, parentItemIndex });
    quill.on("text-change", (delta, oldDelta, source) => {
      if (
        source === "user" &&
        quill.getLength() === 1 &&
        quill.getText().trim() === ""
      ) {
        let format = oldDelta.ops[0].attributes;
        quill.insertText(0, " ", format);
      }
      dispatch("ADD_ID_QUILL_CONTENT", {
        paragraphList,
        parentItemIndex,
      });
      let html = quill.root.innerHTML;
      dispatch("RETURN_DELTAS_ORDERED_LIST", quill);
      dispatch("UPDATE_TEXT_DEFAULT_BY_UUID", { uidd, text: html });
      dispatch("GLOBAL_CHANGE_HEIGHT");
      // commit("post/SET_CHANGES", null, { root: true }); ------- REDU-UNDO
      // console.log('changeeee', html);
    });
    quill.on("editor-change", () => {
      let selection = quill.getSelection();
      // console.log("selecction----->", selection);
      dispatch("ADD_QUILL_GET_SELECTION", selection);
    });
    commit("SET_QUILL_INSTANCE", quill);
  },
  ADD_QUILL_GET_SELECTION({ state, commit, dispatch }, value) {
    commit("SET_QUILL_GET_SELECTION", value);
    // }
  },
  ADD_QUILL_LAST_SELECTION({ state, commit, dispatch }, value) {
    commit("SET_QUILL_LAST_SELECTION", value);
    // }
  },
  ADD_QUILL_NEW_SELECTION({ state, commit, dispatch }, value) {
    commit("SET_QUILL_NEW_SELECTION", value);
    // }
  },
  DESTROY_QUILL({ commit }) {
    commit("SET_QUILL_INSTANCE", null);
  },
  ADD_FONT_SIZE({ state, commit }, value) {
    // console.log('ADD_FONT_SIZE.....', value);
    state.fontSizeList.push(value);
    commit("SET_FONT_SIZE", state.fontSizeList);
  },
  REGISTER_QUIL_FONT_SIZE({ state, dispatch }, value) {
    quillConfig.REGISTER_QUIL_FONT_SIZE(value);
  },
  SET_GLOBAL_FONT_SIZE({ state, dispatch }, payload) {
    quillConfig.SET_GLOBAL_FONT_SIZE(payload);
  },
  GLOBAL_CHANGE_HEIGHT({ state, dispatch }) {
    setTimeout(() => {
      let lienzoItem = document.getElementById(`textedit${state.selectedText.indexPosition}`)
      let UpdateHeight = null;
      let UpdateWidth = null;
      let uidd_ = state.selectedText.uuid;
      UpdateHeight = lienzoItem.offsetHeight || 0;
      UpdateWidth = lienzoItem.offsetWidth;
      if(UpdateHeight != 0){
        dispatch("UPDATE_TEXT_HEIGHT_BY_INDEX", {
          defaultHeight: UpdateHeight,
          uuid: uidd_,
          index: 0,
        });
        dispatch("UPDATE_HEIGHT_STYLES_IN_SELECTION_RESIZE", UpdateHeight);
      }
      // dispatch("UPDATE_WIDTH_STYLES_IN_SELECTION_RESIZE",UpdateWidth);
    }, 10);
  },
  ACTIVE_TO_TEXTEDIT({ state, commit, dispatch }, value) {
    // console.log('activar si se esta editando', value);
    commit("SET_IS_ACTIVE_TO_TEXTEDIT", value);
    // }
  },
  ADD_PARRAFOS_QUILL({ state, commit, dispatch }, value) {
    commit("SET_PARRAFOS_QUILL", value);
  },
  CHANGE_SHOWFONTSIZE_TEXT({ state, commit, dispatch }, value) {
    commit("SET_SHOWFONTSIZE_TEXT", value);
  },
  ADD_IS_DRAGGING({ state, commit, dispatch }, value) {
    commit("SET_IS_DRAGGING", value);
  },
  ADD_ID_QUILL_CONTENT({ state, commit, dispatch }, payload) {
    quillConfig.PROCESS_QUILL_DOM_CONTENT(payload);
  },
  SET_IS_TEMPLATE_EDIT: ({ state, commit, dispatch, rootState }, payload) => {
    const { uidd, value } = payload;
    const images = state.images.map((item) => {
      if (item.uuid === uidd) item.isTemplateEdit = value;
      return item;
    });
    commit("UPDATE_IMAGE", images);
    // console.log("aca ", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  SET_UPADTE_DELTA_LISTA: ({ state, commit, dispatch, rootState }, payload) => {
    const { uidd, value } = payload;
    const images = state.images.map((item) => {
      if (item.uuid === uidd) item.elementsStyles = value;
      return item;
    });
    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  SET_UPADTE_DELTA_TO_EDITOR: (
    { state, commit, dispatch, rootState },
    payload
  ) => {
    const { uidd, value } = payload;
    const images = state.images.map((item) => {
      if (item.uuid === uidd) item.delta = value;
      return item;
    });
    commit("UPDATE_IMAGE", images);
    commit("post/SET_CHANGES", null, { root: true });
  },
  RETURN_DELTAS_ORDERED_LIST({ state, commit, dispatch }, quill) {
    let currentGroup = [];
    currentGroup =  quillConfig.RETURN_DELTAS_ORDERED_LIST(quill);
    // console.log("agrega deltas", currentGroup);
    dispatch("ADD_PARRAFOS_QUILL", currentGroup);
    dispatch("SET_UPADTE_DELTA_LISTA", {
      uidd: state.selectedText.uuid,
      value: currentGroup,
    });
    dispatch("SET_UPADTE_DELTA_TO_EDITOR", {
      uidd: state.selectedText.uuid,
      value: state.quill.getContents().ops,
    });
  },
  ADD_LAST_ATTRIBUTES_SELECTED({ state, commit, dispatch }, value) {
    if(state.lastAttributesSelected != null) commit("SET_LAST_ATTRIBUTES_SELECTED", null);
    commit("SET_LAST_ATTRIBUTES_SELECTED", value);
  },
  CHANGE_IS_OPEN_MENU_FONTS({ state, commit, dispatch }, value) {
    commit("SET_IS_SHOW_MENU_FONTS", value);
  },
  ADD_TYPOGRAPHY_SELECTED_LIST({ state, commit, dispatch }, value) {
    commit("SET_TYPOGRAPHY_SELECTED_LIST", []);
    commit("SET_TYPOGRAPHY_SELECTED_LIST", value);
  },
};