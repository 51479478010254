import gql from "graphql-tag";
import { POST } from "../../templates/graphql/variables";
import { FOLDER } from "./variables";
export const FIND_FOLDERS = gql(`
  query listFolders($type: String!, $clientId: Int!) {
    folders: listFolders(type: $type, clientId: $clientId) {
      ${FOLDER}
    }
  }
`);

export const GET_FOLDER = gql(`
  query getFolder($id:Int!){
    getFolder(id:$id){
      ${FOLDER}
    }
  }
`);

export const FIND_POSTS_BY_FOLDER = gql(`
  query postsByFolder($folderId:Int!){
    items:postsByFolder(folderId:$folderId){
      ${POST}
    }
  }
`);

export const FIND_POSTS_BY_FOLDER_PAGINATE_ORDER = gql(`
  query postsByFolderPaginateOrder($folderId:Int!,$take:Int!,$page:Int!,$order:String!){
    items:postsByFolderPaginateOrder(folderId:$folderId,take:$take,page:$page,order:$order){
      ${POST}
    }
  }
`);
