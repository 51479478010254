var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview-question__qr" }, [
    _c("label", { staticClass: "preview-question__label" }, [
      _vm._v(_vm._s(_vm.item.question)),
    ]),
    _vm._v(" "),
    _c("img", {
      staticClass: "mx-auto d-block",
      attrs: { src: _vm.item.qr.codeQr },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }