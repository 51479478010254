import gql from "graphql-tag";
//import { ADDON, COMMON_VAR } from '../../folders/graphql/variables';
import { QUESTION } from "./variables";
export const DELETE_QUESTION = gql(`
  mutation deleteQuestion($id: Int!){
    deleteComponentQuestion(id: $id)
  }
`);
export const UPDATE_QUESTION = gql(`
  mutation updateQuestion( $componentQuestion: ComponentQuestionDTO!, $id: Int!, ){
    question: updateComponentQuestion( componentQuestion: $componentQuestion, id: $id, ) {
			${QUESTION}
    }
  }
`);
export const CREATE_QUESTION = gql(`
  mutation createQuestion( $componentQuestion: ComponentQuestionDTO! ){
    question: createComponentQuestion(componentQuestion: $componentQuestion) {
			${QUESTION}
    }
  }
`);
export const UPDATE_QUESTION_POSITIONS = gql(`
  mutation updateQuestionPositions( $positions: [Int!]!, $ids: [Int!]! ){
    question: updatePositionsComponentQuestion(positions: $positions, ids: $ids) {
			${QUESTION}
    }
  }
`);
