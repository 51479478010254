export default {
  ADD_FILTER_IMAGE: (state, image) => {
    state.images = [...state.images, image];
  },
  SET_IS_CROP: (state, isCrop) => {
    state.isCrop = isCrop;
  },
  SET_CROP_RESIZE: (state, selectedStylesCrop) => {
    state.selectedStylesCrop = selectedStylesCrop;
  },
  SET_CROP_STYLES: (state, selectedStylesCrop) => {
    state.selectedStylesCrop = selectedStylesCrop;
  },
  SET_IS_CROP_SAVED: (state, isCropSaved) => {
    state.isCropSaved = isCropSaved;
  },
};
