const mutations = {
  SET_NAME(state, value) {
      state.name = value
  },
  SET_REFRESH(state, value) {
      state.refresh = value
  },
  SET_TOKEN(state, value) {
      state.token = value
  },
  SET_OBJ_USUARIO(state, obj) {
      state.objUsuario = obj
  }
};
export default mutations;