export const SHAPE = ["id", "name", "groupId", "_position", "objectsId"];
export const CHILD = [
  "id",
  "name",
  "groupId",
  "position:  _position",
  "objectsId",
  "figureType",
  "figure",
  "width",
  "height",
];
