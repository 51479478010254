/* eslint-disable no-debugger */
import types from "./mutation-types";
import { v4 as uuidv4 } from "uuid";

export default {
  activeElement({ commit, state }, { id }) {
    const element = state.elements.find((e) => e.id === id);
    commit("SET_ACTIVE_ELEMENT", element);
  },
  setActive({ commit, state }, { index }) {
    for (let i = 0, l = state.elements.length; i < l; i++) {
      if (i === index) {
        commit(types.ENABLE_ACTIVE, i);
        continue;
      }
      commit(types.DISABLE_ACTIVE, i);
    }
  },
  setItem: ({ commit, state }, item) => {
    state.elements.push(item);
    console.log(state.elements);
    commit("SET_ITEMS", state.elements);
  },
  setFolder: ({ commit }, item) => {
    commit("SET_FOLDER", item);
  },
  cloneItem: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid }
  ) => {
    const images = drag.images;
    const image = images.find((i) => i.uuid === uuid);
    const newUUID = uuidv4();
    images.push({
      ...image,
      x: image.x + 20,
      y: image.y + 20,
      uuid: newUUID,
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    commit("post/image/SET_SELECTED_IMAGE", newUUID, { root: true });
  },
  moveItem: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index }
  ) => {
    const images = drag.images;

    const itemSelected = images.find((i) => i.uuid === uuid);
    const key = images.findIndex((i) => i.uuid === uuid);

    switch (index) {
      case "send-forward":
        if (key > 0) {
          const temp = itemSelected;
          images[key] = images[key - 1];
          images[key - 1] = temp;
        }
        break;
      case "bring-forward":
        if (key < images.length - 1) {
          const temp = itemSelected;
          images[key] = images[key + 1];
          images[key + 1] = temp;
        }
        break;
      case "bring-to-front":
        if (key < images.length - 1) {
          images.push(itemSelected);
          images.splice(key, 1);
        }
        break;
      case "send-to-back":
        if (key > 0) {
          images.unshift(itemSelected);
          images.splice(key + 1, 1);
        }
        break;
    }

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    commit("post/drag/REMOVE_STYLES_SELECTED", null, { root: true });
  },
  blockItem: ({ commit, state }, { id, action }) => {
    const elements = state.elements.map((e) => {
      if (e.id === id) {
        e.draggable = action;
        e.resizable = action;
        if (action) {
          commit("SET_TYPE", e.type);
          commit("SET_TOOLBAR", false);
        } else {
          commit("SET_TYPE", null);
          commit("SET_TOOLBAR", true);
        }
      }
      return e;
    });
    commit("SET_ITEMS", elements);

    const element = state.element;
    element.draggable = action;
    element.resizable = action;
    if (action) {
      commit("SET_TYPE", element.type);
    } else {
      commit("SET_TYPE", null);
    }
    commit("SET_ACTIVE_ELEMENT", element);
  },
  filterItem: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, filter }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          filter,
        };
      } else {
        return item;
      }
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  flipItem: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, flip }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          flip,
        };
      } else {
        return item;
      }
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  initCrop: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, cropFlag, cropEdit }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          cropFlag,
          cropEdit,
        };
      } else {
        return item;
      }
    });
    // commit("SET_ITEMS", elements);
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  setCrop: ({ commit, state }, { id, path }) => {
    //debugger
    const elements = state.elements.map((e) => {
      if (e.id === id) {
        e.path = path;
      }
      return e;
    });
    commit("SET_ITEMS", elements);
  },
  removeItem: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid }
  ) => {
    const images = drag.images.filter((i) => i.uuid !== uuid);

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    commit("post/image/SET_SELECTED_IMAGE", null, { root: true });
    commit("post/drag/REMOVE_STYLES_SELECTED", null, { root: true });
  },
  unsetActive({ commit }, { id }) {
    commit(types.DISABLE_ACTIVE, id);
  },
  toggleDraggable({ commit, state }, { id }) {
    if (!state.rects[id].draggable) {
      commit(types.ENABLE_DRAGGABLE, id);
    } else {
      commit(types.DISABLE_DRAGGABLE, id);
    }
  },

  toggleResizable({ commit, state }, { id }) {
    if (!state.rects[id].resizable) {
      commit(types.ENABLE_RESIZABLE, id);
    } else {
      commit(types.DISABLE_RESIZABLE, id);
    }
  },

  toggleParentLimitation({ commit, state }, { id }) {
    if (!state.rects[id].parentLim) {
      commit(types.ENABLE_PARENT_LIMITATION, id);
    } else {
      commit(types.DISABLE_PARENT_LIMITATION, id);
    }
  },

  toggleSnapToGrid({ commit, state }, { id }) {
    if (!state.rects[id].snapToGrid) {
      commit(types.ENABLE_SNAP_TO_GRID, id);
    } else {
      commit(types.DISABLE_SNAP_TO_GRID, id);
    }
  },

  setAspect({ commit }, { id }) {
    commit(types.ENABLE_ASPECT, id);
  },
  unsetAspect({ commit }, { id }) {
    commit(types.DISABLE_ASPECT, id);
  },

  setWidth({ commit }, { id, width }) {
    commit(types.CHANGE_WIDTH, { id, width });
  },

  setHeight({ commit }, { id, height }) {
    commit(types.CHANGE_HEIGHT, { id, height });
  },

  setTop({ commit }, { id, top }) {
    commit(types.CHANGE_TOP, { id, top });
  },

  setLeft({ commit }, { id, left }) {
    commit(types.CHANGE_LEFT, { id, left });
  },

  changeXLock({ commit, state }, { id }) {
    switch (state.rects[id].axis) {
      case "both":
        commit(types.ENABLE_Y_AXIS, id);
        break;
      case "x":
        commit(types.ENABLE_NONE_AXIS, id);
        break;
      case "y":
        commit(types.ENABLE_BOTH_AXIS, id);
        break;
      case "none":
        commit(types.ENABLE_X_AXIS, id);
        break;
    }
  },

  changeYLock({ commit, state }, { id }) {
    switch (state.rects[id].axis) {
      case "both":
        commit(types.ENABLE_X_AXIS, id);
        break;
      case "x":
        commit(types.ENABLE_BOTH_AXIS, id);
        break;
      case "y":
        commit(types.ENABLE_NONE_AXIS, id);
        break;
      case "none":
        commit(types.ENABLE_Y_AXIS, id);
        break;
    }
  },

  changeZToBottom({ commit, state }, { id }) {
    if (state.rects[id].zIndex === 1) {
      return;
    }

    commit(types.CHANGE_ZINDEX, { id, zIndex: 1 });

    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (i !== id) {
        if (state.rects[i].zIndex === state.rects.length) {
          continue;
        }
        commit(types.CHANGE_ZINDEX, {
          id: i,
          zIndex: state.rects[i].zIndex + 1,
        });
      }
    }
  },

  changeZToTop({ commit, state }, { id }) {
    if (state.rects[id].zIndex === state.rects.length) {
      return;
    }

    commit(types.CHANGE_ZINDEX, { id, zIndex: state.rects.length });

    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (i !== id) {
        if (state.rects[i].zIndex === 1) {
          continue;
        }
        commit(types.CHANGE_ZINDEX, {
          id: i,
          zIndex: state.rects[i].zIndex - 1,
        });
      }
    }
  },

  setMinWidth({ commit }, { id, width }) {
    commit(types.CHANGE_MINW, { id, minw: width });
  },

  setMinHeight({ commit }, { id, height }) {
    commit(types.CHANGE_MINH, { id, minh: height });
  },
  transparencyItem: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, transparency }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          transparency,
        };
      } else {
        return item;
      }
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
};
