export const QUESTION = [
  "id",
  "addonId",
  "question",
  "textQuestion",
  "position",
  "options",
  "required",
  "options",
];
export const LEAD = ["id", "data", "addonId", "createdAt"];
