export const BACKGROUND = ["id", "path", "width", "height", "thumbnails"];
export const OBJECT = [
  "id",
  "uuid",
  "clientId",
  "typeUser",
  "keywords",
  "createdAt",
  "updatedAt",
];
