import gql from "graphql-tag";
import { POST } from "./variables";

//typeUser: admin
export const FIND_TEMPLATES = gql(`
  query find($page: Int!, $take: Int!) {
    templates(page: $page, take: $take) {
      ${POST}
    }
  }
`);

export const FIND_TEMPLATES_WITH_KEYWORDS = gql(`
	query templatesByKeywords ($names: [String!]!){
		templatesByKeywords: templatesByName(names: $names) {
			${POST}
		}
	}
`);

// typeUser: User, created Posts
export const FIND_POSTS_BY_TYPE = gql(`
	query findPostsByType ($page: Int!, $take: Int!, $names: [String!]!, $user: String!, $clientId:Int!){
		posts: templatesByName( page: $page, take: $take, names: $names, user: $user, clientId: $clientId) {
			${POST}
		}
	}
`);
