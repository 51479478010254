let transform = 1;

// if(window.screen.width <= 1920 && window.screen.width >= 1300) {
//   transform = 0.8
// } else if(window.screen.width < 1300) {
//   transform = 0.5
// } else {
//   transform = 1
// }
export default {
  zoom: {
    width: 940,
    height: 788,
    transform,
  },
  /* post: {
    title: 'Post (Fb/Ig/LinkedIn)',
    width: 940,
    height: 788,
  } */
};
