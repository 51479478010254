<template lang="">
  <v-btn
    title="Close"
    style="position: absolute; top: 12px; right: 15px"
    @click="$emit('click')"
    icon
  >
    <v-icon color="#444">mdi-close</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: 'CloseButton',
};
</script>
<style lang=""></style>
