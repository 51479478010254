import gql from "graphql-tag";
import { ADDON, COMMON_VAR } from "../../folders/graphql/variables";
import { QUESTION } from "./variables";
export const FIND_ADDON = gql(`
	query find($uuid: String!){
		addon: getAddonByUuid(uuid: $uuid) {
			${ADDON}
			${COMMON_VAR}
			questions{
				${QUESTION}
			}
		}
	}
`);
