var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "addon-preview__background" }, [
    _c("div", { style: [_vm.image.styles, { overflow: "hidden" }] }, [
      _c("div", { style: _vm.image.stylesCropped }, [
        _c("img", {
          staticStyle: { width: "100%", height: "100%" },
          attrs: { src: _vm.image.path, alt: "" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }