import gql from "graphql-tag";
//import { FOLDER } from "../../../../store/post/folders/graphql/variables";
import { COMMON_VAR, ADDON, POST, FOLDER } from "./variables";

export const FIND_FOLDERS_BY_TYPE = gql(`
  query listAddonsByType($type: String!, $page:Int, $take: Int) {
    items: listAddonsByType(type: $type, page: $page, take: $take) {
			${ADDON}
			${COMMON_VAR}
    }
  }
`);
export const FIND_BY_KEYWORDS = gql(`
	query addonsByKeywords ($keywords: [String!]!){
		items: addonsByKeywords(keywords: $keywords) {
			${ADDON}
			${COMMON_VAR}
		}
	}
`);

export const FIND_LATEST_POSTS = gql(`
  query latestPosts($take:Int!,$typeUser:String, $clientId:Int!){
    items: latestPosts(take:$take,typeUser:$typeUser, clientId:$clientId){
      ${POST}
    }
  }
`);

export const FIND_FOLDERS_WITH_POSTS = gql(`
  query listFoldersWithPosts($limitPost:Int!,$page:Int!,$take:Int!, $clientId:Int!){
    folders:listFoldersWithPosts(page:$page, take:$take, clientId:$clientId){
      count
      data{
        ${FOLDER}
        items:posts(limitPost: $limitPost){
          ${POST}
        }
      }

    }
  }
`);
