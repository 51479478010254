export const alertSetting = {
  data() {
    return {
      showAlert: false,
      icon: "",
      iconColor: "",
      title: "",
      paragraph: "",
      nameBtnAction: "",
      showBtn: true,
      showBtnClose: true,
      showMainIcon: true,
    };
  },
  methods: {
    resultModalAlerts(value) {
      this.showAlert = value;
    },
    modalMessageParams(icon, iconColor, title, paragraph, nameBtnAction) {
      this.showAlert = true;
      this.icon = icon;
      this.iconColor = iconColor;
      this.title = title;
      this.paragraph = paragraph;
      this.nameBtnAction = nameBtnAction;
    },
    successModal(message) {
      let paragraph = message;
      this.modalMessageParams(
        "fa fa-check-circle",
        "#00A5FF",
        "Success",
        paragraph,
        "Ok"
      );
    },
    successTitleModal(mainTitle, message){
      let paragraph = message;
      let title = mainTitle;
      this.modalMessageParams(
        "fa fa-check-circle",
        "#00A5FF",
        title,
        paragraph,
        "Ok"
      );
    },
    loadingModal(message) {
      let paragraph = message;
      this.showBtn = false;
      this.showMainIcon = false;
      this.showBtnClose = false;
      this.modalMessageParams(
        "",
        "",
        "Loading....",
        paragraph,
        ""
      );
    },
    errorModal(message) {
      let paragraph = message;
      this.modalMessageParams(
        "fa fa-times-circle",
        "#FF0000",
        "Error",
        paragraph,
        "Ok"
      );
    },
    warningModal(message) {
      let paragraph = message;
      this.modalMessageParams(
        "fa fa-exclamation-triangle",
        "#FFC107",
        "Warning",
        paragraph,
        "Ok"
      );
    },
    showButtonsModal(){
      this.showBtn = true;
      this.showMainIcon = true;
      this.showBtnClose = true;
    }
  },
};