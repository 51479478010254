import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { ApolloLink } from "@apollo/client/link/core";
//* Common Imports
import { authLink, responseInterceptor, errorLink } from "../utils/apollo.helper"
// Install the vue plugins
Vue.use(VueApollo);
// Http endpoint
let httpEndpoint;

if (process.env.NODE_ENV === "production") {
  httpEndpoint = process.env.API_GRAPHQL_DESIGN;
} else {
  httpEndpoint = process.env.API_LOCAL_GRAPHQL_DESIGN;
}


// Config
const defaultOptions = {
  httpEndpoint,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: ApolloLink.from([authLink, responseInterceptor, errorLink])
};

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });

  // Create vue apollo provider
  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "cache-and-network",
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        "%cError",
        "backgrounds: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    },
  });
}

export const apollo = createProvider().defaultClient;
