import { setContext } from "@apollo/client/link/context";
import { ApolloLink } from "@apollo/client/link/core";
import { onError } from "@apollo/client/link/error";
//* Common Imports
import { setToken } from "./utils";
import Store from "../store/index";
export const authLink = setContext((_, { headers }) => {
  const _TOKEN = sessionStorage.getItem("token");
  const _REFRESH_TOKEN = sessionStorage.getItem("refresh");
  const _IP_PUBLIC = sessionStorage.getItem("ip_public");
  const _IP_LOCAL = sessionStorage.getItem("ip_local");
  //* Check if token exists, if it is not found, redirect to login
  if (!_TOKEN || !_REFRESH_TOKEN || !_IP_PUBLIC || !_IP_LOCAL) {
    // Redirect to login page
    const pathUrl = `${process.env.URL_MONITOR}/login`;
    window.location.replace(pathUrl);
    throw new Error("No authentication token found. Redirecting to login.");
  }
  const _token = `${_TOKEN}|${_REFRESH_TOKEN}|${_IP_PUBLIC}|${_IP_LOCAL}`;
  
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${_token}`,
    },
  };
});

export const responseInterceptor = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    // Access headers from the context
    const context = operation.getContext();
    const responseHeaders = context.response?.headers;
    // Log or process the headers as needed
    if (responseHeaders) {
      // Example: Store a custom header globally
      const strNewToken = responseHeaders.get("X-New-Token");
      //console.log("Resp X-New-Token, ", strNewToken);
      // this is for failing, "you are not authorized"
      // so we clean yours tokens
      if (!strNewToken) return response;
      if (strNewToken === "Token renewal failed") return response;
      //console.log("Pass token ");
      setToken(strNewToken);
    }

    return response;
  });
});

// Error link to handle GraphQL and network errors
export const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);

        // Handle unauthorized errors
        if (message.includes("Unauthorized")) {
          console.log("Enter unauthorized");
          Store.dispatch("global/util/triggerModal");
        }
      });
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);

      // Handle network-specific issues
      if (networkError.message.includes("Failed to fetch")) {
        //Vue.prototype.$root.$emit("network-error-modal");
      }
    }

    // Optionally retry the request
    return forward(operation);
  }
);
