var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      class: _vm.css,
      attrs: {
        block: _vm.block,
        disabled: _vm.disabled || _vm.loading,
        color: _vm.color,
      },
      on: {
        click: function ($event) {
          return _vm.callback($event)
        },
      },
    },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "white", size: 25 },
          })
        : _c("div", { staticClass: "text-white" }, [
            _c("b", [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }