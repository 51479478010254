<template>
  <v-btn
    :block="block"
    :disabled="disabled || loading"
    :color="color"
    outlined
    @click="callback($event)"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="white"
      :size="25"
    ></v-progress-circular>
    <div v-else>{{ name }}</div>
  </v-btn>
</template>


<script>
export default {
  name: "DsButtonOutlined",
  props: {
    name: {
      type: String,
      default: "",
    },
    css: {
      type: String,
      default: "button-submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#5D6B88",
    },
    loading: { type: Boolean, default: false },
  },
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

