<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M10 0.8125C4.64844 0.8125 0.3125 5.14844 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3516 20.1875 19.6875 15.8516 19.6875 10.5C19.6875 5.14844 15.3516 0.8125 10 0.8125Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IcoShapes",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#96A0B8";
      },
    },
  },
};
</script>
