var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "overlay-color": "rgba(93, 107, 136, 0.5)",
            "overlay-opacity": "1",
            width: "566",
            persistent: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "font-weight-bold choose-shadow",
                          style: [_vm.disabled && _vm.disabledButtonStyle],
                          attrs: {
                            color: "primary",
                            dark: "",
                            height: "35",
                            width: "67",
                            small: "",
                            disabled: _vm.disabled,
                          },
                          on: { click: _vm.triggerInitDialog },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("\n        Save\n      ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "modalA",
              style: _vm.bodyFolders ? "height: 242px;" : "height: 221px;",
            },
            [
              !_vm.loading.load
                ? _c("v-card-title", { staticClass: "modalA_header" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.bodyOk,
                          expression: "!bodyOk",
                        },
                      ],
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "true" },
                      on: { click: _vm.closeDialog },
                    }),
                  ])
                : _c("v-card-title", { staticClass: "modalA_empty-icon" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bodyInit,
                      expression: "bodyInit",
                    },
                  ],
                  staticClass: "modalA_body",
                },
                [
                  _vm._v("\n        Do you want to "),
                  _c("b", [_vm._v("save all the changes?")]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bodyFolders,
                      expression: "bodyFolders",
                    },
                  ],
                  staticClass: "modalA_folders",
                },
                [
                  _vm._v(
                    "\n        Choose a folder to save your " +
                      _vm._s(_vm.type) +
                      ":\n        "
                  ),
                  _c(
                    "div",
                    { staticClass: "select-folders" },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.folders,
                          outlined: "",
                          dense: "",
                          "item-text": "name",
                          "return-object": "",
                          placeholder: "Folders",
                          "menu-props": { contentClass: "menuStyleDes" },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append-item",
                            fn: function () {
                              return [
                                _c(
                                  "v-list-item",
                                  { on: { click: _vm.newFolder } },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass: "d-flex align-center",
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  size: "12",
                                                  color: "#96a0b8",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      fas fa-plus\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-2 bold" },
                                              [_c("b", [_vm._v("New Folder")])]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.folderSelected,
                          callback: function ($$v) {
                            _vm.folderSelected = $$v
                          },
                          expression: "folderSelected",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bodyOk,
                      expression: "bodyOk",
                    },
                  ],
                  staticClass: "modalA_body",
                  class: _vm.pSidesTop,
                },
                [
                  _vm._v(
                    "\n        Your " +
                      _vm._s(_vm.type) +
                      ' " ' +
                      _vm._s(_vm.nameProject) +
                      ' "\n        '
                  ),
                  _c("b", [_vm._v("has been saved successfully!")]),
                ]
              ),
              _vm._v(" "),
              _c("v-card-actions", [
                _c(
                  "div",
                  {
                    staticClass: "buttons_wrapper",
                    class: _vm.marginTopButton,
                  },
                  [
                    !_vm.loading.load
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.bodyOk,
                                expression: "!bodyOk",
                              },
                            ],
                            staticClass: "buttons buttons_back",
                            on: { click: _vm.closeDialog },
                          },
                          [_vm._v("\n            Back\n          ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.bodyOk,
                            expression: "!bodyOk",
                          },
                        ],
                        attrs: {
                          color: "rgba(0, 165, 255, 1)",
                          loading: _vm.loading.load,
                          dark: "",
                          height: 35,
                          width: 67,
                        },
                        on: { click: _vm.changeDialog },
                      },
                      [_vm._v("\n            Save\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.bodyOk,
                            expression: "bodyOk",
                          },
                        ],
                        staticClass: "buttons buttons_save",
                        on: { click: _vm.sendToHome },
                      },
                      [_vm._v("\n            Ok\n          ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          staticClass: "save-modal",
          attrs: { width: "566", persistent: "" },
          model: {
            value: _vm.createFolder,
            callback: function ($$v) {
              _vm.createFolder = $$v
            },
            expression: "createFolder",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "modalB" },
            [
              _c("v-card-title", { staticClass: "modalB_header" }, [
                _c("i", {
                  staticClass: "fa fa-times",
                  attrs: { "aria-hidden": "true" },
                  on: { click: _vm.closeFolderModal },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modalB_body" },
                [
                  _c("v-col", { staticClass: "style_label" }, [
                    _c("h4", { staticClass: "style_label_name" }, [
                      _vm._v("New folder name:"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "box_text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.folderNew,
                          expression: "folderNew",
                        },
                      ],
                      staticClass: "style-input-text",
                      attrs: { type: "text", placeholder: "Nombre del folder" },
                      domProps: { value: _vm.folderNew },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.folderNew = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "btn-save-folder" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "rgba(0, 165, 255, 1)",
                            loading: _vm.loadingFolder,
                            dark: "",
                            height: 35,
                            width: 67,
                          },
                          on: { click: _vm.setFolder },
                        },
                        [_vm._v("\n            Save\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }