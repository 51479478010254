export default {
  SET_NAME_POST: (state, name) => {
    state.name = name;
  },
  SET_ID_POST: (state, id) => {
    state.id = id;
  },
  SET_UUID_POST: (state, uuid) => {
    state.postUuid = uuid;
  },
  SET_ID_FOLDER: (state, folderId) => {
    state.folderId = folderId;
  },
};
