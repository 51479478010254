export default {
  addon: [],
  contest: [],
  survey: [],
  promo: [],
  audience: [],
  addonsByKeywords: [],
  addonsByFolder: [],
  folderView: {},
  moreAddon: true,
  isDeleteAddon: false,
  isLoading: false,
  isLoadingContest: false,
  isLoadingSurvey: false,
  isLoadingPromo: false,
  isLoadingAudience: false,
};
