<template>
	<div  class="horizontal-masonry horizontal-masonry--h ">
			<element
				v-for="(item, i) in items"
				:key="i"
				class="horizontal-masonry-brick horizontal-masonry-brick--h"
			>
				<div class="progress">
					<div 
						v-if="item.progress < 100 && !item.error"
						style="text-align: center; padding-top: 57px;"
					>
						<v-progress-linear
							class="progress-linear"
							v-model="item.progress"
							:color="linearColor"
							background-color="#FFFFFF"
						>
						</v-progress-linear>
						<strong
							:style="{ 'color': colorMessage }"
							class="percentage"
						>
							{{ item.progress }}%
						</strong>
					</div>
					<div 
						v-if="item.progress === 100"
					>	
						<div v-if="!item.error" class="custom-linear" >
							<v-progress-linear
								indeterminate
								:color="circularColor"
								size="17"
								width="3"
							>
							</v-progress-linear>
						</div>
						<div v-else> 
							<cp-icon-error 
								:color-message="colorMessage"
							/>	
						</div>
						<div 
							:style="{ 'color': colorMessage }"
							class="message"
						>
							<p 
								v-if="!item.error"
								class="message_text"
							> 
								Generating thumbnails ... 
							</p>
							<p
								v-else 
								class="message_text"
							> 
								{{ item.msgError }}
							</p>
						</div>	
					</div>
					<div v-if="item.progress < 100 && item.error">
						<cp-icon-error 
							:color-message="colorMessage"
						/>
						<div 
							:style="{ 'color': colorMessage }"
							class="message"
						>
							<p
								class="message_text"
							> 
								{{ item.msgError }}
							</p>
						</div>	
					</div>
				</div>
			</element>
		</div>
</template>

<script>
import CpIconError from "./CpIconError.vue";
export default {
	name: 'CpProgressLinear',
	components: { CpIconError },
	props: {
		items: {
			type: Array,
			default: () =>{
				return []
			}
		}, 
		linearColor: {
			type: String,
			default: '#2C3852'
		},
		circularColor: {
			type: String,
			default: '#2C3852'
		}, 
		errorText: {
			type: String,
			default: 'Your image is too large. Please reduce the image dimensions.'
		}, 
		colorMessage: {
			type: String,
			default: '#2C3852'
		}
	},
};
</script>

<style lang="scss" scoped>
	.progress {
		display: flex;
    	flex-direction: column;
    	align-items: center;
		min-height: 129px;
		background: rgba(0, 165, 255, .6);
		opacity: 0.9;
		&-linear {
			width: 65px;
			border-radius: 2px;
			margin-bottom: 3px;			
		}
		.percentage {
			font-weight: 700;
			font-size: 10px;
			line-height: 11px;
			opacity: 0.9;
			text-align: center;
		}
		.custom-linear {
			margin: 49px 20px 4px 20px;
		}
		.message {
			align-items: center;
			text-align: center;
			font-family: 'Oxygen';
			font-style: normal;
			&_text {
				font-weight: 400;
				font-size: 10px;
				padding: 0px 10px;
			}
		}
		.icon-alert{
			text-align: center;
			padding-top: 23px;
			&__error{
				font-weight: 700;
				font-size: 10px;
				margin: 1px 0 0 0;
				height: 17px;
			}
		}
	}	
</style>