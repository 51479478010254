var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview-question__range" }, [
    _c("label", { staticClass: "preview-question__label" }, [
      _vm._v(_vm._s(_vm.item.question) + " "),
      _vm.item.required ? _c("span", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "pa-0 preview-question__list-range" },
      _vm._l(_vm.item.rangeReaction, function (number) {
        return _c(
          "li",
          {
            key: number,
            staticClass: "list-style-none d-flex align-center p-relative",
          },
          [
            _c("input", {
              staticClass: "preview-question__item-range mr-2",
              attrs: {
                type: "checkbox",
                name: "rdb-" + _vm.item.id,
                id: "rdb-" + _vm.item.id + "-" + number,
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                on: {
                  click: function ($event) {
                    return _vm.setAnswerRange(
                      _vm.item.id,
                      _vm.item.idQuestionDb,
                      _vm.item.question,
                      number,
                      _vm.item.required
                    )
                  },
                },
              },
              [
                _vm.item.reaction.name == "numbers"
                  ? _c("span", [_vm._v(" " + _vm._s(number) + " ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.reaction.name !== "numbers"
                  ? _c("v-icon", { attrs: { color: "#5D6B88", size: "16" } }, [
                      _vm._v(_vm._s(_vm.item.reaction.icon)),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "preview-question__item-range_group" }, [
      _c("span", [_vm._v(_vm._s(_vm.ranges.low))]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.ranges.high) + " ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }