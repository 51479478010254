<template>
  <div class="preview-question__qr">
    <label class="preview-question__label mb-3"
      >{{ item.question }} <span v-if="item.required">*</span></label
    >
    <input
      type="text"
      class="preview-question__input"
      placeholder="Answer goes here"
      v-model="textAnswer"
      @keyup="
        setAnswerByItem(
          item.id,
          item.idQuestionDb,
          item.question,
          textAnswer,
          item.required
        )
      "
    />
    <span v-if="item.required && error" class="preview-question__error-message">
      This field is required
    </span>
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsOpenText",
  data() {
    return {
      textAnswer: "",
      error: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  methods: {
    setAnswerByItem(id, idQuestionDb, question, answer, required) {
      this.validation(answer);
      this.$emit("open-text", id, idQuestionDb, question, answer, required);
    },
    validation(answer) {
      if (!answer && this.item.required) this.error = true;
      else this.error = false;
    },
  },
  // computed: {
  //   ranges() {
  //     return [...Array(this.item.highrange).keys()].map(i => i + this.item.lowrange);
  //   }
  // }
};
</script>

<style lang="scss">
.preview-question {
  &__error-message {
    color: red;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
}
</style>
