import { LinearGradient } from "vue-gpickr";

export default {
  backgrounds: [],
  backgroundsByKeywords: [],

  list: [],
  find: [],
  read: {},
  background: "",
  type: null,
  bg: { background: "#FFFFFF" },
  //gradientColor: { background: "#FFAA5C linear-gradient(#FC2BDA 0%, rgba(255, 255, 255, 0) 100%)" }
  gradientColor: new LinearGradient({
    angle: 0,
    stops: [
      ["#FFFFFF", 0],
      ["#FFFFFF", 1],
    ],
  }),
  isLoading: false
};
