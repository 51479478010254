import gql from "graphql-tag";
import { FOLDER, ADDON, COMMON_VAR } from "../../post/graphql/variables";

export const CLONE_ADDON = gql(`
  mutation cloneAddon($addon:CloneAddonDTO!){
    cloneAddon(addon:$addon){
      ${ADDON}
			${COMMON_VAR}
    }
  }
`);

export const DELETE_ADDON = gql(`
  mutation deleteAddon($id:Int!){
    deleteAddon(id:$id)
  }
`);

export const CLONE_FOLDER = gql(`
  mutation cloneFolder($folderContext:FolderContextDTO!,$limitAddon:Int!){
    cloneFolder(folderContext:$folderContext){
      ${FOLDER}
      items:addons(limitAddon: $limitAddon){
        ${ADDON}
				${COMMON_VAR}
      }
    }
  }
`);

export const DELETE_FOLDER = gql(`
  mutation deleteFolder($id:Int!){
    deleteFolder(id:$id)
  }
`);
