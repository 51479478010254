export default {
  SET_BACKGROUND_ADDON: (state, item) => {
    // set all addon attributes from back

    state.addon = { ...state.addon, ...item };
    state.addonsaved = { ...state.addon, ...item };
  },
  SET_HORIZONTAL: (state, x) => {
    state.addon = { ...state.addon, x };
  },
  SET_VERTICAL: (state, y) => {
    state.addon = { ...state.addon, y };
  },
  SET_WIDTH: (state, width) => {
    state.addon = { ...state.addon, width };
  },
  SET_HEIGHT: (state, height) => {
    state.addon = { ...state.addon, height };
  },
  SET_ORIGIN_POSITION: (state, { width, height, x, y }) => {
    state.addon = { 
      ...state.addon, 
      origin: {
        ...state.addon.origin,
        width,
        height,
        x,
        y
      } 
    };
  },
  SET_CROPPED: (state, cropped) => {
    state.addon = { ...state.addon, cropped };
  },
  SET_CROPPED_SIZE: (state, { width, height }) => {
    state.addon = { 
      ...state.addon, 
      cropped: {
        ...state.addon.cropped,
        width,
        height
      }
    };
  },
  SET_CROPPED_POSITION: (state, { x, y }) => {
    state.addon = { 
      ...state.addon, 
      cropped: {
        ...state.addon.cropped,
        x,
        y
      }
    };
  },
  SET_TRANSFORM_FLIP: (state, { x, y }) => {
    state.addon = {
      ...state.addon,
      transform: {
        ...state.addon.transform,
        scale: {
          x,
          y,
        },
      },
    };
  },
  SET_CROP: (state, { cropFlag, cropEdit }) => {
    state.addon.cropFlag = cropFlag;
    state.addon.cropEdit = cropEdit;
  },
  SET_IMAGE_CROP: (state, path) => {
    state.addon.path = path;
  },
  SET_SAVED_IMAGE: (state, item) => {
    state.addonsaved = item;
  },
  /*
   *  QUESTIONS - TAB
   */
  SET_TYPE_QUESTION: (state, refactorQuestion) => {
    state.questions = state.questions.map((el) => {
      if (el.id === refactorQuestion.id) return refactorQuestion;
      else return el;
    });
    state.addon.question = state.questions;
  },
  SET_QUESTIONS: (state, items) => {
    state.addon.question = items;
    state.questions = items; // if it fails, take it off
  },
  SET_ITEM_QUESTION: (state, item) => {
    state.questions = [...state.questions, item];
    state.addon.question = state.questions;
  },
  SET_QUESTION_BY_ITEM: (state, item) => {
    state.addon.question[item.index].question = item.text;
  },
  SET_CHANGE_REACTION: (state, { id, swiftIcon }) => {
    state.questions.forEach((el) => {
      if (el.id === id) {
        el.reaction = {
          name: swiftIcon.name,
          icon: swiftIcon.icon,
        };
      }
    });
  },
  SET_NUMBER_REACTION: (state, { id, range }) => {
    state.questions.forEach((el) => {
      if (el.id === id) {
        el.rangeReaction = range;
      }
    });
  },
  SET_DATE: (state, data) => {
    state.questions.forEach((el) => {
      if (el.id === data.id) {
        el.date = data.date;
      }
    });
  },
  SET_LIST_QUESTIONS: (state, listQuestions) => {
    state.listQuestions = listQuestions.sort((a, b) => a.id - b.id);
  },
  /*
   * MESSAGE - TAB
   */
  SET_MESSAGE_ITEMS: (state, item) => {
    // create a new attribute "message to addon object"
    state.addon.message = item;
  },
  SET_MESSAGE_REDO:(state,item)=>{
    state.message=item
  },
  SET_DESIGN_ITEMS: (state, item) => {
    state.addon.design = item;
  },
  SET_DESIGN_REDO: (state, item) => {
    state.design = item;
  },
  SET_TITLE: (state, title) => {
    state.addon.message.title = title;
  },
  SET_DESCRIPTION: (state, description) => {
    state.addon.message.description = description;
  },
  SET_CONFIRMATION_MESSAGE: (state, confirmation) => {
    state.addon.message.confirmation = confirmation;
  },
  SET_BUTTON: (state, button) => {
    state.addon.message.button = button;
  },
  SET_QR(state, { code, id }) {
    state.questions.forEach((el) => {
      if (el.id === id) {
        el.qr.codeQr = code;
      }
    });
    /* state.qr.link = data.link
    state.qr.description = data.description */
  },
  SET_QR_DESCRIPTION(state, { description, id }) {
    //state.qr.description = description
    //console.log(' id ', id, description );
    state.questions.forEach((el) => {
      if (el.id === id) {
        el.question = description;
      }
    });
  },
  SET_ADDON: (state, addon) => {
    state.addon = { ...state.addon, addon };
  },
  SET_IS_LOCKED: (state, locked) => {
    state.addon = { ...state.addon, locked };
  },
  SET_ROTATE: (state, degree) => {
    state.addon = {
      ...state.addon,
      transform: {
        ...state.addon.transform,
        rotate: degree,
      },
    };
  },
  SET_NAME: (state, name) => {
    state.addon.name = name;
  },
  SET_ID: (state, id) => {
    state.addon.addonId = id;
  },
  SET_UUID: (state, uuid) => {
    state.addon.addonUuid = uuid;
  },
  SET_ADDON_EDIT: (state, { item, questions }) => {
    const {
      dataObjects: { logoPreview, buttonMessage, image, transform, cropped, x, y },
    } = item;
    // message
    let message = {
      title: item.title,
      description: item.description,
      confirmation: item.message,
      logo: item.logo,
      logoPreview,
      button: buttonMessage,
    };
    // set addon
    (state.addon = {
      addonUuid: item.uuid,
      addonId: item.id,
      folderId: item.folderId,
      name: item.name,
      question: questions,
      message,
      design:
        Object.keys(item.styles).length === 0 ? state.design : item.styles,
      ...image,
      transform: { ...transform },
      cropped: { ...cropped },
      x,
      y,
      original: {
        width: image.width,
        height: image.height,
        x,
        y
      },
      origin: {
        width: image.width,
        height: image.height,
        x,
        y
      }
    }),
      (state.message = message),
      (state.questions = questions),
      (state.design = state.addon.design);
    // mutate state variables
  },
  RESET_ADDON: (state) => {
    (state.addon = {
      addonId: null,
      addonUuid: null,
      name: "",
      question: [],
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      transform: {
        scale: {
          x: 1,
          y: 1,
        },
        rotate: 0,
      },
    }),
      (state.questions = []);
    (state.answers = []),
      (state.qr = {
        link: null,
        description: null,
      }),
      (state.message = {
        logo: null,
        title: "",
        description: "",
        confirmation: "",
        button: {
          name: "Send",
          url: "",
        },
      }),
      (state.design = {
        backcolor: "#F3F4F7",
        buttoncolor: "#96A0B8",
        buttontextcolor: "#FFFFFF",
      }),
      (state.addonsaved = {
        transform: {
          scale: {
            x: 1,
            y: 1,
          },
          rotate: 0,
        },
      });
  },
};
