var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview-question__qr" }, [
    _c("label", { staticClass: "preview-question__label mb-3" }, [
      _vm._v(_vm._s(_vm.item.question) + " "),
      _vm.item.required ? _c("span", [_vm._v("*")]) : _vm._e(),
    ]),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.textAnswer,
          expression: "textAnswer",
        },
      ],
      staticClass: "preview-question__input",
      attrs: { type: "text", placeholder: "Answer goes here" },
      domProps: { value: _vm.textAnswer },
      on: {
        keyup: function ($event) {
          return _vm.setAnswerByItem(
            _vm.item.id,
            _vm.item.idQuestionDb,
            _vm.item.question,
            _vm.textAnswer,
            _vm.item.required
          )
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.textAnswer = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _vm.item.required && _vm.error
      ? _c("span", { staticClass: "preview-question__error-message" }, [
          _vm._v("\n    This field is required\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }