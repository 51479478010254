const actions = {
    CAN_REDO: ({ commit }, index) => {
        commit('CAN_REDO', index)
    },
    CAN_UNDO: ({ commit }, index) => {
        commit('CAN_UNDO', index)
    },
    REDO: ({ commit, getters }) => { // avanzar
        const history = getters.history;
        const nextState = history[getters.historyIndex + 1];
        commit('UPDATE_INDEX', 1)
        // commit('post/drag/REDO_UNDO_POST', nextState, { root: true })

        commit('addons/create/SET_BACKGROUND_ADDON', nextState ? nextState.create.addon : {
            addonUuid: null,
            addonId: null,
            name: "",
            question: [],
            path: null,
            width: 0,
            height: 0,
            x: 0,
            y: 0,
            transform: {
              scale: {
                x: 1,
                y: 1,
              },
              rotate: 0,
            },
            cropped: {
              width: 0,
              height: 0,
              x: 0,
              y: 0,
            }
          }, { root: true })
          
        commit('addons/create/SET_QUESTIONS', nextState ? nextState.create.questions : [], { root: true })
        const messageItem = nextState ? nextState.create.addon.message ? nextState.create.addon.message : {
            logoPreview: null,
            logo: "",
            title: "",
            description: "",
            confirmation: "",
            button: {
                name: "Send",
                url: "",
            }
        } : {
            logoPreview: null,
            logo: "",
            title: "",
            description: "",
            confirmation: "",
            button: {
                name: "Send",
                url: "",
            }
        }
        commit('addons/create/SET_MESSAGE_REDO', messageItem, { root: true })

        const designItem = {
            backcolor: nextState ? nextState.create.design.backcolor : "#F3F4F7",
            buttoncolor: nextState ? nextState.create.design.buttoncolor : "#96A0B8",
            buttontextcolor: nextState ? nextState.create.design.buttontextcolor : "#FFFFFF"
        }
        commit('addons/create/SET_DESIGN_REDO', designItem, { root: true })
    },
    UNDO: ({ commit, getters }) => { // retroceder
        const history = getters.history;
        const prevState = history[getters.historyIndex - 1];
        commit('UPDATE_INDEX', -1)

        commit('addons/create/SET_BACKGROUND_ADDON', prevState ? prevState.create.addon: {
            addonUuid: null,
            addonId: null,
            name: "",
            question: [],
            path: null,
            width: 0,
            height: 0,
            x: 0,
            y: 0,
            transform: {
              scale: {
                x: 1,
                y: 1,
              },
              rotate: 0,
            },
            cropped: {
              width: 0,
              height: 0,
              x: 0,
              y: 0,
            }
          }, { root: true })

        const messageItem = prevState ? prevState.create.addon.message ? prevState.create.addon.message : {
            logoPreview: null,
            logo: "",
            title: "",
            description: "",
            confirmation: "",
            button: {
                name: "Send",
                url: "",
            }
        } : {
            logoPreview: null,
            logo: "",
            title: "",
            description: "",
            confirmation: "",
            button: {
                name: "Send",
                url: "",
            }
        }
        commit('addons/create/SET_QUESTIONS', prevState ? prevState.create.questions : [], { root: true })
        commit('addons/create/SET_MESSAGE_REDO', messageItem, { root: true })

        const designItem = {
            backcolor: prevState ? prevState.create.design.backcolor : "#F3F4F7",
            buttoncolor: prevState ? prevState.create.design.buttoncolor : "#96A0B8",
            buttontextcolor: prevState ? prevState.create.design.buttontextcolor : "#FFFFFF"
        }
        commit('addons/create/SET_DESIGN_REDO', designItem, { root: true })

    }
}

export default actions;