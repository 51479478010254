<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8359 10.8828L9.49219 8.53906C9.375 8.44531 9.23438 8.375 9.09375 8.375H8.71875C9.35156 7.55469 9.75 6.52344 9.75 5.375C9.75 2.70312 7.54688 0.5 4.875 0.5C2.17969 0.5 0 2.70312 0 5.375C0 8.07031 2.17969 10.25 4.875 10.25C6 10.25 7.03125 9.875 7.875 9.21875V9.61719C7.875 9.75781 7.92188 9.89844 8.03906 10.0156L10.3594 12.3359C10.5938 12.5703 10.9453 12.5703 11.1562 12.3359L11.8125 11.6797C12.0469 11.4688 12.0469 11.1172 11.8359 10.8828ZM4.875 8.375C3.21094 8.375 1.875 7.03906 1.875 5.375C1.875 3.73438 3.21094 2.375 4.875 2.375C6.51562 2.375 7.875 3.73438 7.875 5.375C7.875 7.03906 6.51562 8.375 4.875 8.375Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "IcoSearch",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#5D6B88";
      },
    },
  },
};
</script>
