import post from "./post/index";
import addon from "./addon/index";
import main from "./main/index";

export default {
  modules: {
    post,
    addon,
    main
  },
  namespaced: true,
};
