var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-100 bg-primary position-fixed bg-resize-modal vw-100 vh-100 top-0 left-0 bottom-0 right-0 d-flex align-items-center justify-content-center",
      style: { "z-index": 9999 },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "center-modal bg-white d-flex flex-column text-center",
        staticStyle: { "background-color": "white" },
      },
      [
        _c("div", { staticClass: "img-st d-flex justify-center" }, [
          _c("img", {
            staticClass: "img-loader",
            attrs: {
              src: "https://listen-images.s3.us-east-2.amazonaws.com/isotipo_isc_140x164.svg",
              alt: "",
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column align-items-center justify-center",
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "text-secondary" }, [
                _vm._v(
                  "\n          For a better experience, we suggest to display this website on a screen bigger than\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "text-secondary font-weight-bold" }, [
                _vm._v("\n          1024px (width)\n        "),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }