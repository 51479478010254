<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.4707 0.904297H5.5957V2.7793H7.4707V0.904297ZM0 2.7793V4.6543H1.875V2.7793H0ZM3.75 0.904297H1.875V2.7793H3.75V0.904297ZM5.625 8.4043V10.2793H7.5V8.4043H5.625ZM0 6.5293V8.4043H1.875V6.5293H0ZM11.2207 0.904297H9.3457V2.7793H11.2207V0.904297ZM14.9707 0.904297H13.0957V2.7793H14.9707V0.904297ZM3.75 8.4043H1.875V10.2793H3.75V8.4043ZM14.9707 14V12.125H13.0957V14H14.9707ZM14.9707 10.25V8.375H13.0957V10.25H14.9707ZM11.2207 15.875H13.0957V14H11.2207V15.875ZM14.9707 6.5293V4.6543H13.0957V6.5293H14.9707ZM3.75 15.875H5.625V14H3.75V15.875ZM0 15.875H1.875V14H0V15.875ZM7.4707 15.875H9.3457V14H7.4707V15.875ZM0 10.2793V12.1543H1.875V10.2793H0ZM9.3457 4.6543V2.7793H7.4707V4.6543H9.3457ZM7.4707 8.4043H9.3457V6.5293H7.4707V8.4043ZM5.5957 12.1543V14.0293H7.4707V12.1543H5.5957ZM9.3457 10.2793H11.2207V8.4043H9.3457V10.2793ZM9.3457 6.5293H11.2207V4.6543H9.3457V6.5293ZM9.3457 12.1543V14.0293H11.2207V12.1543H9.3457ZM1.875 12.1543V14.0293H3.75V12.1543H1.875ZM5.625 4.6543V2.7793H3.75V4.6543H5.625ZM11.2207 12.1543H13.0957V10.2793H11.2207V12.1543ZM7.4707 6.5293V4.6543H5.5957V6.5293H7.4707ZM11.2207 2.7793V4.6543H13.0957V2.7793H11.2207ZM7.4707 10.2793V12.1543H9.3457V10.2793H7.4707ZM5.5957 6.5293H3.75V8.4043H5.625L5.5957 6.5293ZM11.2207 8.4043H13.0957V6.5293H11.2207V8.4043ZM3.75 4.6543H1.875V6.5293H3.75V4.6543ZM3.75 10.2793V12.1543H5.625V10.2793H3.75Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: "IcoTransparency",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#5D6B88";
      },
    },
  },
};
</script>
