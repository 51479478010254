const mutations = {
  SET_TITLE_PAGE(state, title) {
    state.title_page = title;
  },
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange;
  },
  ADD_CONTEXT_BOARD(state, contextBoard) {
    state.contextBoard = contextBoard;
  },
  CHANGE_YOUR_BOARD(state, yourBoard) {
    state.yourBoard = yourBoard;
  },
  REMOVE_CONTEXT_BOARD(state) {
    state.contextBoard = "";
  },
  SET_GEO_DRIVERS(state, geoDrivers) {
    state.geoDrivers = geoDrivers;
  },
  CHANGE_STATE_MENUSIDEBAR(state, data) {
    state.disabledMenuItem = data;
  },
  CHANGE_ACTIVE_DATEPICKER(state, value) {
    state.datePickerActive = value;
  },
  CHANGE_ACTIVE_REFERENCE_INPUT(state, value) {
    state.isResetRefInput = value;
  },
  OPEN_MODAL_WORD(state) {
    state.openModalWord = true;
  },
  CLOSE_MODAL_WORD(state) {
    state.openModalWord = false;
  },
  SET_NAME(state, value) {
    state.name = value;
  },
  SET_REFRESH(state, value) {
    state.refresh = value;
  },
  SET_TOKEN(state, value) {
    state.token = value;
  },
  SET_OBJ_USUARIO(state, obj) {
    state.objUsuario = obj;
  },
  SHOW_MODAL(state) {
    state.isVisible = true;
  },
  HIDE_MODAL(state) {
    state.isVisible = false;
  },
  SET_MODAL_ALERT(state, configModal) {
    state.respModalAlert = configModal;
  },
};
export default mutations;
