const state = {
  title_page: "",
  disabledMenuItem: true,
  name: "User",
  token: sessionStorage.getItem("token") || "",
  refresh: sessionStorage.getItem("refresh") || "",
  objUsuario: {},
  loading: { load: false, status: false },
  editProject: false,
};

export default state;
