import gql from "graphql-tag";
import { TEXT } from "./variables";
export const FIND_TEXTS = gql(`
  query{
    texts: allTexts{
      ${TEXT}
    }
  }
`);

export const FIND_TEXT_BY_KEYWORDS = gql(`
  query textsByKeywords($keyword:[String!]!){
    textsByKeywords(keyword:$keyword){
      ${TEXT}
    }
  }
`);
