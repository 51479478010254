var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-alert" },
    [
      _c("v-icon", { attrs: { color: _vm.colorMessage, size: "17" } }, [
        _vm._v("\n    fa fa-exclamation-triangle\n  "),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "icon-alert__error",
          style: { color: _vm.colorMessage },
        },
        [_vm._v(" \n    Error \n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }