var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticStyle: { position: "absolute", top: "12px", right: "15px" },
      attrs: { title: "Close", icon: "" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_c("v-icon", { attrs: { color: "#444" } }, [_vm._v("mdi-close")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }