var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]),
      _vm._v(" "),
      _c("v-text-field", {
        staticClass: "base-input",
        attrs: {
          outlined: "",
          dense: "",
          success: _vm.success,
          type: _vm.type,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          readonly: _vm.readonly,
          autofocus: _vm.autofocus,
          "hide-details": "",
          "error-messages": _vm.modelError,
          color: _vm.color,
          autocomplete: "off",
          prefix: _vm.prefix,
          "append-icon": _vm.icon,
        },
        on: { input: _vm.verify, blur: _vm.verify },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }