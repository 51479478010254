var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-question__qr" },
    [
      _c("label", { staticClass: "preview-question__label mb-3" }, [
        _vm._v(_vm._s(_vm.item.question) + " "),
        _vm.item.required ? _c("span", [_vm._v("*")]) : _vm._e(),
      ]),
      _vm._v(" "),
      _c("v-text-field", {
        staticClass: "preview-question__input",
        attrs: {
          placeholder: "MM/DD/AAAA",
          solo: "",
          readonly: "",
          dense: "",
          "hide-details": "",
        },
        on: {
          click: function ($event) {
            return _vm.setDate()
          },
        },
        model: {
          value: _vm.computedDateFormatted,
          callback: function ($$v) {
            _vm.computedDateFormatted = $$v
          },
          expression: "computedDateFormatted",
        },
      }),
      _vm._v(" "),
      _vm.datepicker
        ? _c("v-date-picker", {
            attrs: { "no-title": "" },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }