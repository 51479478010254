var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "overlay-color": "rgba(93, 107, 136, 0.5)",
            "overlay-opacity": "1",
            width: "566",
            persistent: "",
          },
          model: {
            value: _vm.model,
            callback: function ($$v) {
              _vm.model = $$v
            },
            expression: "model",
          },
        },
        [
          _c("div", { staticClass: "isc-modal__content" }, [
            _c(
              "div",
              { staticClass: "isc-modal__header d-flex justify-end" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "isc-modal__content--btn-close",
                    attrs: { icon: "" },
                    on: { click: _vm.close },
                  },
                  [
                    _vm.showBtnClose
                      ? _c(
                          "svg",
                          {
                            attrs: {
                              width: "15",
                              height: "15",
                              viewBox: "0 0 15 15",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M8.37894 7.5039L14.8088 1.07402C15.057 0.834344 15.0639 0.438888 14.8242 0.190722C14.5845 -0.0574443 14.1891 -0.0643256 13.9409 0.175349C13.9357 0.180363 13.9306 0.185488 13.9255 0.190722L7.49561 6.62061L1.06572 0.190685C0.817555 -0.048989 0.422099 -0.0421077 0.182425 0.206059C-0.0513935 0.448149 -0.0513935 0.831928 0.182425 1.07402L6.61231 7.5039L0.182425 13.9338C-0.0614592 14.1777 -0.0614592 14.5732 0.182425 14.8171C0.426345 15.0609 0.8218 15.0609 1.06572 14.8171L7.49561 8.3872L13.9255 14.8171C14.1737 15.0568 14.5691 15.0499 14.8088 14.8017C15.0426 14.5596 15.0426 14.1758 14.8088 13.9338L8.37894 7.5039Z",
                                fill: "#696B76",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "isc-modal__icon" }, [
              _vm.showMainIcon
                ? _c("i", {
                    staticClass: "isc-modal__icon_style",
                    class: _vm.icon,
                    style: { color: _vm.iconColor },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "isc-modal__title" }, [
              _c("strong", [_vm._v(_vm._s(_vm.title))]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "isc-modal__subtitle" }, [
              _vm._v(_vm._s(_vm.paragraph)),
            ]),
            _vm._v(" "),
            !_vm.showLoader
              ? _c(
                  "div",
                  { staticClass: "isc-modal__footer" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "isc-modal__btn",
                        attrs: { color: _vm.colorBtn1 },
                        on: { click: _vm.sendResultBtn1 },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.btnName1) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "isc-modal__btn",
                        attrs: { color: _vm.colorBtn2 },
                        on: { click: _vm.sendResultBtn2 },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_vm.btnName2) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "isc-modal__loader" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }