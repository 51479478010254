<template>
  <div class="message">
    <div class="message__title">Confirmation Message</div>
    <div class="message__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationMessage",

  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  flex-direction: column;
  height: 244px;
  width: 468px;
  background-color: #ffffff;
  border: 1px;
  box-shadow: 0px 2px 4px rgba(39, 52, 68, 0.1);
  align-items: center;
  padding: 2rem;
  &__title {
    margin-top: 46px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 15px;
    color: #2c3852;
  }
  &__text {
    min-height: 48px;
    max-width: 202px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 12px;
    color: #5d6b88;
    text-align: center;
  }
}
</style>
