<template>
  <v-btn
    :class="css"
    :block="block"
    :disabled="disabled || loading"
    :color="color"
    @click="callback($event)"
  >
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="white"
      :size="25"
    ></v-progress-circular>
    <div class="text-white" v-else>
      <b>
        {{ name }}
      </b>
    </div>
  </v-btn>
</template>


<script>
export default {
  name: "DesignButtonSkyblue",
  props: {
    name: {
      type: String,
      default: "",
    },
    css: {
      type: String,
      default: "button-submit bs-btn",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#00a5ff",
    },
    loading: { type: Boolean, default: false },
  },
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style scoped lang="scss">
.button-submit {
  color: white;
  width: 90px;
  height: 40px;
  background-color: #00a5ff;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
}
.button-submit:hover {
  background-color: #0195e4 !important;
}
.button-submit b {
  color: #fff;
}
.bs-btn{
  font-size: 0.75em !important;
}
</style>

