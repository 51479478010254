var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("isc-modal-alert", {
    attrs: {
      title: _vm.titleNoAuth,
      paragraph: _vm.paragraph,
      icon: _vm.icon,
      iconColor: _vm.iconColor,
      showBtn: _vm.showBtn,
      showBtnClose: false,
      showMainIcon: _vm.showMainIcon,
    },
    on: { clicked: _vm.redirectToLogin },
    model: {
      value: _vm.isNoAuth,
      callback: function ($$v) {
        _vm.isNoAuth = $$v
      },
      expression: "isNoAuth",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }