<template>
  <div class="preview-question__qr">
    <label class="preview-question__label mb-3"
      >{{ item.question }} <span v-if="item.required">*</span></label
    >
    <v-text-field
      placeholder="MM/DD/AAAA"
      solo
      readonly
      dense
      class="preview-question__input"
      hide-details
      v-model="computedDateFormatted"
      @click="setDate()"
    ></v-text-field>
    <v-date-picker v-if="datepicker" v-model="date" no-title> </v-date-picker>
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsDate",
  data() {
    return {
      date: "",
      dateFormatted: "",
      datepicker: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    computedDateFormatted(strDate) {
      this.$emit(
        "set-date-item",
        this.item.id,
        this.item.idQuestionDb,
        this.item.question,
        strDate,
        this.item.required
      );
    },
  },
  methods: {
    setDate() {
      this.datepicker = !this.datepicker;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
  },
};
</script>

<style lang="scss">
.preview-question {
  .v-input__control{
    border: none;
    border-radius: 3px;
    min-height: 35px !important;
    .v-input__slot{
      box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62) !important;
      input{
        font-size: 12px;
        &::placeholder{
          color: #5d6b88;
        }
      }
    }
  }
}
</style>
