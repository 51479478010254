import { apollo } from "../../../../plugins/vue-apollo";
import { CREATE_LEAD } from "../graphql/mutation";
import Swal from "sweetalert2";
import { FIND_ADDON_BY_ID } from "../graphql/query";

export default {
  FIND_ADDON_BY_ID: async (_, { id }) => {
    return await apollo
      .query({
        query: FIND_ADDON_BY_ID,
        variables: { id },
      })
      .then(({ data: { addon } }) => {
        return addon;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  },
  // also create a Addon by user
  CREATE: async (_, { lead }) => {
    return await apollo
      .mutate({
        mutation: CREATE_LEAD,
        variables: { lead },
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  },

  /* UPDATE: async ( _ , { id, addon }) => {
   return await  apollo
      .mutate({
        mutation: UPDATE_ADDON,
        variables: { id, addon },
      })
      .then((data) => {
				return data; 
      })
      .catch((err) => {
				console.log(' err', err);
				Swal.fire({
          title: 'Oops...',
          text: 'Something went wrong!',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        })
			})
  }, */
  /* FIND_ADDON: async (_, { uuid }) => {
    return  await apollo
      .query({
        query: FIND_ADDON,
        variables: { uuid },
      })
      .then(({ data: { addon } }) => {
				console.log(' addon ', addon );
				return addon;
      })
      .catch((err) => {
        console.log(err);
				Swal.fire({
          title: 'Oops...',
          text: 'Something went wrong!',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }, */
};
