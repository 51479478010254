<template>
  <div class="preview-question">
    <preview-questions-checkbox
      v-if="question.type === 1"
      :item="question"
      @checkbox-items="itemsAnswersCheckboxes"
    />
    <preview-questions-radioButton
      v-if="question.type === 2"
      :item="question"
      @radio-button-item="itemAnswerRadioButton"
    />
    <preview-questions-range
      v-if="question.type === 3"
      :item="question"
      @range-item="itemAnswerRange"
    />
    <preview-questions-open-text
      v-if="question.type === 4"
      :item="question"
      @open-text="answerOpenText"
    />
    <preview-questions-qr-code v-if="question.type === 5" :item="question" />
    <preview-questions-iframe v-if="question.type === 6" :item="question" />
    <preview-questions-date
      v-if="question.type === 7"
      :item="question"
      @set-date-item="dateItem"
    />
  </div>
</template>

<script>
import PreviewQuestionsCheckbox from "./previewQuestionsComponents/PreviewQuestionsCheckbox.vue";
import PreviewQuestionsRadioButton from "./previewQuestionsComponents/PreviewQuestionsRadioButton.vue";
import PreviewQuestionsRange from "./previewQuestionsComponents/PreviewQuestionsRange.vue";
import PreviewQuestionsQrCode from "./previewQuestionsComponents/PreviewQuestionsQrCode.vue";
import PreviewQuestionsIframe from "./previewQuestionsComponents/PreviewQuestionsIframe.vue";
import PreviewQuestionsOpenText from "./previewQuestionsComponents/PreviewQuestionsOpenText.vue";
import PreviewQuestionsDate from "./previewQuestionsComponents/PreviewQuestionsDate.vue";
export default {
  name: "PreviewQuestions",
  components: {
    PreviewQuestionsCheckbox,
    PreviewQuestionsQrCode,
    PreviewQuestionsRadioButton,
    PreviewQuestionsRange,
    PreviewQuestionsIframe,
    PreviewQuestionsDate,
    PreviewQuestionsOpenText,
  },
  props: {
    question: {
      type: Object,
      default: null,
    },
  },
  computed: {
    questions() {
      return this.$store.getters["addons/create/questions"];
    },
  },
  methods: {
    answerOpenText(id, idQuestionDb, question, answer, required) {
      this.$emit("text-open", id, idQuestionDb, question, answer, required);
    },
    dateItem(id, idQuestionDb, question, strDate, required) {
      this.$emit("date-item", id, idQuestionDb, question, strDate, required);
    },
    itemAnswerRange(id, idQuestionDb, question, answer, required) {
      this.$emit(
        "range-item-answer",
        id,
        idQuestionDb,
        question,
        answer,
        required
      );
    },
    itemAnswerRadioButton(id, idQuestionDb, question, answer, required) {
      this.$emit(
        "radio-button-answer",
        id,
        idQuestionDb,
        question,
        answer,
        required
      );
    },
    itemsAnswersCheckboxes(id, idQuestionDb, question, answers, required) {
      this.$emit(
        "checkboxes-answers",
        id,
        idQuestionDb,
        question,
        answers,
        required
      );
    },
  },
};
</script>

<style lang="scss">
.preview-question {
  border: 1px solid #c5d0e4;
  margin-bottom: 10px;
  padding: 15px 20px;
  background-color: #fff;

  &__label {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: block;
    color: #2C3852;
  }

  &__input {
    width: 100%;
    background: #ffffff;
    border: 1px solid #c5d0e4;
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 2px;
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 10px 15px;
  }

  &__item-checkbox {
    display: none;

    &:checked + label {
      background-color: #00a5ff;
      border-color: #00a5ff;
      position: relative;
      box-shadow: none;
    }

    &:checked + label::after {
      top: -4.5px;
      left: 3px;
      position: absolute;
      content: "\2143";
      color: #fff;
      // content: '\F0132';
      // font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 13px;
      transform: rotate(45deg);
      font-weight: bold;
    }

    & + label {
      width: 16px;
      height: 16px;
      background-color: #fff;
      box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
      border: 1px solid #c5d0e4;
      cursor: pointer;
      margin-right: 12px;
      border-radius: 2px;
    }
  }

  &__item-radio {
    display: none;

    &:checked + label {
      background-color: #fff;
      position: relative;
      border-color: #c5d0e4;
    }

    &:checked + label::after {
      content: "";
      position: absolute;
      background-color: #00a5ff;
      height: 8px;
      width: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    & + label {
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid #c5d0e4;
      box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.6);
      cursor: pointer;
      margin-right: 12px;
      border-radius: 50%;
    }
  }

  &__item-label {
    font-family: "Oxygen";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2C3852;
  }
}

.list-style-none {
  list-style: none;
}
</style>
