export default {
  CHANGE_FILTER: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, filter }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          filter: {
            ...item.currentValues?.adjust,
            [filter.type]: filter.type === 'blur' ? filter.value / 100 : filter.value,
          },
          currentValues: {
            ...item.currentValues,
            filter: {
              type: filter.type,
              value: filter.value
            },
          }
        };
      } else {
        return item;
      }
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_ADJUST: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, filters }
  ) => {
    const images = drag.images.map((item) => {
      const adjustList = filters.reduce((obj, item) => (obj[item.filter] = item.percent / 100, obj), {});

      if (item.uuid === uuid) {
        return {
          ...item,
          filter: {
            ...item.filter,
            ...adjustList
          },
          currentValues: {
            ...item.currentValues,
            adjust: {
              ...adjustList
            },
          }
        };
      } else {
        return item;
      }
    });
    const image = drag.images.find(({ uuid }) => uuid == uuid)
    commit("post/image/SET_SELECTED_IMAGE_DETAIL", image, { root: true });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_FLIP: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, flip }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          flip,
        };
      } else {
        return item;
      }
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CROP: ({ commit }, isCrop) => {
    //commit('SET_IS_CROP_SAVED', false)
    commit("SET_IS_CROP", isCrop);
  },
  CROP_RESIZE: ({ commit }, selectedStylesCrop) => {
    commit("SET_CROP_RESIZE", selectedStylesCrop);
  },
  CROP_STYLES: ({ commit }, selectedStylesCrop) => {
    commit("SET_CROP_STYLES", selectedStylesCrop);
  },
  CROP_SAVED: ({ commit }, isCropSaved) => {
    commit("SET_IS_CROP_SAVED", isCropSaved);
  },
};
