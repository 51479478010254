export default {
  images: [],
  image: {},
  resize: {},
  rotate: null,
  isDragging: false,
  selectedStylesCut: null,
  selectedStylesResize: {
    width: 0,
    height: 0,
    transform: {
      translate: {
        x: 0,
        y: 0,
      },
      rotate: 0,
    },
  },
  hover: false,
  hoverStyles: {
    width: 0,
    height: 0,
    transform: {
      translate: {
        x: 0,
        y: 0,
      },
      rotate: 0,
    },
    uuid: null
  },
  texts: [],
  lastAttributesSelected:null,
  paragraphsList:[],
  selectedCrop: {
    positionBackground: {
      x: 0,
      y: 0,
    },
  },
  selectedText: null,
  imagesSeletedByBox: [],
  parrafosQuill: [],
  countClick: 0,
  quill: null,
  isActiveToTextEdit: false,
  showFontSizeText: false,
  quillGetSelection: null,
  quillGetLastSelection: null,
  quillGetNewSelection: null,
  isOpenFontMenu: false,
  typographySelectedList:[],
  fontsToEditor: [
    "Arial",
    "AndadaPro",
    "Arial",
    "Antonio",
    "Arapey",
    "Archivo",
    "Assistant",
    "Barlow",
    "Basic",
    "BeVietnam",
    "Bilbo",
    "BlackHanSans",
    "Blinker",
    "Boogaloo",
    "BowlbyOne",
    "BubblerOne",
    "Bungee",
    "BebasNeue",
    "Economica",
    "Baskerville",
    "SeaweedScript",
    "Roboto",
    "Phosphate",
    "AbhayaLibre",
    "Creepster",
    "KiloGram",
    "Langdon",
    "LT-Oksana",
    "LT-Oksana-bold",
    "LT-Oksana-italic",
    "LT-Oksana-bold-italic",
    "Monoton",
    "Pixelify-Sans",
    "Pixelify-Sans-medium",
    "Pixelify-Sans-bold",
    "Pixelify-Sans-semi-bold",
    "Reckoner",
    "ChunkFive",
    "Ostrich-Sans-Inline",
    "Ostrich-Sans-Inline-italic",
    "Ostrich-Sans-light",
    "Ostrich-Sans-medium",
    "Ostrich-Sans-Rounded-medium",
    "Ostrich-Sans-black",
    "Ostrich-Sans-bold",
    "Ostrich-Sans-heavy",
    "Ostrich-Sans-Dashed-medium",
    "Allison",
    "Architects-Daughter",
    "Cookie",
    "Dancing-Script",
    "Dancing-Script-bold",
    "Dancing-Script-semi-bold",
    "Dancing-Script-medium",
    "Dela-Gothic-One",
    "Lobster",
    "PlaywriteGBS",
    "PlaywriteGBS-italic",
    "PlaywriteGBS-light",
    "PlaywriteGBS-light-italic",
    "PlaywriteGBS-extra-light",
    "PlaywriteGBS-extra-light-italic",
    "PlaywriteGBS-thin",
    "PlaywriteGBS-thin-italic",
    "Arsenica-regular",
    "Arsenica-bold",
    "Arsenica-bold-italic",
    "Arsenica-demi-bold",
    "Arsenica-demi-bold-italic",
    "Arsenica-extra-bold",
    "Arsenica-extra-bold-italic",
    "Arsenica-light",
    "Arsenica-light-italic",
    "Arsenica-medium",
    "Arsenica-medium-italic",
    "Arsenica-thin",
    "Arsenica-thin-italic",
    "Bevan",
    "Bitter",
    "Bitter-bold",
    "Bitter-bold-italic",
    "Bitter-italic",
    "Bodoni-Moda-9pt",
    "Bodoni-Moda-9pt-bold",
    "Bodoni-Moda-9pt-bold-italic",
    "Bodoni-Moda-9pt-extra-bold",
    "Bodoni-Moda-9pt-black",
    "Bodoni-Moda-9pt-black-italic",
    "Bodoni-Moda-9pt-italic",
    "Bodoni-Moda-9pt-medium",
    "Bodoni-Moda-9pt-medium-italic",
    "Bodoni-Moda-9pt-semi-bold",
    "Bodoni-Moda-9pt-semi-bold-italic",
    "Bodoni-Moda-18pt-regular",
    "Bodoni-Moda-18pt-bold",
    "Bodoni-Moda-18pt-bold-italic",
    "Bodoni-Moda-18pt-extra-bold",
    "Bodoni-Moda-18pt-extra-bold-italic",
    "Bodoni-Moda-18pt-black",
    "Bodoni-Moda-18pt-black-italic",
    "Bodoni-Moda-18pt-semi-bold",
    "Bodoni-Moda-18pt-semi-bold-italic",
    "Bodoni-Moda-18pt-medium",
    "Bodoni-Moda-18pt-italic",
    "Bodoni-Moda-28pt-bold",
    "Bodoni-Moda-28pt-bold-italic",
    "Bodoni-Moda-28pt-black",
    "Bodoni-Moda-28pt-black-italic",
    "Bodoni-Moda-28pt-extra-bold",
    "Bodoni-Moda-28pt-extra-bold-italic",
    "DM-Serif-Text",
    "DM-Serif-Text-italic",
    "Droid-Serif",
    "Droid-Serif-bold",
    "Droid-Serif-bold-italic",
    "Droid-Serif-italic",
    "Gaspar",
    "Gaspar-bold",
    "Gaspar-bold-italic",
    "Gaspar-italic",
    "Gilda-Display",
    "Grandesign-Neue-Serif",
    "Grandesign-Neue-Serif-bold",
    "Grandesign-Neue-Serif-bold-italic",
    "Grandesign-Neue-Serif-italic",
    "Literata-18pt",
    "Literata-18pt-italic",
    "Literata-18pt-bold",
    "Literata-18pt-bold-italic",
    "Literata-18pt-black",
    "Literata-18pt-black-italic",
    "Literata-18pt-extra-bold",
    "Literata-18pt-extra-bold-italic",
    "Literata-18pt-light",
    "Literata-18pt-light-italic",
    "Literata-18pt-extra-light",
    "Literata-18pt-extra-light-italic",
    "Literata-18pt-medium",
    "Literata-18pt-semi-bold",
    "Literata-18pt-semi-bold-italic",
    "Literata-24pt",
    "Literata-24pt-italic",
    "Literata-24pt-bold",
    "Literata-24pt-bold-italic",
    "Literata-24pt-black",
    "Literata-24pt-black-italic",
    "Literata-24pt-extra-bold",
    "Literata-24pt-extra-bold-italic",
    "Literata-24pt-light",
    "Literata-24pt-light-italic",
    "Literata-24pt-extra-light",
    "Literata-24pt-extra-light-italic",
    "Literata-24pt-medium",
    "Lora",
    "Lora-italic",
    "Lora-bold",
    "Lora-bold-italic",
    "Lora-semi-bold",
    "Lora-semi-bold-italic",
    "Lora-medium",
    "Lora-medium-italic",
    "Mermaid",
    "Mermaid-Swash-Caps",
    "Playfair-Display",
    "Playfair-Display-italic",
    "Playfair-Display-bold",
    "Playfair-Display-bold-italic",
    "Playfair-Display-black",
    "Playfair-Display-black-italic",
    "Playfair-Display-extra-bold",
    "Playfair-Display-extra-bold-italic",
    "Playfair-Display-semi-bold",
    "Playfair-Display-semi-bold-italic",
    "Playfair-Display-SC",
    "Playfair-Display-SC-italic",
    "Playfair-Display-SC-bold",
    "Playfair-Display-SC-bold-italic",
    "Playfair-Display-SC-black",
    "Playfair-Display-SC-black-italic",
    "Ripe",
    "Ripe-bold",
    "Ripe-light",
    "Ripe-semi-bold",
    "RogerWhite",
    "Sailer-Trial",
    "Sailer-Trial-bold",
    "Sailer-Trial-SemBd",
    "Sailer-Trial-ExtBd",
    "Sailer-Trial-light",
    "Sailer-Trial-Medium",
    "Sailer-Trial-thin",
    "Souvenir2",
    "Souvenir",
    "Souvenir-italic",
    "Souvenir-light",
    "Souvenir-bold",
    "Souvenir-bold-italic",
    "Souvenir-DemiItalic",
    "Souvenir-Light-Bold-Wd",
    "Souvenir-Light-bold-italic",
    "Souvenir-SSi",
    "Souvenir-SSi-bold",
    "Souvenir-Light-SSi-light",
    "Souvenir-Black-SSi-bold",
    "Souvenir-Black-SSi-bold-italic",
    "ITC-Souvenir-Std",
    "ITC-Souvenir-Std-bold",
    "ITC-Souvenir-Std-light",
    "ITC-Souvenir-Std-medium",
    "Sreda",
    "Sunflower",
    "Unna",
    "Unna-italic",
    "Unna-bold",
    "Unna-bold-italic",
    "ADAM",
    "Aleo-regular",
    "Aleo-bold",
    "Aleo-light",
    "Alfa",
    "Anonymous-Pro",
    "Anonymous-bold",
    "Anson",
    "Archive2",
    "Archivo-black",
    "Azedo-light",
    "Azedo-bold",
    "Bariol",
    "Baskervville",
    "Baskervville-italic",
    "Beaver",
    "Big-John",
    "Slim-Joe",
    "Biko",
    "Biko-bold",
    "Biko-black",
    "Biko-light",
    "Blanch-Caps",
    "Blanch-Caps-Light",
    "Blanch-Caps-Inline",
    "Blanch-Condensed",
    "Blanch-Condensed-Light",
    "Blanch-Condensed-Inline",
    "BorisBlackBloxx",
    "BorisBlackBloxxDirty",
    "BPreplay",
    "BPreplay-italic",
    "BPreplay-bold",
    "BPreplay-bold-italic",
    "Bungee",
    "Cabin",
    "Cabin-italic",
    "Cabin-bold",
    "Cabin-bold-italic",
    "Cabin-medium",
    "Cabin-medium-italic",
    "Cabin-semi-bold",
    "Cabin-semi-bold-italic",
    "CartoGothic-book",
    "CartoGothic-italic",
    "CartoGothic-bold",
    "CartoGothic-bold-italic",
    "Champagne-Limousines",
    "Champagne-Limousines-italic",
    "Champagne-Limousines-bold",
    "Champagne-Limousines-bold-italic",
    "Coco-Gothic",
    "Coco-Gothic-italic",
    "Coco-Gothic-bold",
    "Coco-Gothic-bold-italic",
    "Coco-Gothic-Extra-Light",
    "Coco-Gothic-Extra-Light-italic",
    "Coco-Gothic-light",
    "Coco-Gothic-light-italic",
    "Coco-Gothic-Extra-black",
    "Coco-Gothic-extra-black-italic",
    "Coco-Gothic-heavy",
    "Coco-Gothic-heavy-italic",
    "Code-light",
    "Code-bold",
    "Comfortaa",
    "Comfortaa-light",
    "Comfortaa-bold",
    "Comic-Neue-1-1",
    "Comic-Neue-1-1-bold",
    "Comic-Neue-1-1-bold-oblique",
    "Comic-Neue-1-1-oblique",
    "Comic-Neue-1-1-light",
    "Comic-Neue-1-1-light-oblique",
    "Comic-Neue-1-1-Angular",
    "Comic-Neue-1-1-Angular-bold",
    "Comic-Neue-1-1-Angular-light",
    "Comic-Neue-1-1-Angular-oblique",
    "Comic-Neue-1-1-Angular-oblique-bold",
    "Comic-Neue-1-1-Angular-oblique-light",
    "Comic-Neue",
    "Comic-Neue-italic",
    "Comic-Neue-bold",
    "Comic-Neue-bold-italic",
    "Comic-Neue-light",
    "Comic-Neue-light-italic",
    "Coolvetica-Rg",
    "Coolvetica-Rg-italic",
    "Coolvetica-Condensed-Rg",
    "Coolvetica-Compressed-Hv",
    "Coolvetica-Crammed-Rg",
    "Creato-Display",
    "Creato-Display-italic",
    "Creato-Display-bold",
    "Creato-Display-bold-italic",
    "Creato-Display-extra-bold",
    "Creato-Display-extra-bold-italic",
    "Creato-Display-black",
    "Creato-Display-black-italic",
    "Creato-Display-medium",
    "Creato-Display-medium-italic",
    "Creato-Display-light",
    "Creato-Display-light-italic",
    "Creato-Display-thin",
    "Creato-Display-thin-italic",
    "Diavlo-Book",
    "Diavlo-bold",
    "Diavlo-light",
    "Diavlo-medium",
    "DM-Mono",
    "DM-Mono-italic",
    "DM-Mono-light",
    "DM-Mono-light-italic",
    "DM-Mono-medium",
    "DM-Mono-medium-italic",
    "Fredoka-One",
    "Frutiger-bold",
    "a_FuturaRound-bold",
    "a_FuturaOrtoSh-bold",
    "Futura-CondensedExtraBold-Normal",
    "Futura_Book-bold",
    "Futura-extra-bold",
    "a_FuturaOrto-bold",
    "Futura-ICG-XBold",
    "Futura-LH-Extra-bold",
    "Futura-URW-bold",
    "Geologica",
    "Geologica-bold",
    "Geologica-extra-bold",
    "Geologica-semi-bold",
    "Geologica-light",
    "Geologica-extra-light",
    "Geologica-thin",
    "Geologica-black",
    "Geologica-medium",
    "Geologica-Cursive",
    "Geologica-Cursive-bold",
    "Geologica-Cursive-semi-bold",
    "Geologica-Cursive-extra-bold",
    "Geologica-Cursive-light",
    "Geologica-Cursive-extra-light",
    "Geologica-Cursive-thin",
    "Geologica-Cursive-medium",
    "Geologica-Cursive-black",
    "Geologica-Roman",
    "Geologica-Roman-bold",
    "Geologica-Roman-extra-bold",
    "Geologica-Roman-semi-bold",
    "Geologica-Roman-light",
    "Geologica-Roman-extra-light",
    "Geologica-Roman-thin",
    "Geologica-Roman-black",
    "GeosansLight",
    "GeosansLight-oblique",
    "Glober-Thin",
    "Glober-SemiBold",
    "Gontserrat",
    "Gontserrat-italic",
    "Gontserrat-bold",
    "Gontserrat-bold-italic",
    "Gontserrat-black",
    "Gontserrat-black-italic",
    "Gontserrat-extra-bold",
    "Gontserrat-extra-bold-italic",
    "Gontserrat-light",
    "Gontserrat-light-italic",
    "Gontserrat-extra-light",
    "Gontserrat-extra-light-italic",
    "Gontserrat-medium",
    "Gontserrat-medium-italic",
    "Gontserrat-semi-bold",
    "Gontserrat-semi-bold-italic",
    "Harabara",
    "Helvetica-Inserat-Cyrillic",
    "Helvetica-Inserat-BQ",
    "Helvetica-Inserat-BQ-italic",
    "Helvetica-Inserat-roman",
    "Helvetica-Inserat-Roman-SemiB",
    "Helvetica-Inserat-LT-Std-roman",
    "Helvetica-Neue",
    "Helvetica-Neue-italic",
    "Helvetica-Neue-bold",
    "Helvetica-Neue-bold-italic",
    "Helvetica-Neue-condensed-bold",
    "Helvetica-Neue-condensed-black",
    "Helvetica-Neue-light",
    "Helvetica-Neue-light-italic",
    "Helvetica-Neue-ultra-light",
    "Helvetica-Neue-ultra-light-italic",
    "Helvetica-Neue-medium",
    "Herald",
    "HP-Simplified",
    "HP-Simplified-italic",
    "HP-Simplified-bold",
    "HP-Simplified-bold-italic",
    "HP-Simplified-light",
    "HP-Simplified-light-italic",
    "Inter-18pt",
    "Inter-18pt-italic",
    "Inter-18pt-bold",
    "Inter-18pt-bold-italic",
    "Inter-18pt-black",
    "Inter-18pt-black-italic",
    "Inter-18pt-extra-bold",
    "Inter-18pt-extra-bold-italic",
    "Inter-18pt-light",
    "Inter-18pt-light-italic",
    "Inter-18pt-extra-light",
    "Inter-18pt-extra-light-italic",
    "Inter-18pt-medium",
    "Inter-18pt-medium-italic",
    "Inter-18pt-semi-bold",
    "Inter-18pt-semi-bold-italic",
    "Inter-18pt-thin",
    "Inter-18pt-thin-italic",
    "Inter-24pt-italic",
    "Inter-24pt-bold",
    "Inter-24pt-bold-italic",
    "Inter-24pt-black",
    "Inter-24pt-black-italic",
    "Inter-24pt-extra-bold",
    "Inter-24pt-extra-bold-italic",
    "Inter-24pt-light",
    "Inter-24pt-light-italic",
    "Inter-24pt-extra-light",
    "Inter-24pt-extra-light-italic",
    "Inter-24pt-medium",
    "Jaapokki",
    "Jaapokki-enchance",
    "Jaapokki-subtract",
    "Kenyan-Coffee-Rg",
    "Kankin",
    "Kenyan-Coffee-Rg-italic",
    "Kenyan-Coffee-Rg-bold",
    "Kenyan-Coffee-Rg-bold-italic",
    "Kirvy",
    "Kirvy-bold",
    "Kirvy-light",
    "Kirvy-thin",
    "Lato",
    "Lato-italic",
    "Lato-bold",
    "Lato-bold-italic",
    "Lato-black",
    "Lato-black-italic",
    "Lato-light",
    "Lato-light-italic",
    "Lato-Hairline",
    "Lato-Hairline-italic",
    "Lato-20",
    "Lato-20-italic",
    "Lato-20-bold",
    "Lato-20-bold-italic",
    "Lato-20-black",
    "Lato-20-black-italic",
    "Lato-20-Hairline",
    "Lato-20-Hairline-italic",
    "Lato-20-heavy",
    "Lato-20-heavy-italic",
    "Lato-20-light",
    "Lato-20-light-italic",
    "Lato-20-medium",
    "Lato-20-medium-italic",
    "Lato-20-semi-bold",
    "Lato-20-semi-bold-italic",
    "Lato-20-thin",
    "Lato-20-thin-italic",
    "Molot",
    "Mukta-Malar",
    "Mukta-Malar-bold",
    "Mukta-Malar-extra-bold",
    "Mukta-Malar-light",
    "Mukta-Malar-extra-light",
    "Mukta-Malar-semi-bold",
    "Mukta-Malar-medium",
    "Mulish",
    "Mulish-italic",
    "Mulish-bold",
    "Mulish-bold-italic",
    "Mulish-black",
    "Mulish-black-italic",
    "Mulish-extra-bold",
    "Mulish-extra-bold-italic",
    "Mulish-semi-bold",
    "Mulish-semi-bold-italic",
    "Mulish-light",
    "Mulish-light-italic",
    "Mulish-extra-light",
    "Mulish-extra-light-italic",
    "Mulish-medium",
    "Mulish-medium-italic",
    "NanumGothic",
    "NanumGothic-bold",
    "NanumGothic-extra-bold",
    "Neris-black",
    "Neris-black-italic",
    "Neris-bold-italic",
    "Neris-semi-bold",
    "Neris-semi-bold-italic",
    "Neris-light",
    "Neris-light-italic",
    "Neris-thin",
    "Neris-thin-italic",
    "New-Amsterdam",
    "Nexa-light",
    "Nexa-bold",
    "Nolo-Contendre",
    "Nolo-Contendre-italic",
    "Nolo-Contendre-Condensed",
    "Nolo-Contendre-CondItal",
    "Nolo-Contendre-Expanded",
    "Nolo-Contendre-ExpItal",
    "Nolo-Contendre-Shadow",
    "Nolo-Contendre-Shadow-italic",
    "nevis-bold",
    "Norwester",
    "Helvetica-Neue-LTStd-bdcno-italic",
    "Helvetica-Neue-LTStd-bdexo-italic",
    "Helvetica-Neue-LTStd-bold",
    "Helvetica-Neue-LTStd-bd-bold",
    "Helvetica-Neue-LTStd-bdit-bold-italic",
    "Helvetica-Neue-LTStd-bdex-bold",
    "Helvetica-Neue-LTStd-blkexo-italic",
    "Helvetica-Neue-LTStd-blkex",
    "Helvetica-Neue-LTStd-blkcn",
    "Helvetica-Neue-LTStd-nbdou-bold",
    "Helvetica-Neue-LTStd-blk",
    "Helvetica-Neue-LTStd-blkcno",
    "Helvetica-Neue-LTStd-cn",
    "Helvetica-Neue-LTStd-ex",
    "Helvetica-Neue-LTStd-exo-italic",
    "Helvetica-Neue-LTStd-cno-italic",
    "Helvetica-Neue-LTStd-blkit-italic",
    "Helvetica-Neue-LTStd-hv",
    "Helvetica-Neue-LTStd-lt",
    "Helvetica-Neue-LTStd-ltcn",
    "Helvetica-Neue-LTStd-hvexo",
    "Helvetica-Neue-LTStd-hvcno",
    "Helvetica-Neue-LTStd-hvex",
    "Helvetica-Neue-LTStd-hvcn",
    "Helvetica-Neue-LTStd-hvit-italic",
    "Helvetica-Neue-LTStd-it-italic",
    "Helvetica-Neue-LTStd-ltexo-italic",
    "Helvetica-Neue-LTStd-ltcno-italic",
    "Helvetica-Neue-LTStd-ltit-italic",
    "Helvetica-Neue-LTStd-ltex",
    "Null-Free",
    "Nunito-Sans-7pt-bold",
    "Nunito-Sans-7pt-bold-italic",
    "Nunito-Sans-7pt-black",
    "Nunito-Sans-7pt-black-italic",
    "Nunito-Sans-7pt-Condensed",
    "Nunito-Sans-7pt-Condensed-italic",
    "Nunito-Sans-7pt-Condensed-bold",
    "Nunito-Sans-7pt-Condensed-bold-italic",
    "Nunito-Sans-7pt-Condensed-extra-bold",
    "Nunito-Sans-7pt-Condensed-extra-bold-italic",
    "Nunito-Sans-7pt-Condensed-light",
    "Nunito-Sans-7pt-Condensed-light-italic",
    "Nunito-Sans-7pt-Condensed-extra-light",
    "Nunito-Sans-7pt-Condensed-extra-light-italic",
    "Nunito-Sans-7pt-Condensed-medium",
    "Nunito-Sans-7pt-Condensed-medium-italic",
    "Nunito-Sans-7pt-Condensed-semi-bold",
    "Nunito-Sans-7pt-Condensed-semi-bold-italic",
    "Nunito-Sans-7pt-Expanded-bold",
    "Nunito-Sans-7pt-Expanded-bold-italic",
    "Nunito-Sans-7pt-Expanded-black",
    "Nunito-Sans-7pt-Expanded-black-italic",
    "Nunito-Sans-7pt-Expanded-extra-bold",
    "Nunito-Sans-7pt-Expanded-extra-bold-italic",
    "Nunito-Sans-7pt-Expanded-light",
    "Nunito-Sans-7pt-Expanded-extra-light",
    "Nunito-Sans-7pt-Expanded-extra-light-italic",
    "Open-Sans",
    "Open-Sans-italic",
    "Open-Sans-bold",
    "Open-Sans-bold-italic",
    "Open-Sans-semi-bold",
    "Open-Sans-semi-bold-italic",
    "Open-Sans-extra-bold",
    "Open-Sans-extra-bold-italic",
    "Open-Sans-light",
    "Open-Sans-light-italic",
    "Open-Sans-medium",
    "Open-Sans-medium-italic",
    "Open-Sans-Condensed",
    "Open-Sans-Condensed-italic",
    "Open-Sans-Condensed-bold",
    "Open-Sans-Condensed-bold-italic",
    "Open-Sans-Condensed-semi-bold",
    "Open-Sans-Condensed-semi-bold-italic",
    "Open-Sans-Condensed-extra-bold",
    "Open-Sans-Condensed-extra-bold-italic",
    "Open-Sans-Condensed-light",
    "Open-Sans-Condensed-light-italic",
    "Open-Sans-Condensed-medium",
    "Open-Sans-Condensed-medium-italic",
    "Open-Sans-SemiCondensed-italic",
    "Open-Sans-SemiCondensed-bold",
    "Open-Sans-SemiCondensed-bold-italic",
    "Open-Sans-SemiCondensed-extra-bold",
    "Open-Sans-SemiCondensed-extra-bold-italic",
    "Optien",
    "OSP-DIN",
    "Oswald",
    "Oswald-bold",
    "Oswald-semi-bold",
    "Oswald-light",
    "Oswald-extra-light",
    "Oswald-medium",
    "Oswald-Stencil-bold",
    "Oxanium",
    "Oxanium-bold",
    "Oxanium-semi-bold",
    "Oxanium-extra-bold",
    "Oxanium-light",
    "Oxanium-extra-light",
    "Oxanium-medium",
    "Oxygen",
    "Oxygen-bold",
    "Oxygen-light",
    "PaloAlto",
    "PaloAlto-italic",
    "Passion-One",
    "Passion-One-bold",
    "Passion-One-black",
    "Paytone-One",
    "PoetsenOne",
    "PoiretOne",
    "Poppins",
    "Poppins-italic",
    "Poppins-bold",
    "Poppins-bold-italic",
    "Poppins-black",
    "Poppins-black-italic",
    "Poppins-extra-bold",
    "Poppins-extra-bold-italic",
    "Poppins-semi-bold",
    "Poppins-semi-bold-italic",
    "Poppins-light",
    "Poppins-light-italic",
    "Poppins-extra-light",
    "Poppins-extra-light-italic",
    "Poppins-thin",
    "Poppins-thin-italic",
    "Poppins-medium",
    "Poppins-medium-italic",
    "Practique",
    "Prime",
    "Prime-light",
    "Prosto",
    "Quicksand",
    "Quicksand-bold",
    "Quicksand-light",
    "Raiders",
    "Raleway",
    "Raleway-italic",
    "Raleway-bold",
    "Raleway-bold-italic",
    "Raleway-black",
    "Raleway-black-italic",
    "Raleway-extra-bold",
    "Raleway-extra-bold-italic",
    "Raleway-semi-bold",
    "Raleway-semi-bold-italic",
    "Raleway-light",
    "Raleway-light-italic",
    "Raleway-extra-light",
    "Raleway-extra-light-italic",
    "Raleway-thin",
    "Raleway-thin-italic",
    "Raleway-medium",
    "Raleway-medium-italic",
    "RBNo2",
    "RBNo2-Light-Alternative",
    "Righteous",
    "Roboto",
    "Roboto-italic",
    "Roboto-bold",
    "Roboto-bold-italic",
    "Roboto-black",
    "Roboto-black-italic",
    "Roboto-extra-bold",
    "Roboto-extra-bold-italic",
    "Roboto-semi-bold",
    "Roboto-semi-bold-italic",
    "Roboto-light",
    "Roboto-light-italic",
    "Roboto-extra-light",
    "Roboto-extra-light-italic",
    "Roboto-thin",
    "Roboto-thin-italic",
    "Roboto-medium",
    "Roboto-medium-italic",
    "RobotoCondensed",
    "RobotoCondensed-italic",
    "RobotoCondensed-bold",
    "RobotoCondensed-bold-italic",
    "RobotoCondensed-black",
    "RobotoCondensed-black-italic",
    "RobotoCondensed-extra-bold",
    "RobotoCondensed-extra-bold-italic",
    "RobotoCondensed-semi-bold",
    "RobotoCondensed-semi-bold-italic",
    "RobotoCondensed-light",
    "RobotoCondensed-light-italic",
    "RobotoCondensed-extra-light",
    "RobotoCondensed-extra-light-italic",
    "RobotoCondensed-thin",
    "RobotoCondensed-thin-italic",
    "RobotoCondensed-medium",
    "RobotoCondensed-medium-italic",
    "RockoFLF",
    "RockoFLF-bold",
    "RockoUltraFLF",
    "RockoUltraFLF-bold",
    "Roddenberry",
    "Roddenberry-italic",
    "Rubik",
    "Rubik-italic",
    "Rubik-bold",
    "Rubik-bold-italic",
    "Rubik-black",
    "Rubik-black-italic",
    "Rubik-extra-bold",
    "Rubik-extra-bold-italic",
    "Rubik-semi-bold",
    "Rubik-semi-bold-italic",
    "Rubik-light",
    "Rubik-light-italic",
    "Rubik-medium",
    "Rubik-medium-italic",
    "SecularOne",
    "Signika",
    "Signika-bold",
    "Signika-semi-bold",
    "Signika-light",
    "Staatliches",
    "Straczynski",
    "Straczynski-italic",
    "Straczynski-bold",
    "Straczynski-bold-italic",
    "Swis721-bold",
    "Swis721-black",
    "Syncopate",
    "Syncopate-bold",
    "TangoSans",
    "TangoSans-italic",
    "TangoSans-bold",
    "TangoSans-bold-italic",
    "Titillium",
    "Titillium-italic",
    "Titillium-bold",
    "Titillium-bold-italic",
    "Titillium-black",
    "Titillium-semi-bold",
    "Titillium-semi-bold-italic",
    "Titillium-light",
    "Titillium-light-italic",
    "Titillium-thin",
    "Titillium-thin-italic",
    "Tobago-Poster",
    "Tobago-Poster-Alternate",
    "Tobago-Poster-Shadow",
    "Ubuntu",
    "Ubuntu-italic",
    "Ubuntu-bold",
    "Ubuntu-bold-italic",
    "Ubuntu-light",
    "Ubuntu-light-italic",
    "Ubuntu-medium",
    "Ubuntu-medium-italic",
    "Unbounded",
    "Unbounded-bold",
    "Unbounded-semi-bold",
    "Unbounded-extra-bold",
    "Unbounded-light",
    "Unbounded-extra-light",
    "Unbounded-medium",
    "WorldsAtWarBB",
    "WorldsAtWarBB-italic",
  ],
  fontSizeList:[
    '12px',
    '15px',
    '18px',
    '19px',
    '20px',
    '24px',
    '25px',
    '27px',
    '32px',
    '42px',
    '47px',
    '48px',
    '50px',
    '55px',
    '63px',
    '65px',
    '110px',
  ]
};
