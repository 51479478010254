<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M18.125 0.75H1.875C0.820312 0.75 0 1.60938 0 2.625V16.375C0 17.4297 0.820312 18.25 1.875 18.25H18.125C19.1406 18.25 20 17.4297 20 16.375V2.625C20 1.60938 19.1406 0.75 18.125 0.75ZM8.75 15.75H2.5V5.75H8.75V15.75ZM17.5 15.75H11.25V5.75H17.5V15.75Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IcoTemplates",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#96A0B8";
      },
    },
  },
};
</script>
