import {cloneDeep} from 'lodash';


export const undoRedoPlugin = (store) => {
    // initialize and save the starting stage
    store.subscribe((mutation, state) => {
        // is called AFTER every mutation
        
        if( mutation.type === 'post/drag/SET_IMAGES' || mutation.type === 'post/SET_CHANGES') {
            // console.log('undoRedoPlugin---',state.post.drag.images);
            // undoRedoHistory.addState(cloneDeep(state));
            store.commit('global/redoundo/HISTORY',cloneDeep(state.post.drag.images))
        } 

        if( mutation.type === 'addons/changes/SET_CHANGES') {
            store.commit('global/redoundoAddon/HISTORY',cloneDeep(state.addons))
        } 

        if( mutation.type === 'addons/editimage/SET_CHANGES') {
            store.commit('global/redoundoAddonImage/HISTORY',cloneDeep(state.addons))
        }

    });
}
