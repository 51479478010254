export default {
  SET_UPLOADS: (state, uploads) => {
    state.uploads = uploads.map((el) => {
      return el;
    });
  },
  SET_UPLOAD: (state, upload) => {
    state.upload = upload;
  },
  SET_UPLOADS_BY_KEYWORDS: (state, uploadsByKeywords) => {
    state.uploadsByKeywords = uploadsByKeywords;
  },
  SET_IMAGE_BYKEYWORD: (state, imagesByKeywords) => {
    state.find = imagesByKeywords;
  },
  SET_PROGRESS: (state, data) => {
    state.files.forEach((el, i) => {
      if (i === data.index) el.progress = data.progress;
    });
  },
  SET_FILES: (state, files) => {
    state.files = files.map((el, i) => ({
      id: i + 1,
      progress: 0,
      error: false,
      msgError: null
    }));
  },
  SET_ERROR: (state, data) => {
    state.files.forEach((el, i) => {
      if (el.id === (data.index + 1 )) {el.error = data.error, el.msgError = data.msgError};
    });
  },
  SET_POSTS: (state, folders) => {
    state.posts = folders;
  },
  //*RESET VARIABLES
  RESET_FILES_PROGRESS: (state, index) => {
    state.files.forEach((el, i) => {
      if (el.id === index + 1) {
        state.files.splice(i, 1);
      }
    });
  },
  RESET_UPLOADS: (state) => {
    state.uploads = [];
  },
  RESET: (state) => {
    state.read = {};
  },
};
