import moment from "moment";
import Swal from "sweetalert2";
export function generateKeywords(text) {
  const spaceType = " ";
  const dashType = "-";
  const downDash = "_";
  let isSpace = false;
  let isDash = false;
  let isDownDash = false;
  isSpace = text.includes(spaceType);
  isDash = text.includes(dashType);
  isDownDash = text.includes(downDash);

  text = text.split(".").slice(0, -1).join(".");

  if (isSpace && !isDash && !isDownDash) {
    return getKeywordsArray(spaceType, text);
  } else if (isDash && !isSpace && !isDownDash) {
    return getKeywordsArray(dashType, text);
  } else if (isDownDash && !isDash && !isSpace) {
    return getKeywordsArray(downDash, text);
  } else {
    return [text];
  }
}
export function getKeywordsArray(typeSplit, text) {
  const items = text.split(typeSplit);
  const result = [text];
  for (let i = 0; i < items.length; i++) {
    for (let j = 1; j <= items.length; j++) {
      const slice = items.slice(i, j);
      if (slice.length) result.push(slice.join(" "));
    }
  }
  return result;
}
/*
 *Function getting array of string
 *@param {String} dataString
 *@return {Array['String']} keywords
 */
export function getArrayString(dataString) {
  let strings = dataString.split(",");
  let keywords = strings.map((item) => item.trim());
  return keywords;
}
/*
 *Function Modify Thumbnails
 *@param {Array[]} thumbnails
 *@param {Object} full (retrieving for lambda image)
 *@return {Array[]} thumbnails (New thumbnails)
 */
export function transformThumbnails(thumbnails, full) {
  thumbnails.unshift({
    location: full.path,
    width: full.width,
    height: full.height,
    quality: "",
  });
  thumbnails.forEach((el, i) => {
    el.quality = `SCREEN_${i + 1}`;
  });
  return thumbnails;
}
/**
 **Function that changes domain
 * @param {String} path		Url from AWS 
 * @return {String}   		New path with another domain  
 */
 export function changeDomainImage(path) {
	const bucket = 'https://image-store.isocialcube.com/';
	const domain = (new URL(path));
	const getDoamin = domain.hostname;
	const getProtocol  = domain.protocol;
	const newPath = path.replace( getProtocol + '//' + getDoamin +'/', bucket);
	return newPath;
}
export function transformStringDate(date, format) {
  return moment(parseInt(date)).format(format);
}
export function messagePopUp(title, text, typeResult, time) {
  Swal.fire({
    title: title,
    text: text,
    icon: typeResult,
    showConfirmButton: false,
    timer: time,
  });
}
//** FUNCTIONS ADDONS EXCEL MODEL CREATION*/
/*
 *Function compare two Arrays if they both have same ids.
 *@param {Array[]} questions
 *@param {Array[]} leads
 *@return {Array[]} newLeads (New Leads)
 */
export function compareTwoArrays(questions, leads) {
  let newLeads = [];
  for (const [index, lead] of leads.entries()) {
    newLeads.push({
      id: lead.id,
      createdAt: lead.createdAt,
      data: [],
    });
    for (const question of questions) {
      for (const data of lead.data) {
        if (question.id == data.questionId) {
          newLeads[index].data.push(data);
        }
      }
    }
  }
  return newLeads;
}
/*
 *Function replicate missing objects from sampleQuestions(first index from newLeads).
 *@param {Array[]} sampleQuestions
 *@param {Array[]} newLeads
 *@return {Array[]} newLeads (Modify newLeads)
 */
export function replicateMissingObjects(sampleQuestions, newLeads) {
  for (const sampleQuestion of sampleQuestions) {
    for (const lead of newLeads) {
      const isExistId = lead.data.some((item) => {
        if (item.questionId == sampleQuestion.questionId) return true;
      });
      if (!isExistId) {
        lead.data.push({
          questionId: sampleQuestion.questionId,
          response: "",
        });
      }
    }
  }
  return newLeads;
}
export function validationUrl(text){
	let pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	if(!pattern.test(text) && text != '') {
		return {resp: false, text: "Please enter a valid URL." };
	} else{
		return {resp: true, text: "" };
	}
}
export function checkIfImageExists(url, callback) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };
    
    img.onerror = () => {
      callback(false);
    };
  }
}

export function rgbaToHex(rgba) {
  // valores de la variable RGBA
  //r: red, g: green, b: blue, a: alpha(opacidad) 
  const [r, g, b, a = 1] = rgba.match(/\d+(\.\d+)?/g).map(Number);

  // Convertir cada componente a hexadecimal
  const toHex = (num) => num.toString(16).padStart(2, '0');

  const red = toHex(r);
  const green = toHex(g);
  const blue = toHex(b);

  // Convertir el canal alfa (0-1) a 0-255 y luego a hex
  const alpha = toHex(Math.round(a * 255));

  // Retornar el color en formato #RRGGBB o #RRGGBBAA
  return a < 1 ? `#${red}${green}${blue}${alpha}` : `#${red}${green}${blue}`;
}
