export default {
  texts: (state) => state.texts,
  read: (state) => state.read,
  fontFamily: (state) => state.fontFamily,
  colorFont: (state) => state.colorFont,
  fontSize: (state) => state.fontSize,
  fontWeight: (state) => state.fontWeight,
  fontWeightD: (state) => state.fontWeightD,
  fontCursive: (state) => state.fontCursive,
  fontCursiveD: (state) => state.fontCursiveD,
  fontUnderline: (state) => state.fontUnderline,
  fontUnderlineD: (state) => state.fontUnderlineD,
  fontAlignC: (state) => state.fontAlignC,
  fontAlignL: (state) => state.fontAlignL,
  fontAlignR: (state) => state.fontAlignR,
  fontAlignCD: (state) => state.fontAlignCD,
  fontAlignLD: (state) => state.fontAlignLD,
  fontAlignRD: (state) => state.fontAlignRD,
};
