const getters = {
  canUndo: (state) => {
    return !(state.historyIndex >= 0)
  },
  canRedo: (state) => {
    return !(
      state.historyIndex < state.newHistory.length - 1 &&
      state.newHistory.length > 0
    )
  },
  history: (state) => state.history,
  historyIndex: (state) => state.historyIndex,
  newHistory: (state) => state.newHistory,
}

export default getters
