import gql from "graphql-tag";
import { COMMON_VAR, ADDON } from "./variables";

export const CLONE_ADDON = gql(`
  mutation cloneAddon($addon:CloneAddonDTO!){
    cloneAddon(addon:$addon){
      ${ADDON}
      ${COMMON_VAR}
    }
  }
`);

export const DELETE_ADDON = gql(`
  mutation deleteAddon($id:Int!){
    deleteAddon(id:$id)
  }
`);
