var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "horizontal-masonry horizontal-masonry--h" },
    _vm._l(_vm.items, function (item, i) {
      return _c(
        "element",
        {
          key: i,
          staticClass: "horizontal-masonry-brick horizontal-masonry-brick--h",
        },
        [
          _c("div", { staticClass: "progress" }, [
            item.progress < 100 && !item.error
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "padding-top": "57px",
                    },
                  },
                  [
                    _c("v-progress-linear", {
                      staticClass: "progress-linear",
                      attrs: {
                        color: _vm.linearColor,
                        "background-color": "#FFFFFF",
                      },
                      model: {
                        value: item.progress,
                        callback: function ($$v) {
                          _vm.$set(item, "progress", $$v)
                        },
                        expression: "item.progress",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "strong",
                      {
                        staticClass: "percentage",
                        style: { color: _vm.colorMessage },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(item.progress) +
                            "%\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            item.progress === 100
              ? _c("div", [
                  !item.error
                    ? _c(
                        "div",
                        { staticClass: "custom-linear" },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              indeterminate: "",
                              color: _vm.circularColor,
                              size: "17",
                              width: "3",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("cp-icon-error", {
                            attrs: { "color-message": _vm.colorMessage },
                          }),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "message",
                      style: { color: _vm.colorMessage },
                    },
                    [
                      !item.error
                        ? _c("p", { staticClass: "message_text" }, [
                            _vm._v(
                              " \n\t\t\t\t\t\t\tGenerating thumbnails ... \n\t\t\t\t\t\t"
                            ),
                          ])
                        : _c("p", { staticClass: "message_text" }, [
                            _vm._v(
                              " \n\t\t\t\t\t\t\t" +
                                _vm._s(item.msgError) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            item.progress < 100 && item.error
              ? _c(
                  "div",
                  [
                    _c("cp-icon-error", {
                      attrs: { "color-message": _vm.colorMessage },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "message",
                        style: { color: _vm.colorMessage },
                      },
                      [
                        _c("p", { staticClass: "message_text" }, [
                          _vm._v(
                            " \n\t\t\t\t\t\t\t" +
                              _vm._s(item.msgError) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }