import { apollo } from '../../../../plugins/vue-apollo'
import {
  CREATE_ADDON,
  CREATE_COMPONENT_METRIC,
  UPDATE_ADDON,
} from '../graphql/mutation'
import Swal from 'sweetalert2'
import { FIND_ADDON } from '../graphql/query'

export default {
  FIND_ADDON: async (_, { uuid }) => {
    return await apollo
      .query({
        query: FIND_ADDON,
        variables: { uuid },
      })
      .then(({ data: { addon } }) => {
        return addon
      })
      .catch((err) => {
        console.log(err)
        Swal.fire({
          title: 'Oops...',
          text: 'Something went wrong!',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        })
      })
  },
  // also create a Addon by user
  CREATE: async ({ commit }, { addon, setIdAddon , errorFunc}) => {
    await apollo
      .mutate({
        mutation: CREATE_ADDON,
        variables: { addon },
      })
      .then(({ data: { addon } }) => {
        if (setIdAddon) {
          commit('addons/create/SET_ID', addon.id, { root: true })
          commit('addons/create/SET_UUID', addon.uuid, { root: true })
        }
        commit(
          'global/saved/SET_LOADING',
          { load: false, status: true },
          { root: true },
        )
      })
      .catch((err) => {
        console.log("[Error] in action Create (Addon), ", err.message);
        commit(
          'global/saved/SET_LOADING',
          { load: false, status: false },
          { root: true },
        )
        return errorFunc(err.message)
      })
  },

  UPDATE: async ({ commit }, { id, addon, errorFunc }) => {
    await apollo
      .mutate({
        mutation: UPDATE_ADDON,
        variables: { id, addon },
      })
      .then(() => {
        commit(
          'global/saved/SET_LOADING',
          { load: false, status: true },
          { root: true },
        )
      })
      .catch((err) => {
        console.log('[Error] in Graphql UPDATE ', err)
        commit(
          'global/saved/SET_LOADING',
          { load: false, status: false },
          { root: true },
        )
        return errorFunc(err.message)
      })
  },
  UPDATE_NAME: (_, { id, addon, responseFunc }) => {
    apollo
      .mutate({
        mutation: UPDATE_ADDON,
        variables: { id, addon },
      })
      .then(() => {
        const msgResp =  "Addon's name updated successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log('[Error] in function UPDATE_NAME (Addon)', error)
        const msgResp = error?.message  ?? "Server fails to update a addon's name."; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      })
  },

  CREATE_ADDON_METRICS: async (_, { uuid, ref }) => {
    return await apollo
      .mutate({
        mutation: CREATE_COMPONENT_METRIC,
        variables: { componentMetric: { addonUuid: uuid, metricRef: ref } },
      })
      .catch((err) => {
        console.log(err)
      })
  },
  IS_HIDDEN_BUTTON: ({ commit }, value) => {
    commit("SET_HIDDEN_BUTTON_SAVE", value);
  },
}
