import { v4 as uuidv4 } from "uuid";

export default {
  CLONE_ITEM: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid }
  ) => {
    const images = drag.images;
    const image = images.find((i) => i.uuid === uuid);
    const newUUID = uuidv4();
    const newImage = {
      ...image,
      x: image.x + 20,
      y: image.y + 20,
      uuid: newUUID,
    };
    images.push(newImage);

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    commit(
      "post/drag/SET_STYLES_SELECTED",
      {
        transform: `translate(${newImage.x}px, ${newImage.y}px) rotate(${newImage.rotate}deg)`,
        height: `${newImage.height}px`,
        width: `${newImage.width}px`,
      },
      { root: true }
    );
    commit("post/image/SET_SELECTED_IMAGE", newUUID, { root: true });
  },
  MOVE_ITEM: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index }
  ) => {
    const images = drag.images;
    const itemSelected = images.find((i) => i.uuid === uuid);
    const copySelected=JSON.parse(JSON.stringify(itemSelected))
    const key = images.findIndex((i) => i.uuid === uuid);
    switch (index) {
      case "send-forward":
        if (key > 0) {
          const temp = copySelected;
          images[key] = images[key - 1];
          images[key - 1] = temp;
        }
        break;
      case "bring-forward":
        if (key < images.length - 1) {
          const temp = copySelected;
          images[key] = images[key + 1];
          images[key + 1] = temp;
        }
        break;
      case "bring-to-front":
        if (key < images.length - 1) {
          images.push(copySelected);
          images.splice(key, 1);
        }
        break;
      case "send-to-back":
        if (key > 0) {
          images.unshift(copySelected);
          images.splice(key + 1, 1);
        }
        break;
    }
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    // commit("post/drag/REMOVE_STYLES_SELECTED", null, { root: true });
  },
  DELETE_ITEM: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid }
  ) => {
    const images = drag.images.filter((i) => i.uuid !== uuid);

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    commit("post/image/SET_SELECTED_IMAGE", null, { root: true });
    // commit("post/drag/REMOVE_STYLES_SELECTED", null, { root: true });
  },
  BLOCK_ITEM: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid }
  ) => {
    const images = drag.images;
    const itemSelected = images.find((i) => i.uuid === uuid);
    itemSelected.blocked = !itemSelected.blocked ? true : false;

    commit("SET_IS_BLOCKED", itemSelected.blocked);
    const selectedImage = itemSelected.blocked ? null : uuid;

    const stylesSelected = itemSelected.blocked
      ? null
      : {
          transform: `translate(${itemSelected.x}px, ${itemSelected.y}px) rotate(${itemSelected.rotate}deg)`,
          height: `${itemSelected.height}px`,
          width: `${itemSelected.width}px`,
        };

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    commit("post/image/SET_SELECTED_IMAGE", selectedImage, { root: true });
    commit("post/drag/SET_STYLES_SELECTED", stylesSelected, { root: true });
    if (itemSelected.blocked)
      commit("post/drag/REMOVE_STYLES_SELECTED", null, { root: true });
  },
};
