import gql from "graphql-tag";
import { POST } from "./variables";

export const CREATE_POST = gql(`
  mutation create($post: PostDTO!){
    template: createPost(post: $post) {
      ${POST}
    }
  }
`);
/* 
export const UPDATE_TNC = gql(`
  mutation update($id: Int!,$data: TermAndConditionDTO!){
    updateTNC(id: $id, data: $data) {
      ${TNC}
      assignment {
        ${ASSIGNMENT}
      }
    }
  }
`);

export const DELETE_TNC = gql(`
  mutation delete($id: Int!) {
    deleteTNC(id: $id) 
  }
`); */
