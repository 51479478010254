import templates from "./templates/index";
import images from "./images/index";
import background from "./backgrounds/index";
import shapes from "./shapes/index";
import texts from "./texts/index";
import uploads from "./uploads/index";
import rect from "./rect/index";
import canvas from "./canvas/index";
import drag from "./drag/index";
import image from "./image/index";
import zoom from "./zoom/index";
import saved from "./saved/index";
import navControls from "./navControls/index";
import sideControls from "./sideControls/index";
import textControls from "./textControls/index";
import folder from "./folders/index";
import shapeControls from "./shapeControls/index";
import guideLines from "./guideLines/index";
import main from "./main/index";

export default {
  modules: {
    templates,
    images,
    background,
    shapes,
    texts,
    uploads,
    rect,
    canvas,
    drag,
    image,
    zoom,
    saved,
    navControls,
    sideControls,
    textControls,
    shapeControls,
    guideLines,
    folder,
    main,
  },
  state: {
    menuActive: "Templates",
  },
  mutations: {
    SET_MENU_ACTIVE: (state, menuActive) => {
      state.menuActive = menuActive;
    },
    SET_CHANGES: () => {
    },
    ADD_HISTORY:(images)=>{
      console.log(images)
    }
  },
  actions: {
    GET_MENU_ACTIVE: ({ commit }, { name }) => {
      commit("SET_MENU_ACTIVE", name);
    },
    SAVE_CHANGES: ({ commit }) => {
      // Se usa para guardar en REDO UNDO
      commit("SET_CHANGES");
    },
  },
  getters: {
    menuActive: (state) => state.menuActive,
  },
  namespaced: true,
};
