import { apollo } from "../../../../plugins/vue-apollo";
//import { CREATE_FOLDER } from "../graphql/mutations";
import {
  FIND_ADDONS_BY_FOLDER,
  FIND_ADDONS_BY_FOLDER_PAGINATE_ORDER,
  FIND_BY_KEYWORDS,
  FIND_FOLDERS_BY_TYPE,
} from "../graphql/query";
import Swal from "sweetalert2";
import { CLONE_ADDON, DELETE_ADDON } from "../graphql/mutations";
import { GET_FOLDER } from "../../../post/folders/graphql/query";

export default {
  //** LIST FOLDERS  ADDONS ADMIN
  FIND_ADDONS_BY_TYPE: ({ commit, dispatch }, { type, take, page }) => {
    apollo
      .query({
        query: FIND_FOLDERS_BY_TYPE,
        variables: { type, take, page },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { items } }) => {
        switch (type) {
          case "contest":
            commit("SET_FOLDERS_BY_CONTEST", items);
            break;
          case "survey":
            commit("SET_FOLDERS_BY_SURVEY", items);
            break;
          case "promo":
            commit("SET_FOLDERS_BY_PROMO", items);
            break;
          case "audience":
            commit("SET_FOLDERS_BY_AUDIENCE", items);
            break;
        }
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  FIND_ADDONS_BY_KEYWORDS: ({ commit, dispatch }, { take , page, keywords }) => {
    apollo
      .query({
        query: FIND_BY_KEYWORDS,
        variables: { take, page, keywords },
      })
      .then(({ data: { items } }) => {
        commit("FIND_ADDONS_BY_KEYWORDS", items);
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  FIND_ADDONS_BY_FOLDER: ({ commit, dispatch }, { folderId }) => {
    apollo
      .query({
        query: FIND_ADDONS_BY_FOLDER,
        variables: { folderId },
      })
      .then(({ data: { items } }) => {
        commit("SET_ADDONS_BY_FOLDER", items);
      })
      .catch((err) => {
        console.log(err);
        dispatch("snackbar/errors", err, { root: true });
      });
  },
//* individual folder 
  FIND_ADDONS_BY_FOLDER_PAGINATE_ORDER: (
    { commit, dispatch, getters },
    { folderId, take, page, order }
  ) => {
    commit('SET_IS_LOADING', true)
    if(page == 1) commit("SET_ADDONS_BY_FOLDER", []);
    
    apollo
      .query({
        query: FIND_ADDONS_BY_FOLDER_PAGINATE_ORDER,
        variables: { folderId, take, page, order },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { items } }) => {
        commit("SET_MORE_ADDON", items.length == take);
        if (page > 1) {
          commit("SET_ADDONS_BY_FOLDER", getters.addonsByFolder.concat(items));
        } else {
          commit("SET_ADDONS_BY_FOLDER", items);
        }

        commit('SET_IS_LOADING', false)
      })
      .catch((err) => {
        console.log(err);
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  CLONE_ADDON: async  ({ commit }, { addon, responseFunc  }) => {
    await apollo
      .mutate({
        mutation: CLONE_ADDON,
        variables: { addon },
        fetchPolicy: "no-cache",
      })
      .then(({ data: { cloneAddon } }) => {
        commit("ADD_CLONE_ADDON_FOLDER", cloneAddon);
        const msgResp =  "Addon cloned successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_ADDON", error);
        const msgResp = error.message ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  DELETE_ADDON: async ({ dispatch }, { id, responseFunc }) => {
    await apollo
      .mutate({
        mutation: DELETE_ADDON,
        variables: { id },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        dispatch("IS_DELETE_ADDON", true);
        const msgResp =  "Addon deleted successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl DELETE_ADDON", error);
        dispatch("IS_DELETE_ADDON", false);
        const msgResp = error.message ; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
  // individual folder 
  GET_FOLDER: async ({ commit }, id) => {
    apollo
      .query({
        query: GET_FOLDER,
        variables: { id },
      })
      .then(({ data: { getFolder } }) => {
        commit("SET__FOLDER_VIEW", getFolder);
      })
      .catch((err) => {
        console.log(err);
        // dispatch('snackbar/errors', err, { root: true })
      });
  },
  RESET_ARRAY_CONTEST: ({ commit }) => {
    commit("RESET_CONTEST");
  },
  RESET_ARRAY_SURVEY: ({ commit }) => {
    commit("RESET_SURVEY");
  },
  RESET_ARRAY_PROMO: ({ commit }) => {
    commit("RESET_PROMO");
  },
  RESET_ARRAY_AUDIENCE: ({ commit }) => {
    commit("RESET_AUDIENCE");
  },
  RESET_KEYWORDS: ({ commit }) => {
    commit("RESET_ADDONS_KEYWORDS");
  },
  IS_DELETE_ADDON: ({commit}, isDelete) => {
    commit("SET_IS_DELETE_ADDON", isDelete);
  },
  REMOVE_ADDON_FROM_FOLDER: ({ commit }, id) => {
    commit("DELETE_ADDON_FOLDER", id);
  }

};
