<template>
  <div class="preview-question__checkbox">
    <label class="preview-question__label"
      >{{ item.question }} <span v-if="item.required">*</span></label
    >

    <ul class="pa-0">
      <li
        v-for="answer in item.answers"
        :key="answer.id"
        class="list-style-none d-flex align-center mt-3"
      >
        <input
          type="radio"
          class="preview-question__item-radio mr-2"
          :name="'rdb-' + item.id"
          :id="'rdb-' + item.id + '-' + answer.id"
        />
        <label
          :for="'rdb-' + item.id + '-' + answer.id"
          @click="
            setAnswerRadioButtons(
              item.id,
              item.idQuestionDb,
              item.question,
              answer.text,
              item.required
            )
          "
        >
        </label>
        <span class="preview-question__item-label">{{ answer.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsRadioButton",
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  methods: {
    setAnswerRadioButtons(id, idQuestionDb, question, answer, required) {
      this.$emit(
        "radio-button-item",
        id,
        idQuestionDb,
        question,
        answer,
        required
      );
    },
  },
};
</script>

<style lang="scss"></style>
