<template>
  <div class="w-100 bg-primary position-fixed bg-resize-modal vw-100 vh-100 top-0 left-0 bottom-0 right-0 d-flex align-items-center justify-content-center"
    :style="{ 'z-index': 9999 }"
  >
    <div class="center-modal bg-white d-flex flex-column text-center" style="background-color: white;">
      <div class="img-st d-flex justify-center">
        <img class="img-loader" src="https://listen-images.s3.us-east-2.amazonaws.com/isotipo_isc_140x164.svg" alt="">
      </div>
      <div class="d-flex flex-column align-items-center justify-center">
        <div class="text-center">
          <span class="text-secondary">
            For a better experience, we suggest to display this website on a screen bigger than
          </span>
        </div>
        <div class="text-center">
          <span class="text-secondary font-weight-bold">
            1024px (width)
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: "ScreensizeModal",
    props: {},
    data: () => ({}),
    mounted(){},
    methods: {}
  };
</script>

<style scoped>
  .bg-resize-modal .center-modal{
    width: 100%;
    max-width: 560px;
    height: 230px;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    border-radius: 6px;
    font-size: 16px !important;
  }
  .bg-resize-modal .center-modal .img-st{
    width: 100%;
    background-color: rgba(0, 165, 255, .5);
    padding: 10px;
  }
  .bg-resize-modal .center-modal .img-st img{
    width: 30px;
    height: auto;
  }
  .bg-resize-modal .center-modal .img-st + div{
    flex-grow: 1;
    width: 80%;
    margin: 0 auto;
  }
  .bg-resize-modal .center-modal .img-st + div span{
    font-size: 1.125em;
    line-height: 1.75em;
  }

@media (max-width: 768px){
    .bg-resize-modal .center-modal{
        font-size: 14px !important;
    }
}
</style>
