var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-question__qr" },
    [
      _c("label", { staticClass: "preview-question__label mb-3" }, [
        _vm._v(_vm._s(_vm.item.question)),
      ]),
      _vm._v(" "),
      _vm._l(_vm.item.iFrames, function (iframe) {
        return _c(
          "div",
          { key: iframe.id, staticStyle: { "text-align": "center" } },
          [
            _c("span", {
              staticClass: "mr-2",
              domProps: { innerHTML: _vm._s(iframe.link) },
            }),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }