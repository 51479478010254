import gql from "graphql-tag";
import { QUESTION, LEAD } from "./variables";
import {
  COMMON_VAR,
  ADDON,
  FOLDER,
} from "../../../addons/folders/graphql/variables";

export const FIND_LATEST_ADDONS = gql(`
  query latestAddons($take: Int!, $typeUser: String, $clientId: Int!) {
    latestAddons: latestAddons(take: $take, typeUser: $typeUser, clientId: $clientId) {
			${ADDON}
			${COMMON_VAR}
			questions {
				${QUESTION}
			}
    }
  }
`);
export const FIND_FOLDERS_WITH_ADDONS = gql(`
  query listFoldersWithAddons($limitAddon:Int!,$page:Int!,$take:Int!, $clientId:Int!){
    folders:listFoldersWithAddons(page:$page, take:$take, clientId: $clientId ){
      count
      data{
        ${FOLDER}
        items:addons(limitAddon: $limitAddon){
          ${ADDON}
          ${COMMON_VAR}
					questions {
						${QUESTION}
					}
        }
      }

    }
  }
`);
export const FIND_ADDONS_BY_FOLDER_ID = gql(`
  query listAddonsByFolderPaginate($page: Int!, $take: Int!, $folderId: Int!, $clientId:Int!) {
    folders: listAddonsByFolderPaginate(page:$page, take: $take, folderId: $folderId, clientId:$clientId) {
			${ADDON}
			${COMMON_VAR}
    }
  }
`);

export const LIST_LEADS_BY_ADDON = gql(`
  query listLeads($addonId:Int!){
    listLeads(addonId:$addonId){
			${LEAD}		
    }
  }
`);
