<template>
  <div class="preview-question__qr">
    <label class="preview-question__label mb-3">{{ item.question }}</label>
    <div
      style="text-align: center"
      v-for="iframe in item.iFrames"
      :key="iframe.id"
    >
      <span v-html="iframe.link" class="mr-2"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsIframe",
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  // computed: {
  //   ranges() {
  //     return [...Array(this.item.highrange).keys()].map(i => i + this.item.lowrange);
  //   }
  // }
};
</script>

<style lang="scss">
.preview-question {
}
</style>
