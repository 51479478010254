var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "15",
        height: "16",
        viewBox: "0 0 15 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M13.5938 0.875H4.21875C3.42773 0.875 2.8125 1.51953 2.8125 2.28125V3.6875H1.40625C0.615234 3.6875 0 4.33203 0 5.09375V14.4688C0 15.2598 0.615234 15.875 1.40625 15.875H10.7812C11.543 15.875 12.1875 15.2598 12.1875 14.4688V13.0625H13.5938C14.3555 13.0625 15 12.4473 15 11.6562V2.28125C15 1.51953 14.3555 0.875 13.5938 0.875ZM10.6055 14.4688H1.58203C1.46484 14.4688 1.40625 14.4102 1.40625 14.293V5.26953C1.40625 5.18164 1.46484 5.09375 1.58203 5.09375H2.8125V11.6562C2.8125 12.4473 3.42773 13.0625 4.21875 13.0625H10.7812V14.293C10.7812 14.4102 10.6934 14.4688 10.6055 14.4688ZM13.418 11.6562H4.39453C4.27734 11.6562 4.21875 11.5977 4.21875 11.4805V2.45703C4.21875 2.36914 4.27734 2.28125 4.39453 2.28125H13.418C13.5059 2.28125 13.5938 2.36914 13.5938 2.45703V11.4805C13.5938 11.5977 13.5059 11.6562 13.418 11.6562Z",
          fill: _vm.fill,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }