<template>
  <div class="preview-question__qr">
    <label class="preview-question__label">{{ item.question }}</label>
    <img :src="item.qr.codeQr" class="mx-auto d-block" />
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsQrCode",
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  // computed: {
  //   ranges() {
  //     return [...Array(this.item.highrange).keys()].map(i => i + this.item.lowrange);
  //   }
  // }
};
</script>

<style lang="scss">
.preview-question {
  // &__list-range {
  //   margin-top: 20px;
  //   display: flex;
  //   justify-content: space-around;
  //   flex-wrap: wrap;
  //   margin-bottom: 20px;

  // }
  // &__item-range {
  //   display: none;

  //   &:checked + label {
  //     background-color: #00a5ff;
  //     color: #fff;
  //   }

  //   & + label {
  //     height: 32px;
  //     width: 32px;
  //     background-color: #F3F4F7;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin: 0 2.5px;
  //     border-radius: 3px;
  //     margin-bottom: 5px;
  //     font-family: 'Oxygen';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 12px;
  //     line-height: 16px;
  //     color: #5D6B88;
  //     cursor: pointer;
  //   }

  //   &--low, &--high {
  //     font-family: 'Oxygen';
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 10px;
  //     line-height: 16px;
  //     color: #5D6B88;
  //     display: block;
  //     bottom: -20px;
  //     position: absolute;
  //     width: 100px;
  //   }

  //   &--low {
  //     left: 0;
  //   }

  //   &--high {
  //     right: 0;
  //     text-align: right;
  //   }
  // }
}
</style>
