import gql from "graphql-tag";
import { POST } from "./variables";

export const CREATE_POST = gql(`
  mutation create($post: PostDTO!){
    saved: createPost(post: $post) {
      ${POST}
    }
  }
`);

export const UPDATE_POST = gql(`
  mutation update($id: Int!,$post: PostDTO!){
    updatePost(id: $id, post: $post) {
      ${POST}
    }
  }
`);
