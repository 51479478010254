import { cloneDeep } from 'lodash'

import { apollo } from '../../../../plugins/vue-apollo'
import {
  FIND_BACKGROUNDS,
  FIND_BACKGROUNDS_WITH_KEYWORDS,
} from '../graphql/query'
import { messagePopUp } from '../../../../utils/utils'

export default {
  FIND: ({ commit }, { take, page }) => {
    apollo
      .query({
        query: FIND_BACKGROUNDS,
        variables: { take, page },
      })
      .then(({ data: { backgrounds } }) => {
        commit('SET_BACKGROUNDS', backgrounds)
      })
      .catch((err) => {
        console.error(err)
        messagePopUp('Oops...', 'Something went wrong!', 'error', 1500)
      })
  },
  FIND_WITH_KEYWORDS: ({ commit }, { keywords }) => {
    apollo
      .query({
        query: FIND_BACKGROUNDS_WITH_KEYWORDS,
        variables: { keywords },
      })
      .then(({ data: { backgroundsByKeywords } }) => {
        commit('SET_BACKGROUNDS_BY_KEYWORD', backgroundsByKeywords)
      })
      .catch((err) => {
        messagePopUp('Oops...', 'Something went wrong!', 'error', 1500)
      })
  },
  ADD_BACKGROUND_IN_CANVAS: (
    { commit, rootState },
    { background, type, path },
  ) => {
    console.log(rootState)
    if (type == 'backgroundI') background = `url( ${path} ) center / cover`

    commit('SET_LIENZO_BACKGROUND', background)
    commit('SET_TYPE_BACKGROUND', type)
    commit('post/SET_CHANGES', null, { root: true })
    commit(
      'global/redoundo/ADD_HISTORY',
      cloneDeep({
        images: rootState.post.drag.images,
        background,
        type,
      }),
      { root: true },
    )
  },
}
