<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M16.875 15.75H15.9375L10.8203 1.60938C10.6641 1.14062 10.1953 0.75 9.64844 0.75H7.8125C7.26562 0.75 6.79688 1.14062 6.64062 1.60938L1.52344 15.75H0.625C0.273438 15.75 0 16.0625 0 16.375V17.625C0 17.9766 0.273438 18.25 0.625 18.25H5.625C5.9375 18.25 6.25 17.9766 6.25 17.625V16.375C6.25 16.0625 5.9375 15.75 5.625 15.75H4.84375L5.74219 13.25H11.7188L12.6172 15.75H11.875C11.5234 15.75 11.25 16.0625 11.25 16.375V17.625C11.25 17.9766 11.5234 18.25 11.875 18.25H16.875C17.1875 18.25 17.5 17.9766 17.5 17.625V16.375C17.5 16.0625 17.1875 15.75 16.875 15.75ZM6.875 10.125L8.75 5.08594L10.5859 10.125H6.875Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IcoFont",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#96A0B8";
      },
    },
  },
};
</script>
