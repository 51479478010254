import { apollo } from "../../../../plugins/vue-apollo";
import { CREATE_POST } from "../graphql/mutation";
import {
  FIND_TEMPLATES,
  FIND_TEMPLATES_WITH_KEYWORDS,
  FIND_POSTS_BY_TYPE,
} from "../graphql/query";
import Store from "../../../../store/index";

export default {
  FIND: async ({ commit }, { take, page }) => {
    commit('SET_IS_LOADING', true)
    await apollo
      .query({
        query: FIND_TEMPLATES,
        variables: { take, page },
      })
      .then(({ data: { templates } }) => {
        commit("SET_TEMPLATES", templates);
        commit('SET_IS_LOADING', false)
      })
      .catch((error) => {
        console.error("[Error] in function FIND[Templates]", error.message);
        Store.dispatch("global/util/setRespAlert", {type: "error", message: error.message, showModal: true});
      });
  },
  FIND_WITH_KEYWORDS: ({ commit }, { keywords }) => {
    apollo
      .query({
        query: FIND_TEMPLATES_WITH_KEYWORDS,
        variables: { names: keywords },
      })
      .then(({ data: { templatesByKeywords } }) => {
        commit("SET_TEMPLATES_BY_KEYWORD", templatesByKeywords);
      })
      .catch((error) => {
        console.error(error);
        Store.dispatch("global/util/setRespAlert", {type: "error", message: error.message, showModal: true});
      });
  },
  // also create a template
  CREATE_POST: ({ commit, dispatch }, { data, take, page }) => {
    apollo
      .mutate({
        mutation: CREATE_POST,
        variables: { data },
        update: (cache, { data: { template } }) => {
          const data = cache.readQuery({
            query: FIND_TEMPLATES,
            variables: { take, page },
          });
          data.templates.unshift(template);

          cache.writeQuery({
            query: FIND_TEMPLATES,
            variables: { take, page },
            data,
          });

          commit("SET_TEMPLATES", data.templates);
        },
      })
      .then(() => {
        dispatch(
          "snackbar/show",
          {
            text: `Add success`,
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  //* POSTS USER

  FIND_POSTS_BY_USERS: (
    { commit, dispatch },
    { page, take, keywords, user, clientId }
  ) => {
    apollo
      .query({
        query: FIND_POSTS_BY_TYPE,
        variables: { page, take, names: keywords, user, clientId },
      })
      .then(({ data: { posts } }) => {
        switch (user) {
          case "user":
            commit("SET_POSTS_BY_USER", posts);
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },

};
