export default {
  // recently created
  SET_BACKGROUNDS: (state, backgrounds) => {
    state.backgrounds = backgrounds.map((el) => {
      return el;
    });
  },
  SET_BACKGROUNDS_BY_KEYWORD: (state, backgroundsByKeywords) => {
    state.backgroundsByKeywords = backgroundsByKeywords;
  },
  RESET_BACKGROUNDS_BY_KEYWORDS: (state) => {
    state.backgroundsByKeywords = [];
  },
  // finished
  SET_TNC_ALL: (state, tnc) => {
    state.find = tnc;
  },
  SET_TNC_READ: (state, tnc) => {
    const assignment = tnc.assignment.id;
    state.read = { ...tnc, assignment };
  },
  SET_IMAGE_BYKEYWORD: (state, imagesByKeywords) => {
    state.find = imagesByKeywords;
  },
  RESET: (state) => {
    state.read = {};
  },
  SET_BGCOLOR: (state, color) => {
    state.bg.background = color;
  },
  SET_GRADIENTCOLOR: (state, color) => {
    state.gradientColor = color;
  },
  SET_LIENZO_BACKGROUND: (state, background) => {
    state.background = background;
  },
  SET_TYPE_BACKGROUND: (state, type) => {
    state.type = type
  }
};
