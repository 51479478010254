import gql from "graphql-tag";
//import { FOLDER } from "../../../../store/post/folders/graphql/variables";
import { COMMON_VAR, ADDON, QUESTION } from "./variables";

export const FIND_FOLDERS_BY_TYPE = gql(`
  query listAddonsByType($type: String!, $page:Int, $take: Int) {
    items: listAddonsByType(type: $type, page: $page, take: $take) {
			${ADDON}
			${COMMON_VAR}
			questions {
				${QUESTION}
			}
    }
  }
`);
export const FIND_BY_KEYWORDS = gql(`
	query addonsByKeywords ($take:Int, $page:Int, $keywords: [String!]!){
		items: addonsByKeywords(take: $take, page: $page, keywords: $keywords) {
			${ADDON}
			${COMMON_VAR}
			questions {
				${QUESTION}
			}
		}
	}
`);

export const FIND_ADDONS_BY_FOLDER = gql(`
  query listAddonsByFolder($folderId:Int!){
    items:listAddonsByFolder(folderId:$folderId){
      ${ADDON}
      ${COMMON_VAR}
			questions {
				${QUESTION}
			}
    }
  }
`);

export const FIND_ADDONS_BY_FOLDER_PAGINATE_ORDER = gql(`
  query listAddonsByFolderPaginateOrder($folderId:Int!,$take:Int!,$page:Int!,$order:String!){
    items:listAddonsByFolderPaginateOrder(folderId:$folderId,take:$take,page:$page,order:$order){
      ${ADDON}
      ${COMMON_VAR}
			questions {
				${QUESTION}
			}
    }
  }
`);
