<template>
  <div class="icon-alert"> 
    <v-icon
      :color="colorMessage"
      size="17"
    >
      fa fa-exclamation-triangle
    </v-icon> 
    <p 
      :style="{ 'color': colorMessage }"
      class="icon-alert__error"
    > 
      Error 
    </p> 	
  </div>
</template>

<script>
export default {
  name: 'IconError',
  props:{
    colorMessage: {
			type: String,
			default: '#2C3852'
		}
  },
};
</script>

<style lang="scss" scoped>
  .icon-alert{
			text-align: center;
			padding-top: 23px;
			&__error{
				font-weight: 700;
				font-size: 10px;
				margin: 1px 0 0 0;
				height: 17px;
			}
		}
</style>