<template>
  <v-select
    class="select-input"
    hide-details
    dense
    flat
    outlined
    placeholder="Search"
    label="Select"
    solo
    :items="items"
    :item-value="id"
    :item-text="text"
    v-model="model"
  ></v-select>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    id: {
      type: String,
      default: null,
    },
    text: String,
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style lang="scss">
.select-input.v-text-field {
  height: 35px;
  width: 76px;
  font-size: 12px;
  color: #5d6b88;
  & > .v-input__control {
    > .v-input__slot {
      min-height: 35px !important;
      padding: 0 10px !important;
    }
  }
  .v-select__selections {
    margin-left: 2px;

    .v-select__selection {
      margin: 5px 0 3px 0;
    }
    & > input {
      display: none;
    }
  }
  fieldset {
    border-color: #c5d0e4 !important;
  }
  input {
    max-height: 30px;
    color: #5d6b88;
  }

  & > .v-input__control > .v-input__slot {
    background: #ffffff;
    padding: 0 9px;
  }
  .v-input__append-inner {
    margin-top: 0 !important;
    padding: 0 !important;
    align-self: center;

    .v-input__icon {
      min-width: 15px;
      width: 19px;
    }
  }
}
</style>
