<template>
  <v-dialog v-model="show" width="566" class="save-modal" persistent>
      <v-card class="modalB">
        <v-card-title class="modalB_header">
          <i
            class="fa fa-times"
            @click="close"
            aria-hidden="true"
          ></i>
        </v-card-title>
        <div class="modalB_body">
          <v-col class="style_label">
            <h4 class="style_label_name">New folder name:</h4>
          </v-col>
          <v-col class="box_text">
            <input
              type="text"
              class="style-input-text"
              v-model="folderNew"
            />
          </v-col>
          <v-col class="btn-save-folder">
            <v-btn
              color="rgba(0, 165, 255, 1)"
              @click="setFolder"
              :loading="loadingFolder"
              dark
              :height="35"
              :width="67"
            >
              Save
            </v-btn>
          </v-col>
        </div>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
  props: {
    clientId: {
			type: Number,
			default: 0
		},
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      folderNew: '',
      loadingFolder: false,
    }
  },
  methods: {
    close() {
      this.$store.dispatch('modals/close', 'addFolder');
      this.folderNew = '';
    },
    setFolder() {
      let folder = {};
      if (this.folderNew === "") return;
      if (!this.clientId) {
        this.$emit('trigger-error', "Client does not exist" );
        return;
      }
      folder.name = this.folderNew;
      folder.type = this.type;
      folder.clientId = this.clientId;
      this.loadingFolder = true;
      
      this.$store.dispatch("home/main/ADD_FOLDER", { folder, responseFunc: this.responseMessageGraphql });
      this.$store.dispatch('modals/close', 'addFolder');
      this.loadingFolder = false;
      this.folderNew = '';
    },
    responseMessageGraphql(msgResp, typeResp ){
      const typeResponse = {
        "error":  () => {
          let modifiedErrMsg = msgResp.replace("GraphQL error:", "")
          this.$emit('trigger-error', modifiedErrMsg);
        },
        "success": () => this.$emit('trigger-success', msgResp)
      }      
      return typeResponse[typeResp]();
    },
  },
  computed: {
    show: {
      get() {
        return this.$store.getters["modals/all"].find((e) => e === "addFolder");
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  }
  
}
</script>

<style lang="scss">
.modalB {
  height: 242px;
  &_header {
    padding: 25px 25px 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    i {
      color: #696b76;
      cursor: pointer;
      -webkit-text-stroke: 2px white;
    }
  }
  &_body {
    .style_label {
      padding: 0px;
      &_name {
        text-align: left;
        margin-left: 40px;
        font-size: 15px;
        font-weight: normal;
      }
    }
    .box_text {
      padding: 12px 0px 12px 10px;
      margin-bottom: 12px;
    }
    .style-input-text {
      background: #ffffff;
      border: 1px solid #c5d0e4;
      box-sizing: border-box;
      box-shadow: inset 0px 2px 5px rgb(197 208 228 / 62%);
      border-radius: 3px;
      width: 486px;
      height: 35px;
      font-size: 12px;
      padding-left: 14px;
      margin-left: 30px;
    }
    .style-input-text:focus {
      outline: none;
      border-color: #00a5ff;
    }
    .btn-save-folder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 24px;
      .v-btn {
        border-radius: 3px;
        font-size: 12px;
      }
    }
  }
}
</style>