import {
  CREATE_FOLDER,
} from "../graphql/mutations";
import { apollo } from "../../../../plugins/vue-apollo";

export default {
  ADD_FOLDER: ({ commit, dispatch }, { folder, responseFunc }) => {
    const clientId = folder.clientId;
    apollo
      .mutate({
        mutation: CREATE_FOLDER,
        variables: { folder },
        update: (cache, { data: { folder } }) => {

          if(folder.type === 'post') {
            dispatch('home/post/LIST_POSTS_WITH_FOLDER', {
              take: 5,
              page: 1,
              limitPost: 5,
              clientId,
            }, { root: true });
          } else {
            dispatch('home/addon/LIST_ADDONS_WITH_FOLDER', {
              take: 5,
              page: 1,
              limitAddon: 5,
              clientId,
            }, { root: true });
          }

        },
      })
      .then(() => {
        const msgResp =  "Folder added successfully"; 
        const typeResp = "success";
        return responseFunc(msgResp, typeResp)
      })
      .catch((error) => {
        console.log("[Error] in GraphQl CLONE_ADDON", error);
        const msgResp = error?.message ?? "Server fails to create a new folder "; 
        const typeResp = "error";
        return responseFunc(msgResp, typeResp)
      });
  },
}