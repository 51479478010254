<template>
  <div class="preview-question__range">
    <label class="preview-question__label"
      >{{ item.question }} <span v-if="item.required">*</span></label
    >

    <ul class="pa-0 preview-question__list-range">
      <li
        v-for="number in item.rangeReaction"
        :key="number"
        class="list-style-none d-flex align-center p-relative"
      >
        <input
          type="checkbox"
          class="preview-question__item-range mr-2"
          :name="'rdb-' + item.id"
          :id="'rdb-' + item.id + '-' + number"
        />
        <label
          @click="
            setAnswerRange(
              item.id,
              item.idQuestionDb,
              item.question,
              number,
              item.required
            )
          "
        >
        <span v-if="item.reaction.name == 'numbers'"> {{ number }} </span>
      <v-icon color="#5D6B88" size="16" v-if="item.reaction.name !== 'numbers'">{{ item.reaction.icon }}</v-icon>
        </label>
      </li>
    </ul>
		<div class="preview-question__item-range_group">
			<span>{{ ranges.low }}</span>
			<span>{{ ranges.high }} </span>
		</div>
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsRange",
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  computed: {
    ranges() {
      let lowRange = (this.item.lowrange == null || this.item.lowrange.length <= 0 ? 'Lowest range level' : this.item.lowrange);
      let highRange = (this.item.highrange == null || this.item.highrange.length <= 0 ? 'Highest range level' : this.item.highrange);
      return {
        low: lowRange,
        high: highRange,
      }
    },
  },
  methods: {
    setAnswerRange(id, idQuestionDb, question, answer, required) {
      for (let i = 0; i < this.item.rangeReaction; i ++) {
        let ids = `rdb-${ id }-${ i + 1 }`; 
        document.getElementById(ids).checked = false;
      }
      for (let i = 0; i < answer; i ++) {
        let ids = `rdb-${ id }-${ i + 1 }`;
        document.getElementById(ids).checked = true;        
      }
      this.$emit("range-item", id, idQuestionDb, question, answer, required);
    },
  }
};
</script>

<style lang="scss">
.preview-question {
  &__list-range {
    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 17px;
  }
  &__item-range {
    display: none;

    &:checked + label {
      background-color: #00a5ff;
      color: #fff;
      i{
        color: #fff !important;
      }
    }

    & + label {
      height: 32px;
      width: 32px;
      background-color: #f3f4f7;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2.5px;
      border-radius: 3px;
      margin-bottom: 5px;
      font-family: "Oxygen";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #5d6b88;
      cursor: pointer;
    }

    /* &--low,
    &--high {
      font-family: "Oxygen";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #5d6b88;
      display: block;
      bottom: -20px;
      position: absolute;
      width: 100px;
    }

    &--low {
      left: 0;
    }

    &--high {
      right: 0;
      text-align: right;
    } */
		&_group{
			font-family: "Oxygen";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: #5d6b88;
			display: flex;
    	justify-content: space-between;
		}
  }
}
</style>
