export default {
  LOAD_POST_CANVAS: ({ commit }, templatePost) => {
    //* Main Template
    const { 
      id, 
      name, 
      folderId, 
      uuid, 
      dataObjects: { post: { background, drag } } 
    } = templatePost;
  
    if (id) {
      commit("SET_NAME_POST", name);
      commit("SET_ID_POST", id);
      commit("SET_UUID_POST", uuid);
      commit("SET_ID_FOLDER", folderId);
      commit(
        "post/drag/SET_IMAGES",
        drag.images,
        { root: true }
      );
      commit(
        "post/background/SET_LIENZO_BACKGROUND",
        background.background,
        { root: true }
      );
    } else {
      // this is for default Templates
      console.log(" name ", name);
      console.log("templatePost",templatePost)
      commit("SET_NAME_POST", name);
      commit(
        "post/drag/SET_IMAGES",
        drag.images,
        { root: true }
      );
      commit(
        "post/background/SET_LIENZO_BACKGROUND",
        background.background,
        { root: true }
      );
    }
  },
  SET_NAME: ({ commit }, name) => {
    commit("SET_NAME_POST", name);
  },
  RESET_POST: ({ commit }) => {
    // reset name
    commit("SET_NAME_POST", "");
    commit("SET_ID_POST", null);
    commit("SET_UUID_POST", null);
    // reset all images were dragged.
    commit("post/drag/SET_IMAGES", [], { root: true });
    // reset background
    commit("post/background/SET_BGCOLOR", "#FFFFFF", { root: true });

    commit("post/image/SET_SELECTED_IMAGE_DETAIL", null, { root: true });
    commit('post/background/SET_LIENZO_BACKGROUND', "", { root: true })
    commit('post/background/SET_TYPE_BACKGROUND', "", { root: true })
    commit("post/image/SET_SELECTED_IMAGE", null, { root: true });
    commit(
      'global/redoundo/RESET_HISTORY',null,
      { root: true },
    )
    //commit('post/drag/REMOVE_STYLES_SELECTED',null, { root: true });
  },
};
