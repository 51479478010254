 import { alertSetting } from "./alertSetting.js";
 export const handleRespMixin = {
  mixins: [ alertSetting ],
  data() {
    return {
      clientId: Number(sessionStorage.getItem("client_id")) 
    };
  },
  methods: {
    responseMessageGraphql(msgResp, typeResp ){
      const typeResponse = {
        "error":  () => {
          let modifiedErrMsg = msgResp.replace("GraphQL error:", "")
          return this.errorModal(modifiedErrMsg);
        },
        "success": () => this.successModal(msgResp),
      }      
      return typeResponse[typeResp]();
    }
  },
};