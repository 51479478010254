<template>
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M18.125 0H1.875C0.820312 0 0 0.859375 0 1.875V13.125C0 14.1797 0.820312 15 1.875 15H18.125C19.1406 15 20 14.1797 20 13.125V1.875C20 0.859375 19.1406 0 18.125 0ZM17.8906 13.125H2.10938C1.95312 13.125 1.875 13.0469 1.875 12.8906V2.10938C1.875 1.99219 1.95312 1.875 2.10938 1.875H17.8906C18.0078 1.875 18.125 1.99219 18.125 2.10938V12.8906C18.125 13.0469 18.0078 13.125 17.8906 13.125ZM5 3.4375C4.10156 3.4375 3.4375 4.14062 3.4375 5C3.4375 5.89844 4.10156 6.5625 5 6.5625C5.85938 6.5625 6.5625 5.89844 6.5625 5C6.5625 4.14062 5.85938 3.4375 5 3.4375ZM3.75 11.25H16.25V8.125L12.8125 4.72656C12.6172 4.53125 12.3438 4.53125 12.1484 4.72656L7.5 9.375L5.9375 7.85156C5.74219 7.65625 5.46875 7.65625 5.27344 7.85156L3.75 9.375V11.25Z"
    />
  </svg>
</template>

<script>
export default {
  name: "IcoImages",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#96A0B8";
      },
    },
  },
};
</script>
