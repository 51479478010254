import { apollo } from "../../../../plugins/vue-apollo";
import {
  DELETE_QUESTION,
  UPDATE_QUESTION,
  CREATE_QUESTION,
  UPDATE_QUESTION_POSITIONS,
} from "../graphql/mutation";

export default {
  // also create a Addon by user
  CREATE: async (_, { componentQuestion }) => {
    await apollo.mutate({
      mutation: CREATE_QUESTION,
      variables: { componentQuestion },
      /* update: (_, { data: { question }}) => {
					const items = addon.question.map((el) => {
						if(!el.idQuestionDb){
							return {
								...el,
								idQuestionDb: question.id 
							}
						}else {
							return el
						}
					})
					console.log(' items ', items );
					commit("addons/create/SET_QUESTIONS", items, { root: true });
				}, */
    });
  },

  UPDATE: async (_, { id, componentQuestion }) => {
    await apollo.mutate({
      mutation: UPDATE_QUESTION,
      variables: { id, componentQuestion },
      /* update: (question) => {
					console.log(' rootState ', rootState);
					console.log('question  ', question );
				}, */
    });
  },
  DELETE_QUESTION: async (_, id) => {
    await apollo.mutate({
      mutation: DELETE_QUESTION,
      variables: { id },
      /* update: () => {
					const items = addon.question.map((el, index) => {
						return {
							...el,
							id : index + 1 
						}
					})
					commit("addons/create/SET_QUESTIONS", items, { root: true });
				}, */
    });
  },
  UPDATE_POSITIONS: async (_, { positions, ids }) => {
    await apollo.mutate({
      mutation: UPDATE_QUESTION_POSITIONS,
      variables: { positions, ids },
      /* update: (question) => {
					console.log(' rootState ', rootState);
					console.log('question  ', question );
				}, */
    });
  },
};
