import gql from 'graphql-tag'
import { ADDON, COMMON_VAR } from '../../folders/graphql/variables'
import { QUESTION } from './variables'
export const CREATE_ADDON = gql(`
  mutation create($addon: AddonsDTO!){
    addon: createAddon(addon: $addon) {
      ${ADDON}
      ${COMMON_VAR}
      questions {
				${QUESTION}
			}
    }
  }
`)
export const UPDATE_ADDON = gql(`
  mutation update( $id: Int!, $addon: AddonsDTO!){
    addon: updateAddon( id: $id, addon: $addon) {
      ${ADDON}
      ${COMMON_VAR}
      questions {
				${QUESTION}
			}
    }
  }
`)

export const CREATE_COMPONENT_METRIC = gql(`
  mutation createComponentMetric($componentMetric:ComponentMetricDTO!){
    componentMetric: createComponentMetric(componentMetric:$componentMetric){
      id
    }
  }
`)
