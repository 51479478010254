import { decodeJWT, getCurrentDateUtcUnixTime } from "../utils/utils";
import { mapActions } from "vuex";
export const expTokenMixin = {
  data() {
    return {
      intervalId: null, // To store the interval ID
      tokenExpirationTime: null,
      checkInterval: 1 * 60 * 1000, // 5 minutes in milliseconds
      isShowLoader: false,
      tokenExpiration: null,
    };
  },
  created() {
    this.fetchTokenExpiration(); // Fetch token expiration initially
    // test, if it is necessary to checkTokenExpiry function, every time that the user changes the components as a first validation.
    this.checkTokenExpiry();
    this.startTokenExpiryCheck(); // Start checking for token expiry every 5 minutes
  },
  methods: {
    ...mapActions({
      setLogout: "global/auth/SESSION_LOGOUT",
      renewToken: "global/auth/RENEW_TOKEN",
      updateSession: "global/auth/SESSION_UPDATE",
    }),
    fetchTokenExpiration() {
      // Fetch token expiration from your backend or session
      const token = sessionStorage.getItem("token");
      if(!token) this.clearStorageAndRedirect();
      const {
        payload: { exp },
      } = decodeJWT(token);
      this.tokenExpiration = exp;
      this.tokenExpirationTime = exp * 1000; //* this is for remaining time
    },
    startTokenExpiryCheck() {
      this.intervalId = setInterval(() => {
        this.checkTokenExpiry(); // Check token expiration every 5 minutes
      }, this.checkInterval);
    },
    checkTokenExpiry() {
      const timeLeft =
        this.tokenExpirationTime - getCurrentDateUtcUnixTime() * 1000;
      //console.log("TOKEN EXPIRATION TIME ===>", this.tokenExpirationTime);
      //console.log("CURRENT TIME UNIX ===>", getCurrentDateUtcUnixTime() * 1000);
      //console.log("LEFT TIME ===>", timeLeft);
      if (timeLeft <= 5 * 60 * 1000 && timeLeft > 0) {
        //* If token expires in 5 minutes, show the modal
        this.showModalBtn(
          "Session Expiring Soon: 5 Minutes Remaining",
          "Would you like to continue?"
        );
      } else {
        this.checkTokenExpWithCurrent();
      }
    },
    // Stop the interval
    stopTokenExpiryCheck() {
      if (this.intervalId) {
        clearInterval(this.intervalId); // Stop the interval
        console.log("Token expiry check interval stopped.");
        this.intervalId = null; // Clear the ID
      }
    },
    checkTokenExpWithCurrent() {
      //console.log("=====CHECKING TOKEN EXPIRATION=====");
      if (this.tokenExpiration <= getCurrentDateUtcUnixTime()) {
        this.clearStorageAndRedirect();
      }
    },
    clearStorageAndRedirect() {
      localStorage.clear();
      sessionStorage.clear();
      const pathUrl = `${process.env.URL_MONITOR}/login`;
      window.location.replace(pathUrl);
    },
    async redirectToLogin() {
      this.isShowLoader =false;
      await this.setLogout();
      this.clearStorageAndRedirect();
    },
    async setTokenAndSession() {
      try {
        this.isShowLoader = true;
        // get refresh token
        const refreshToken = sessionStorage.getItem("refresh");
        //validate if it exists
        if (!refreshToken) this.clearStorageAndRedirect();
        //* renew token by refresh token
        const respRenewToken = await this.renewToken({ refreshToken });
        const { data: { newToken } } = respRenewToken.data;
        // set the new token
        sessionStorage.setItem("token", newToken);
        //todo: update session
        await this.updateSession();
        this.isShowModalBtn = false; //* This variable comes from doubleBtnMixin
        this.isShowLoader =false;
        this.fetchTokenExpiration();
        this.startTokenExpiryCheck();
      } catch (error) {
        console.log("[Error] in function setTokenAndSession", error);
        this.stopTokenExpiryCheck();
        this.clearStorageAndRedirect();
      }
    },
  },
  beforeDestroy() {
    this.stopTokenExpiryCheck(); // Clear the interval id before the component is destroyed
  },
};
