export default {
  backgrounds: (state) => state.backgrounds,
  backgroundsByKeywords: (state) => state.backgroundsByKeywords,

  list: (state) => state.list,
  find: (state) => state.find,
  read: (state) => state.read,
  background: (state) => state.background, // used it
  type: (state) => state.type,
  bg: (state) => state.bg, // used it
  gradientColor: (state) => state.gradientColor,
  isLoading: (state) => state.isLoading
};
