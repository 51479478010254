var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.35156 5.24023L8.17773 8.31641C8.38281 8.4043 8.58789 8.4043 8.79297 8.31641L15.6191 5.24023C16.1172 5.00586 16.1172 4.27344 15.6191 4.03906L8.79297 0.962891C8.70508 0.904297 8.58789 0.904297 8.5 0.904297C8.38281 0.904297 8.26562 0.904297 8.17773 0.962891L1.35156 4.03906C0.853516 4.27344 0.853516 5.00586 1.35156 5.24023ZM15.6191 7.81836L13.9199 7.02734L9.17383 9.19531C8.96875 9.2832 8.73438 9.3418 8.5 9.3418C8.23633 9.3418 8.00195 9.2832 7.79688 9.19531L3.05078 7.02734L1.35156 7.81836C0.853516 8.02344 0.853516 8.75586 1.35156 8.99023L8.17773 12.0664C8.38281 12.1543 8.58789 12.1543 8.79297 12.0664L15.6191 8.99023C16.1172 8.75586 16.1172 8.02344 15.6191 7.81836ZM15.6191 11.5684L13.9199 10.7773L9.17383 12.9453C8.96875 13.0332 8.73438 13.0918 8.5 13.0918C8.23633 13.0918 8.00195 13.0332 7.79688 12.9453L3.05078 10.7773L1.35156 11.5684C0.853516 11.7734 0.853516 12.5059 1.35156 12.7402L8.17773 15.8164C8.38281 15.9043 8.58789 15.9043 8.79297 15.8164L15.6191 12.7402C16.1172 12.5059 16.1172 11.7734 15.6191 11.5684Z",
          fill: _vm.fill,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }