import { mapActions  } from "vuex";
export const signedUrlUploadMixin = {
  created() {
    
  },
  methods: {
    ...mapActions({
      getSignedUrl: "post/uploads/GET_SIGNED_URL_S3",
      uploadToS3File: "post/uploads/UPLOAD_URL_SIGNED_S3",
      getProcessThumbnailsImage: "post/uploads/GET_PROCESS_THUMBNAILS_IMAGE",
    }),
    async setUrlSignedS3AndUpload(file, index, clientId, sizeKb) {
      // Important: first we only test here this code, then pass to other function
      const payload = {
        filename: file.name,
        contentType: file.type,
        fileSize: file.size,
        dir: 'temp/' // IMPORTANT: do not change this path
      };
      try {
        //*Get signed url, Important: add dir= directory path, which will be located the file  
        const {
          data: {
            dataSigned: { s3Url, fileName },
          },
        } = await this.getSignedUrl({ payload, clientId, sizeKb, index });
        const uploadFile = new File([file], fileName, { type: file.type });
        //* Here add loading upload
        await this.uploadToS3File({ s3Url, file: uploadFile, index });
        return { fileName, contentType: payload.contentType };
        
      } catch (error) {
        console.log("[Error] in function setUrlSignedS3AndUpload (Mixin) ", error);
        throw error;
      }
    },
  },
};