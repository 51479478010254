export default {
  ACTIVE: ({ commit }, isActive) => {
    commit("SET_IS_ACTIVE", isActive);
  },
  ACTIVE_HORIZONTAL: ({ commit }, isActiveHorizontal) => {
    commit("SET_IS_ACTIVE_HORIZONTAL", isActiveHorizontal);
  },
  ACTIVE_VERTICAL: ({ commit }, isActiveVertical) => {
    commit("SET_IS_ACTIVE_VERTICAL", isActiveVertical);
  },
  ACTIVE_SECONDARY: ({ commit }, isActiveSecondary) => {
    commit("SET_IS_ACTIVE_SECONDARY", isActiveSecondary);
  },
};
