var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message" }, [
    _c("div", { staticClass: "message__title" }, [
      _vm._v("Confirmation Message"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "message__text" }, [
      _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }