export default {
  id: null,
  postUuid: null,
  name: "",
  folderId: null,
  dataObject: {
    background: "",
    items: [], // items en el lienzo
  },
};
