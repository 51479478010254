<template>
  <div class="addon-preview__background">
    <div :style="[image.styles, {overflow: 'hidden'}]">
      <div :style="image.stylesCropped">
        <img
          :src="image.path"
          alt=""
          style="width: 100%; height: 100%;"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewBackground",
  props: {
    image: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.addon-preview {
  &__background {
    width: 100%;
    height: 256px;
    overflow: hidden;
    background: #fff;
  }
}
</style>
