export const QUESTION = [
  "id",
  "addonId",
  "question",
  "textQuestion",
  "position",
  "options",
  "required",
  "options",
];
