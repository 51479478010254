let transform = 1;

if(window.screen.width <= 1920 && window.screen.width >= 1300) {
  transform = 0.5
} else if(window.screen.width < 1300) {
  transform = 0.5
} else {
  transform = 1
}

export default {
  zoom: {
    width: 1200 * transform,
    height: 1200 * transform,
    transform,
  },
  post: {
    title: "Post (Fb/Ig/LinkedIn)",
    width: 1200,
    height: 1200,
  },
};
