<template>
  <div>
    <div class="subtitle-account">
      <div class="subtitle-icon" v-if="icon" v-html="icon"></div>
      <span>
        {{ label }}
      </span>
    </div>
    <v-select
      class="design-select"
      :class="{'opacity_': disabled}"
      :background-color="backgroundColor"
      hide-details
      placeholder="Loading .."
      v-model="model"
      :items="items"
      :disabled="disabled"
      height="35px"
    ></v-select>
  </div>
</template>


<script>
export default {
  name: "SelectDesign",
  props: {
    value: {
      type: [String, Number, Object],
      default: () => {
        return null;
      },
    },
    label: {
      type: String,
      default: () => {
        return "";
      },
    },
    placeholder: {
      type: String,
      default: () => {
        return "All";
      },
    },
    id: {
      type: String,
      default: () => {
        return "";
      },
    },
    text: {
      type: String,
      default: () => {
        return "";
      },
    },
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    backgroundColor: {
      type: String,
      default: "#222d3a",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    icon: {
      type: String,
    },
  },
  data(){
    return{
      listItems:[]
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    }
  },
  watch:{
    items(n,o){
      (n.length > 0) ? this.disabled = false : this.disabled = true;
    }
  },
  /* mounted(){
    this.loadItems()
  },
  methods:{
    loadItems(){
      setTimeout(() => {
        this.listItems = this.items;
      }, 1000);
    }
  } */
};
</script>
<style lang="scss">
.subtitle-icon {
  margin-right: 5px;
}
.subtitle-account {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.subtitle-account span{
  font-size: 0.75em;
  font-weight: bold;
  margin-left: 3px;
}
.opacity_{
  opacity: .3;
}
.design-select .v-input__slot{
    height: 35px;

  
}
</style>
