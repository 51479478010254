import {
  ENABLE_ACTIVE,
  DISABLE_ACTIVE,
  ENABLE_ASPECT,
  DISABLE_ASPECT,
  ENABLE_DRAGGABLE,
  DISABLE_DRAGGABLE,
  ENABLE_CROPPER,
  DISABLE_CROPPER,
  ENABLE_RESIZABLE,
  DISABLE_RESIZABLE,
  ENABLE_PARENT_LIMITATION,
  DISABLE_PARENT_LIMITATION,
  ENABLE_SNAP_TO_GRID,
  DISABLE_SNAP_TO_GRID,
  CHANGE_ZINDEX,
  ENABLE_BOTH_AXIS,
  ENABLE_X_AXIS,
  ENABLE_Y_AXIS,
  ENABLE_NONE_AXIS,
  CHANGE_HEIGHT,
  CHANGE_LEFT,
  CHANGE_MINH,
  CHANGE_MINW,
  CHANGE_TOP,
  CHANGE_WIDTH,
} from "./mutation-types";

export default {
  SET_ACTIVE_ELEMENT: (state, element) => {
    state.element = element;
  },
  SET_TYPE: (state, type) => {
    state.type = type;
  },
  SET_TOOLBAR: (state, toolbar) => {
    state.toolbar = toolbar;
  },
  SET_ITEMS: (state, elements) => {
    state.elements = elements;
  },
  SET_INDEX: (state, { index }) => {
    state.element.zIndex = index;
  },
  SET_FOLDER: (state, folder) => {
    state.folder = folder;
  },
  [ENABLE_ACTIVE](state, id) {
    state.elements[id].active = true;
    state.elements[id].behavior = true;
    state.toolbar = false;
    state.type = state.elements[id].type;
  },
  [DISABLE_ACTIVE](state, id) {
    state.elements[id].active = false;
    state.elements[id].behavior = false;
    const deselectAll = state.elements.filter((rect) => rect.active === true);

    if (deselectAll.length === 0) {
      state.type = null;
      state.toolbar = true;
    }
  },

  [ENABLE_ASPECT](state, id) {
    state.elements[id].aspectRatio = true;
  },
  [DISABLE_ASPECT](state, id) {
    state.elements[id].aspectRatio = false;
  },

  [ENABLE_DRAGGABLE](state, id) {
    state.elements[id].draggable = true;
  },
  [DISABLE_DRAGGABLE](state, id) {
    state.elements[id].draggable = false;
  },

  [ENABLE_CROPPER](state, id) {
    state.elements[id].cropper = true;
  },
  [DISABLE_CROPPER](state, id) {
    state.elements[id].cropper = false;
  },

  [ENABLE_RESIZABLE](state, id) {
    state.elements[id].resizable = true;
  },
  [DISABLE_RESIZABLE](state, id) {
    state.elements[id].resizable = false;
  },

  [ENABLE_SNAP_TO_GRID](state, id) {
    state.elements[id].snapToGrid = true;
  },
  [DISABLE_SNAP_TO_GRID](state, id) {
    state.elements[id].snapToGrid = false;
  },

  [ENABLE_BOTH_AXIS](state, id) {
    state.elements[id].axis = "both";
  },
  [ENABLE_NONE_AXIS](state, id) {
    state.elements[id].axis = "none";
  },
  [ENABLE_X_AXIS](state, id) {
    state.elements[id].axis = "x";
  },
  [ENABLE_Y_AXIS](state, id) {
    state.elements[id].axis = "y";
  },

  [ENABLE_PARENT_LIMITATION](state, id) {
    state.elements[id].parentLim = true;
  },
  [DISABLE_PARENT_LIMITATION](state, id) {
    state.elements[id].parentLim = false;
  },

  [CHANGE_ZINDEX](state, payload) {
    state.elements[payload.id].zIndex = payload.zIndex;
  },

  [CHANGE_HEIGHT](state, payload) {
    state.elements[payload.id].height = payload.height;
  },

  [CHANGE_WIDTH](state, payload) {
    state.elements[payload.id].width = payload.width;
  },

  [CHANGE_TOP](state, payload) {
    state.elements[payload.id].top = payload.top;
  },

  [CHANGE_LEFT](state, payload) {
    state.elements[payload.id].left = payload.left;
  },

  [CHANGE_MINH](state, payload) {
    state.elements[payload.id].minh = payload.minh;
  },

  [CHANGE_MINW](state, payload) {
    state.elements[payload.id].minw = payload.minw;
  },
};
