import gql from "graphql-tag";
import { FOLDER, POST } from "./variables";

export const CLONE_POST = gql(`
  mutation clonePost($id:Int!){
    clonePost(id:$id){
      ${POST}
    }
  }
`);

export const DELETE_POST = gql(`
  mutation deletePost($id:Int!){
    deletePost(id:$id)
  }
`);

export const CLONE_FOLDER = gql(`
  mutation cloneFolder($folderContext:FolderContextDTO!,$limitPost:Int!){
    cloneFolder(folderContext:$folderContext){
      ${FOLDER}
      items:posts(limitPost: $limitPost){
        ${POST}
      }
    }
  }
`);

export const DELETE_FOLDER = gql(`
  mutation deleteFolder($id:Int!){
    deleteFolder(id:$id)
  }
`);
