var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "logo" }, [
    _c("div", { staticClass: "logo__container-img" }, [
      _vm.message.logo
        ? _c("img", {
            staticClass: "logo__img",
            attrs: { src: _vm.message.logo, alt: "logo" },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "text-center d-block logo__title" }, [
      _vm._v(_vm._s(_vm.message.title)),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "text-center d-block logo__description" }, [
      _vm._v(_vm._s(_vm.message.description)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }