export default {
  ADD_FONT_WEIGHT_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          fontWeight:
            item.styles.fontWeight === "bold" ? "" : "bold",
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  ADD_ITALIC_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          fontStyle:
            item.styles.fontStyle === "italic" ? "" : "italic",
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  ADD_UNDERLINE_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          textDecoration:
            item.styles.textDecoration === "underline"
              ? ""
              : "underline",
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  ADD_FONT_ALIGN_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { textAlign, uuid, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          textAlign,
        };
      }
      return item;
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  ADD_LINE_HEIGHT_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { lineHeight, uuid}
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          lineHeight,
        };
      }
      return item;
    });
    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  ADD_FONT_TRANSFORM_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          textTransform:
            item.styles.textTransform === "uppercase"
              ? "lowercase"
              : "uppercase",
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  ADD_LIST_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          default: `<ul><li>${item.default}</li></ul>`,
        };
      } else {
        return item;
      }
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_FONT_FAMILY_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { fontFamily, uuid, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          fontFamily,
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_SIZE_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, scale }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) item.transform.scale = scale;
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_FONT_SIZE_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, index, fontSize }
  ) => {
    const images = drag.images.map((item) => {
      // if (item.uuid === uuid) item.transform.scale = scale;
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          fontsList:fontSize
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_COLOR_IN_TEXT: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { uuid, color, index }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        item.styles = {
          ...item.styles,
          color,
        };
      }
      return item;
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
};
