export const UPLOAD = [
  "id",
  "path",
  "mimeType",
  "width",
  "height",
  "thumbnails",
  "objectsId",
  "sizeKb",
];
