var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "21",
        viewBox: "0 0 20 21",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.fill,
          d: "M11.5625 15.5C12.0703 15.5 12.5 15.1094 12.5 14.5625V8H15.8984C16.6016 8 16.9531 7.17969 16.4453 6.67188L10.5078 0.734375C10.2344 0.460938 9.72656 0.460938 9.45312 0.734375L3.51562 6.67188C3.00781 7.17969 3.35938 8 4.0625 8H7.5V14.5625C7.5 15.1094 7.89062 15.5 8.4375 15.5H11.5625ZM20 15.1875C20 14.6797 19.5703 14.25 19.0625 14.25H13.75V14.5625C13.75 15.7734 12.7344 16.75 11.5625 16.75H8.4375C7.22656 16.75 6.25 15.7734 6.25 14.5625V14.25H0.9375C0.390625 14.25 0 14.6797 0 15.1875V19.5625C0 20.1094 0.390625 20.5 0.9375 20.5H19.0625C19.5703 20.5 20 20.1094 20 19.5625V15.1875ZM15.1562 18.625C15.1562 19.0547 14.8047 19.4062 14.375 19.4062C13.9453 19.4062 13.5938 19.0547 13.5938 18.625C13.5938 18.1953 13.9453 17.8438 14.375 17.8438C14.8047 17.8438 15.1562 18.1953 15.1562 18.625ZM17.6562 18.625C17.6562 19.0547 17.3047 19.4062 16.875 19.4062C16.4453 19.4062 16.0938 19.0547 16.0938 18.625C16.0938 18.1953 16.4453 17.8438 16.875 17.8438C17.3047 17.8438 17.6562 18.1953 17.6562 18.625Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }