const mutations = {
  CAN_REDO: (state, index) => {
    state.canRedo = index
  },
  CAN_UNDO: (state, index) => {
    state.canUndo = index
  },
  HISTORY: (state, history) => {
    state.history.push(history)
    // state.historyIndex += 1
  },
  UPDATE_INDEX: (state, index) => {
    state.historyIndex += index
  },
  ADD_HISTORY: (state, { images, background, type }) => {
    if (state.newHistory.length != state.historyIndex + 1) {
      state.newHistory.splice(state.historyIndex + 1)
    }
    state.newHistory.push({ images, background, type })
    state.historyIndex = state.newHistory.length - 1
  },
  RESET_HISTORY: (state) => {
    state.newHistory = []
    state.historyIndex = -1
  }
}

export default mutations
