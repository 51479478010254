import gql from "graphql-tag";

import { SHAPE, CHILD } from "./variables";
import { OBJECT } from "../../backgrounds/graphql/variables";

export const FIND_SHAPES = gql(`
  query {
    shapes {
      ${SHAPE}
      child {
        ${CHILD}
        object{
          ${OBJECT}
        }
      }
    }
	}`);

export const FIND_SHAPES_WITH_KEYWORDS = gql(`
	query ($keywords:[String!]!){
		shapesByKeywords(keywords: $keywords) {
			${SHAPE}
			${CHILD}
			object{
				${OBJECT}
			}
		}
	}
`);
