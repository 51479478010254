import { mapActions  } from "vuex";
export const picUploaderMixin = {
  created() {
    
  },
  methods: {
    ...mapActions({
      getSignedUrlPic: "post/uploads/GET_SIGNED_URL_PIC",
      uploadToS3FilePic: "post/uploads/UPLOAD_SIGNED_URL_TO_S3",
    }),
    async setUrlSignedS3AndUploadPic(file) {
      // Important: first we only test here this code, then pass to other function
      const payload = {
        filename: file.name,
        contentType: file.type,
        fileSize: file.size,
        dir: 'temp/' // IMPORTANT: do not change this path
      };
      try {
        //*Get signed url, Important: add dir= directory path, which will be located the file  
        const {
          data: {
            dataSigned: { s3Url, fileName },
          },
        } = await this.getSignedUrlPic({ payload });
        const uploadFile = new File([file], fileName, { type: file.type });
        //* Here add loading upload
        await this.uploadToS3FilePic({ s3Url, file: uploadFile});
        return { fileName, contentType: payload.contentType };
        
      } catch (error) {
        console.log("[Error] in function setUrlSignedS3AndUpload (Mixin) ", error);
        throw error;
      }
    },
    /**
     * This function takes a file and uploads it to s3 (Before that, 
     * it generates a  signed url), and then returns the path
     * of the uploaded image.
     *
     * @param {File} file the file to be uploaded
     * @returns {Promise<string>} a promise that resolves with the path of the uploaded image
     */
    async getUploadedPicture(file) {
      //* This functions comes from mixin PicUploaderMixin
      const { fileName, contentType } = await this.setUrlSignedS3AndUploadPic(file);

      const {
        data: {
          file: { path },
        },
      } = await this.$store.dispatch("post/uploads/UPLOAD_SINGLE_IMAGE", {
        fileKey: fileName,
        contentType,
      });
      return path;
    },
  },
};