import Vue from "vue";
import Vuex from "vuex";
import home from "./home/index";
import addons from "./addons/index";
import global from "./global/index";
import post from "./post/index";
import modals from "./modals/index";
import {undoRedoPlugin} from "./plugin/undoredo.plugin";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    hello() {
      console.log("World");
    },
  },
  modules: {
    home,
    addons,
    global,
    post,
		modals
  },
  plugins: [undoRedoPlugin]
});
