<template>
  <div class="text-center">
    <v-dialog
      overlay-color="rgba(93, 107, 136, 0.5)"
      overlay-opacity="1"
      v-model="model"
      width="566"
      persistent
    >

      <div class="isc-modal__content">
        <div
          class="isc-modal__header d-flex justify-end"
        >
          <v-btn
            icon
            @click="close"
            class="isc-modal__content--btn-close"
          >
            <svg
              v-if="!loading" 
              width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.37894 7.5039L14.8088 1.07402C15.057 0.834344 15.0639 0.438888 14.8242 0.190722C14.5845 -0.0574443 14.1891 -0.0643256 13.9409 0.175349C13.9357 0.180363 13.9306 0.185488 13.9255 0.190722L7.49561 6.62061L1.06572 0.190685C0.817555 -0.048989 0.422099 -0.0421077 0.182425 0.206059C-0.0513935 0.448149 -0.0513935 0.831928 0.182425 1.07402L6.61231 7.5039L0.182425 13.9338C-0.0614592 14.1777 -0.0614592 14.5732 0.182425 14.8171C0.426345 15.0609 0.8218 15.0609 1.06572 14.8171L7.49561 8.3872L13.9255 14.8171C14.1737 15.0568 14.5691 15.0499 14.8088 14.8017C15.0426 14.5596 15.0426 14.1758 14.8088 13.9338L8.37894 7.5039Z" fill="#696B76"/>
            </svg>
          </v-btn>
        </div>
        <h2 
          v-if="title" 
          class="isc-modal__title" 
          :style="{ marginBottom: !subtitle ? '30px' : '', padding: generalPaddingTitle}" 
          v-html="title"
        >
        </h2>
        <p class="isc-modal__subtitle" v-if="subtitle" v-html="subtitle"></p>
        
        <div class="isc-modal__footer d-flex justify-center">
          <v-btn
            v-if="action == 1"
            color="primary"
            class="isc-modal__btn"
            :loading="loading"
            @click="clicked"
          >
            {{ actionOk }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    action: {
      type: Number,
      default: 1
    },
    actionOk: {
      type: String,
      default: 'Ok'
    },
  generalPaddingTitle: {
      type: String,
      default: "0px 83px"
    }
  },
  data () {
    return {
      
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    clicked() {
      this.$emit("clicked", true);
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep .v-dialog {
    box-shadow: none;
  }
  .isc-modal {
    &__content {
      background-color: #fff;
      border-radius: 6px;
      padding: 15px 15px 59px;
      box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
      &--btn-close{
        &:hover::before{
          display: none;
        }
      }
    }

    &__header {
      margin-bottom: 10px;
    }
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #2C3852;
      text-align: center;
      margin-bottom: 13px;
    }
    &__subtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      color: #2C3852;
      text-align: center;
      margin-bottom: 40px;
    }
    &__btn {
      box-shadow: 0px 2px 4px rgba(44, 56, 82, 0.1);
      padding: 9px 20px 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }

  }
  
</style>