export default {
  SET: ({ commit }, data) => {
    let item = data.item;
    item.key = data.count + 1;
    commit("SET_OBJECTS_ALL", item);
  },
  SETTEXTSELECT: ({ commit }, data) => {
    commit("SET_TEXT_SELECT", data.item);
  },
  SETALL: ({ commit }, data) => {
    commit("SET_OBJECTS", data.items);
  },
  IS_TEXT_SELECTED: ({ commit }, isTextSelected) => {
    commit("SET_IS_TEXT_SELECTED", isTextSelected);
  },
};
