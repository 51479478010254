export default {
  SET_SHAPES: (state, shapes) => {
    state.shapes = shapes;
  },
  SET_SHAPES_BY_KEYWORD: (state, shapesByKeywords) => {
    state.shapesByKeywords = shapesByKeywords;
  },
  RESET_SHAPES_BY_KEYWORDS: (state) => {
    state.shapesByKeywords = [];
  },
};
