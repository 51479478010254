export default {
  SET_FOLDERS_BY_ADDON: (state, items) => {
    state.addon = items;
  },
  SET_FOLDERS_BY_CONTEST: (state, items) => {
    items.forEach((el) => {
      state.contest.push(el);
    });
  },
  SET_FOLDERS_BY_SURVEY: (state, items) => {
    items.forEach((el) => {
      state.survey.push(el);
    });
  },
  SET_FOLDERS_BY_PROMO: (state, items) => {
    items.forEach((el) => {
      state.promo.push(el);
    });
  },
  SET_FOLDERS_BY_AUDIENCE: (state, items) => {
    items.forEach((el) => {
      state.audience.push(el);
    });
  },
  SET_ADDONS_BY_FOLDER: (state, items) => {
    state.addonsByFolder = items;
  },
  SET__FOLDER_VIEW: (state, folder) => {
    state.folderView = folder;
  },
  FIND_ADDONS_BY_KEYWORDS: (state, items) => {
    state.addonsByKeywords = items;
  },
  SET_MORE_ADDON: (state, moreAddon) => {
    state.moreAddon = moreAddon;
  },
  ADD_CLONE_ADDON_FOLDER: (state, cloneAddon) => {
    state.addonsByFolder.unshift(cloneAddon);
    if (state.morePost) {
      state.addonsByFolder.pop();
    }
  },
  DELETE_ADDON_FOLDER: (state, id) => {
    state.addonsByFolder = state.addonsByFolder.filter((el) => el.id != id);
  },
  ORDER_ADDONS_BY_FOLDER: (state, type) => {
    //type 1 >Last modified, updatedAt; type 2 >alphabetically,name
    state.addonsByFolder.sort((a, b) => {
      if (a[type] < b[type]) {
        return -1;
      } else {
        return 1;
      }
    });
  },
  RESET_CONTEST: (state) => {
    state.contest = [];
  },
  RESET_SURVEY: (state) => {
    state.survey = [];
  },
  RESET_PROMO: (state) => {
    state.promo = [];
  },
  RESET_AUDIENCE: (state) => {
    state.audience = [];
  },
  RESET_ADDONS_KEYWORDS: (state) => {
    state.addonsByKeywords = [];
  },
  SET_IS_DELETE_ADDON: (state, isDeleteAddon) => {
    state.isDeleteAddon = isDeleteAddon;
  },
  SET_IS_LOADING: (state, isLoading) => {
    state.isLoading = isLoading
  }
};
