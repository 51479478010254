var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "12",
        height: "13",
        viewBox: "0 0 12 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M4.96875 5.77344C5.10938 5.77344 5.25 5.63281 5.25 5.49219V4.36719C5.25 4.20312 5.10938 4.08594 4.96875 4.08594H2.67188C3.42188 3.05469 4.61719 2.375 6 2.375C8.27344 2.375 10.125 4.22656 10.125 6.5C10.125 8.79688 8.27344 10.625 6 10.625C4.94531 10.625 3.98438 10.25 3.25781 9.59375C3.14062 9.5 2.97656 9.5 2.88281 9.61719L2.08594 10.4141C1.96875 10.5312 1.96875 10.7188 2.08594 10.8125C3.11719 11.75 4.5 12.3125 6 12.3125C9.21094 12.3125 11.8125 9.73438 11.8125 6.52344C11.8125 3.3125 9.25781 0.734375 6.04688 0.710938C4.3125 0.6875 2.74219 1.4375 1.6875 2.63281V0.78125C1.6875 0.640625 1.54688 0.5 1.40625 0.5H0.28125C0.117188 0.5 0 0.640625 0 0.78125V5.49219C0 5.63281 0.117188 5.77344 0.28125 5.77344H4.96875Z",
          fill: _vm.fill,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }