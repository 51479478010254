import state from "./state";

const mutations = {
    CAN_REDO: ( state, index ) => {
        state.canRedo = index;
    },
    CAN_UNDO: ( state, index ) => {
        state.canUndo = index;
    },
    HISTORY: (state, history) => {
        state.history.push(history);
        state.historyIndex += 1;
    },
    UPDATE_INDEX: (state , index) => {
        state.historyIndex += index
    }
}

export default mutations;