var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "save-modal",
      attrs: { width: "566", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "modalB" },
        [
          _c("v-card-title", { staticClass: "modalB_header" }, [
            _c("i", {
              staticClass: "fa fa-times",
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.close },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modalB_body" },
            [
              _c("v-col", { staticClass: "style_label" }, [
                _c("h4", { staticClass: "style_label_name" }, [
                  _vm._v("New folder name:"),
                ]),
              ]),
              _vm._v(" "),
              _c("v-col", { staticClass: "box_text" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.folderNew,
                      expression: "folderNew",
                    },
                  ],
                  staticClass: "style-input-text",
                  attrs: { type: "text" },
                  domProps: { value: _vm.folderNew },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.folderNew = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "btn-save-folder" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "rgba(0, 165, 255, 1)",
                        loading: _vm.loadingFolder,
                        dark: "",
                        height: 35,
                        width: 67,
                      },
                      on: { click: _vm.setFolder },
                    },
                    [_vm._v("\n            Save\n          ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }