const mutations = {
  SET_TITLE_PAGE(state, title) {
    state.title_page = title;
  },
  SET_NAME(state, value) {
    state.name = value;
  },
  SET_REFRESH(state, value) {
    state.refresh = value;
  },
  SET_TOKEN(state, value) {
    state.token = value;
  },
  CHANGE_STATE_MENUSIDEBAR(state, data) {
    state.disabledMenuItem = data;
  },
  SET_OBJ_USUARIO(state, obj) {
    state.objUsuario = obj;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_EDIT_PROJECT: (state, editProject) => {
    state.editProject = editProject;
  },
};

export default mutations;
