export const POST = [
  "id",
  "name",
  "width",
  "height",
  "_position",
  "templateId",
  "finalImage",
  "uuid",
  "dataObjects",
  "typeUser",
  "clientId",
  "folderId",
  "createdAt",
  "updatedAt",
];
//export const ASSIGNMENT = ['id', 'name'];
