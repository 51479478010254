<template>
  <div class="preview-question__checkbox">
    <label class="preview-question__label"
      >{{ item.question }} <span v-if="item.required">*</span></label
    >

    <ul class="pa-0">
      <li
        v-for="answer in item.answers"
        :key="answer.id"
        class="list-style-none d-flex align-center mt-3"
      >
        <input
          type="checkbox"
          class="preview-question__item-checkbox mr-2"
          :id="'chk-' + item.id + '-' + answer.id"
        />
        <label
          :for="'chk-' + item.id + '-' + answer.id"
          @click="
            setAnswerCheckbox(
              item.id,
              item.idQuestionDb,
              item.question,
              answer.text,
              item.required
            )
          "
        ></label>
        <span v-if="!answer.other" class="preview-question__item-label">{{ answer.text }}</span>
        <span v-if="answer.other" class="preview-question__item-label"> <span class="fontOther"> other: </span>{{ item.other_answer }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PreviewQuestionsCheckbox",
  props: {
    item: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      answers: [],
    };
  },
  mounted(){
    console.log('preview---',this.item);
  },
  methods: {
    setAnswerCheckbox(id, idQuestion, question, answer, required) {
      // validation and push names
      const isRepeatedAnswer = this.answers.some((item) => {
        if (item === answer) return true;
      });
      if (isRepeatedAnswer) {
        this.answers = this.answers.filter((item) => item != answer);
        this.$emit(
          "checkbox-items",
          id,
          idQuestion,
          question,
          this.answers,
          required
        );
      } else {
        this.answers.push(answer);
        this.$emit(
          "checkbox-items",
          id,
          idQuestion,
          question,
          this.answers,
          required
        );
      }
    },
  },
};
</script>

<style lang="scss">
.fontOther{
  color: #2c3852;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
}
</style>
