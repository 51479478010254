var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "preview-question" },
    [
      _vm.question.type === 1
        ? _c("preview-questions-checkbox", {
            attrs: { item: _vm.question },
            on: { "checkbox-items": _vm.itemsAnswersCheckboxes },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.type === 2
        ? _c("preview-questions-radioButton", {
            attrs: { item: _vm.question },
            on: { "radio-button-item": _vm.itemAnswerRadioButton },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.type === 3
        ? _c("preview-questions-range", {
            attrs: { item: _vm.question },
            on: { "range-item": _vm.itemAnswerRange },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.type === 4
        ? _c("preview-questions-open-text", {
            attrs: { item: _vm.question },
            on: { "open-text": _vm.answerOpenText },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.type === 5
        ? _c("preview-questions-qr-code", { attrs: { item: _vm.question } })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.type === 6
        ? _c("preview-questions-iframe", { attrs: { item: _vm.question } })
        : _vm._e(),
      _vm._v(" "),
      _vm.question.type === 7
        ? _c("preview-questions-date", {
            attrs: { item: _vm.question },
            on: { "set-date-item": _vm.dateItem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }