import { apollo } from "../../../../plugins/vue-apollo";
import { FIND_SHAPES, FIND_SHAPES_WITH_KEYWORDS } from "../graphql/query";
import { messagePopUp } from "../../../../utils/utils";
export default {
  FIND: ({ commit }) => {
    apollo
      .query({
        query: FIND_SHAPES,
      })
      .then(({ data: { shapes } }) => {
        commit("SET_SHAPES", shapes);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  FIND_WITH_KEYWORDS: ({ commit }, { keywords }) => {
    apollo
      .query({
        query: FIND_SHAPES_WITH_KEYWORDS,
        variables: { keywords },
      })
      .then(({ data: { shapesByKeywords } }) => {
        commit("SET_SHAPES_BY_KEYWORD", shapesByKeywords);
      })
      .catch((err) => {
        console.error(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
};
