const getters = {
  getTitlePage: (state) => state.title_page,
  showDateRange: state => state.showDateRange,
  dateRange: state => state.dateRange,
  getContextBoard: state => state.contextBoard,
  getYourBoard: state => state.yourBoard,
  geoDrivers: state => state.geoDrivers,
  getDisabledMenuItem: state => state.disabledMenuItem,
  getDatePickerActive: state => state.datePickerActive,
  getOpenModal: state =>state.openModalWord,
  getName: state => state.name,
  getToken: state => state.token,
  getRefresh: state => state.refresh,
  getObjUsuario: state => state.objUsuario,
  getResetRefInput: state => state.isResetRefInput,
  getRespModalAlert: state => state.respModalAlert,
  getMsgNoAuth: state => state.msgNoAuth,
};

export default getters;