import Quill from "quill";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import store from "../../../index";
const quillConfig = {
  GET_ATTRIBUTE_BY_RANGE(index, length) {
    /**
     * DEVUELVE TODOS LOS ATRIBUTOS DE LA SELECCION QUE SE LE ENVIE ATRAVEZ DEL RANGO.
     *
     * @param {Number} index - INCIO DE LA SELECCION.
     * @param {Number} length - FIN DE LA SELECCION.
     * @returns {object}  - OBJETO CON LISTA DE ATRIBUTOS SEGUN EL RANGO SELECCIONADO
     */
    
    let quillInit = store.state.post.drag.quill;
    let format_ = quillInit.getFormat(index, length);
    format_.italic = format_.italic || false;
    format_.underline = format_.underline || false;
    format_.color = format_.color || '#000000';
    format_.bold = format_.bold || false;
    format_.align = format_.align || 'left';
    format_.textTransform = format_.textTransform || 'none';
    format_.textLineHeight = format_.textLineHeight || 'none';
    format_.list = format_.list || 'none';
    return format_;
  },
  REGISTER_QUIL_FONT_SIZE(size) {
    /**
     * REGISTRO DE UN NUEVO TAMAÑO DE FUENTE EN QUILL Y AGREGADO EN VARIABLE GLOBAL 'fontSizeList'.
     *
     * @param {string} size - TAMAÑO DE FUENTE.
     * @returns {}  - NO RETORNA.
     */

    store.dispatch("post/drag/ADD_FONT_SIZE", size);
    let whitelist = store.state.post.drag.fontSizeList;
    this.CONFIG_REGISTER_FONT_SIZE(whitelist);
  },
  SET_GLOBAL_FONT_SIZE(obj) {
    /**
     * AGREGA TAMAÑO DE FUENTE A UNA SELECCION DE QUILL SI EL VALOR DEL TAMAÑO DE FUENTE
     * NO SE ENCUENTRA EN LA LISTA DE FUENTES LO REGISTRA USANDO ('REGISTER_QUIL_FONT_SIZE')
     * 
     * @param {Object} obj - OBJETO QUE CONTIENE('TAMAÑO DE FUENTE', 'RANGO DE INICIO Y FIN DE LA SELECCION').
     * @returns {}  - NO RETORNA.
     */
    let state_ = store.state.post.drag;
    let { fontSize, range } = obj;
    let pixelValue = `${fontSize}px`;
    let includeValue = store.state.post.drag.fontSizeList.includes(pixelValue);
    let index_ = range.index;
    let length_ = range.length;
    if (!includeValue && fontSize > 0) this.REGISTER_QUIL_FONT_SIZE(pixelValue);
    let contenido = state_.quill.getText(index_, length_);
    // console.log('fontsizee', obj, contenido);
    state_.quill.formatText(index_, length_, "size", pixelValue);
  },
  CONFIG_REGISTER_UPPERCASE(arr) {
    /**
     * CONFIGURACION Y REGISTRO INICIAL DE OPCION UPPERCASE PARA EL EDITOR QUILL
     * 
     * @param {Array} arr - ARREGLO DE STRING QUE CONTIENE LA LISTA DE OPCIONES UPPERCASE.
     * @returns {}  - NO RETORNA.
     */

    let Parchment = Quill.import("parchment");
    let configUppercase = {
      scope: Parchment.Scope.INLINE,
      whitelist: arr,
    };
    let textTranformOptions =  new Parchment.ClassAttributor(
      "textTransform",
      "line",
      configUppercase
    );
    Quill.register(textTranformOptions, true);
  },
  CONFIG_REGISTER_LINE_HEIGHT(arr) {
    /**
     * CONFIGURACION Y REGISTRO INICIAL DE OPCION LINE-HEIGTH PARA EL EDITOR QUILL
     * 
     * @param {Array} arr - ARREGLO DE STRING QUE CONTIENE LA LISTA DE LINE-HEIGTH DEL EDITOR
     * @returns {}  - NO RETORNA.
     */

    let Parchment = Quill.import("parchment");
    let configLineheight = {
      scope: Parchment.Scope.INLINE,
      whitelist: arr,
    };
    let lineHeightOptions = new Parchment.ClassAttributor(
      "textLineHeight",
      "lineHeight",
      configLineheight
    );
    Quill.register(lineHeightOptions, true);
  },
  CONFIG_REGISTER_FONT_FAMILY(fontFamilyList) {
    /**
     * CONFIGURACION Y REGISTRO INICIAL DE OPCION DEL TIPO DE FUENTE
     * 
     * @param {Array} fontFamilyList - ARREGLO DE STRING QUE CONTIENE LA LISTA DE FUENTES DEL EDITOR
     * @returns {}  - NO RETORNA.
     */
    
    let FontAttributor = Quill.import("attributors/class/font");
    FontAttributor.whitelist = fontFamilyList;
    Quill.register(FontAttributor, true);
  },
   CONFIG_REGISTER_FONT_SIZE(fontsizeList) {
    /**
     * CONFIGURACION Y REGISTRO INICIAL DEL TAMAÑO DE FUENTE
     * 
     * @param {Array} fontsizeList - ARREGLO DE STRING QUE CONTIENE LA LISTA DE TAMAÑOS DE FUENTE
     * @returns {}  - NO RETORNA.
     */
    
    let Size = Quill.import("attributors/style/size");
    Size.whitelist = fontsizeList;
    Quill.register(Size, true);
  },
  RETURN_DELTAS_ORDERED_LIST(quill) {
    /**
     * PROCESA EL EL ARREGLO DELTA DEL QUILL CREADO PARA CREAR Y RETORNAR UN NUEVO ARREGLO DE OBJETOS 
     * OBJETOS QUE SON CREADOS A BASE DE TODOS LOS ELEMENTOS DEL EDITOR INCLUIDOS LOS SALTOS DE LINEA '\n'
     * 
     * @param {Object} quill - INSTANCIA DE QUILL CREADA QUE CONTIENE CONTENIDO DEL EDITOR
     * @returns {Array}  - 'currentGroup' - ARREGLO CON LISTA ORDENDA DE ELEMENTOS DEL EDITOR
     */
    
    let deltas = quill.getContents().ops;
    let deltasB = quill.getContents().ops;
    let elementRanges = [];
    let groupedDeltas = [];
    let currentGroup = [];
    let clearGroup = [];
    let currentPosition = 0;
    let currentPositionB = 0;
    let palabraActual = '';
    let palabras = [];

    deltas.forEach(op => {
      if (op.insert) {
        var texto = op.insert;
        for (var i = 0; i < texto.length; i++) {
          var char = texto[i];
          if (char.match(/\s/)) { // Verifica si es un espacio u otro separador
            if (palabraActual.length > 0) {
              palabras.push(palabraActual);
              palabraActual = '';
            }
          } else {
            palabraActual += char;
          }
        }
      }
    });
    // console.log("deltas ---length", quill.getLength());
    // console.log("deltas ---start", deltas);
    function isBreakLine(atributo) {
      // let valorTrimmed = atributo.trim();
      // return valorTrimmed === "";
      let regex = /^(\n){1,}/;

  // Testear la expresión regular en el valor del atributo
      return regex.test(atributo);
    }
    function esObjetoVacio(obj) {
      return Object.keys(obj).length === 0;
    }
    
    function esBreakline(obj) {
      return Object.values(obj).includes('\n');
    }
    
    function removerObjetosInvalidos(array) {
      const filtradoInicial = array.filter(obj => {
        return !esObjetoVacio(obj) && !esBreakline(obj);
      });
    
      // Eliminar objetos contiguos con `insert` y `breakline` en `true`
      const resultado = filtradoInicial.reduce((acc, obj, index, arr) => {
        const anterior = arr[index - 1];
        if (index === 0 || !(obj.insert && obj.breakline === true && anterior && anterior.insert && anterior.breakline === true)) {
          acc.push(obj);
        }
        return acc;
      }, []);
    
      return resultado;
    }
    let deltaProcess = [];
    let itemPr = {};
    let itemBR = {};
    let textValue = '';
    let brValue = '';
    for (let index = 0; index < deltas.length; index++) {
      const element = deltas[index];      
      element.breakline = isBreakLine(element.insert);
      let prev = deltas[index + 1] || null;
      if(!element.breakline){
        textValue += element.insert;
        itemPr.insert = textValue
        itemPr.breakline = false
        brValue = ''; 
        itemBR = {};
      }else{        
        deltaProcess.push(itemPr);
        textValue = '';
        itemPr = {};
        brValue += element.insert;
        itemBR.breakline = true
        itemBR.insert = brValue
        deltaProcess.push(itemBR);        
      }
    }
    let deltaFinal = removerObjetosInvalidos(deltaProcess);
    // console.log("deltas ---half pre proccess", deltaProcess);
    // console.log("deltas ---half proccess", deltaFinal);
    for (let index = 0; index < deltaFinal.length; index++) {
      const element = deltaFinal[index];
      let prev = deltas[index - 1] || null;
      let elementRange = {
        insert: element.insert,
        brInsert : null,
      };
      if(element.breakline){
        let insertLength = (element.insert.length - 1)
        for (let index = 0; index < insertLength; index++) {
          currentPosition++
          elementRange.brInsert = "\n";
          elementRange.start = currentPosition;
          elementRange.end = elementRange.start;
          elementRange.sequence = currentPosition;
          elementRange.attributes = this.GET_ATTRIBUTE_BY_RANGE(elementRange.start, elementRange.end);
          elementRanges.push(elementRange);
          elementRange = {};          
        }
      }else{
        if(prev != null) currentPosition++
        elementRange.start = currentPosition;
        elementRange.end = currentPosition + element.insert.length;
        currentPosition += element.insert.length;
        elementRange.sequence = currentPosition;
        elementRange.attributes = this.GET_ATTRIBUTE_BY_RANGE(elementRange.start, elementRange.end);
        elementRanges.push(elementRange);
        elementRange = {};
      }
    }
    elementRanges.map((e, index)=>{
      e.index = index;
    })
    return elementRanges;
  },
  PROCESS_QUILL_DOM_CONTENT(obj) {
    /**
     * PROCESA EL CONTENIDO DEL DOM DEL EDITOR QUILL PARA AGREGAR ATRIBUTOS Y ESTILOS EN LAS ETIQUETAS HTML 
     * ATRIBUTOS QUE AYUDARAN A MANEJAR LAS ETIQUETAS AGREGADAS AL MOMENTO DE EDITAR CON EL MENU DEL QUILL
     * 
     * @param {Object} obj - 'paragraphList' nodeList list de 
     * @returns {}  - NO RETORNA
     */

    let { paragraphList, parentItemIndex } = obj;
    // console.log('crear ids', paragraphList, parentItemIndex);
    let itemsCount = 0;
    // console.log('entro---------',paragraphList, parentItemIndex);
    paragraphList.forEach((item, index) => {
      item.removeAttribute("data-paragraph-id");
      item.removeAttribute("data-paragraph-index");
      
      switch (item.tagName) {
        case "P":
          itemsCount += 1;
          // console.log('on parrafo',{a: item,b: item.tagName,itemsCount});
          item.setAttribute(
            "data-paragraph-id",
            `p-${parentItemIndex}-${itemsCount - 1}`
          );
          item.setAttribute("data-paragraph-index", itemsCount - 1);
          createFontsizeByChild(item, false);
          break;
        case "OL":
          item.style.paddingLeft = "0";
          item.childNodes.forEach((child, index) => {
            itemsCount += 1;
            child.removeAttribute("data-paragraph-id");
            child.removeAttribute("data-paragraph-index");
            child.setAttribute(
              "data-paragraph-id",
              `p-${parentItemIndex}-${itemsCount - 1}`
            );
            child.setAttribute("data-paragraph-index", itemsCount - 1);
            createFontsizeByChild(child, true);
            // console.log('on lista ---',child,itemsCount);
          });
          break;

        default:
          // console.log('distinto',item.tagName);
          itemsCount += 1;
          // console.log('on parrafo',{a: item,b: item.tagName,itemsCount});
          item.setAttribute(
            "data-paragraph-id",
            `p-${parentItemIndex}-${itemsCount - 1}`
          );
          item.setAttribute("data-paragraph-index", itemsCount - 1);
          createFontsizeByChild(item, false);
          
          break;
      }
      function removeChild(element){
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      }
      function createFontsizeByChild(item, list) {
        let brSelectedIndex = parseInt(item.getAttribute("data-paragraph-index"));
        let elementTextSelected = store.getters["post/image/selectedDetail"];
        let quillEditor = store.getters["post/drag/quillEditor"];
        item.childNodes.forEach((it) => {
          (index > 0) ? index = index - 1 : index = 0;
          const prevElement = paragraphList[index];
          let setElement,prevDataFontFamily, prevDataFontSize, prevFontFamilyComputed, prevFontFontSizeComputed = null;
          if (prevElement) {
            if (prevElement.tagName == "OL") {
              const nodeList = prevElement.childNodes;
              setElement = prevElement.childNodes[nodeList.length - 1];
            } else {
              setElement = prevElement;
            }
            prevDataFontFamily = setElement.getAttribute("data-font-family");
            prevDataFontSize = setElement.getAttribute("data-font-size");
            prevFontFamilyComputed = getComputedStyle(setElement).fontFamily;
            prevFontFontSizeComputed = getComputedStyle(setElement).fontSize;
          }
          if (it.nodeName == "#text") {
            // console.log('---- texto recien ingresado solo entra una vez', it);
            let fontSize = null;
            const textoToAdd = it.nodeValue;
            let fontFamily = setElement.getAttribute("data-font-family") || 'Arial';
            elementTextSelected.styles.fontsList.map((f,index)=>{
              let fontSizeValue = f.font;
              if(fontSizeValue == undefined && prevDataFontSize != null) fontSizeValue = `${prevDataFontSize}px`;
              if(f.index === brSelectedIndex) fontSize = parseFloat(fontSizeValue.replace("px", ""));
            })
            fontFamily = fontFamily.replace(/"/g, "");
            fontFamily = `ql-font-${fontFamily}`;
            item.innerHTML = `<span class=${fontFamily} style="font-size:${fontSize}px;">${textoToAdd}</span>`;
          } else {
            if (it.tagName == "BR") {
              let font_ = null;
              if( elementTextSelected.styles.fontsList != undefined){
                // console.log('---- item br', elementTextSelected.styles);
                elementTextSelected.styles.fontsList.map((f,index)=>{
                  let fontSizeValue = f.font;
                  if(fontSizeValue == undefined && prevDataFontSize != null) fontSizeValue = `${prevDataFontSize}px`;
                  if(f.index === brSelectedIndex){
                    font_ = parseFloat(fontSizeValue.replace("px", ""));
                    item.style.fontSize = `${font_}px`;
                    item.setAttribute("data-font-size", font_);
                  }
                });                
              }
            } else {
              const imgElement = item.querySelector('img') || null;
              // console.log('procesa img', it,imgElement,item);
              if(imgElement != null) removeChild(item);
              let fontSize = null;
              if( elementTextSelected.styles.fontsList != undefined){
                elementTextSelected.styles.fontsList.map((f,index)=>{
                  let fontSizeValue = f.font;
                  if(fontSizeValue == undefined && prevDataFontSize != null) {fontSizeValue = `${prevDataFontSize}px`}
                  if(Array.isArray(fontSizeValue)) fontSizeValue = fontSizeValue[0];
                  // else sizeFormat = parseFloat(fontSizeFilter.font.replace("px", ""));
                  
                  if(f.index === brSelectedIndex) fontSize = parseFloat(fontSizeValue.replace("px", ""));
                  // if(f.index === brSelectedIndex) fontSize = parseFloat(f.font.replace("px", ""));
                })
              }else{
                fontSize = 24;
              }
              const aElement = it.querySelector('a') || null;
              if(aElement != null){
                let textSave = it.innerText;
                removeChild(it);
                it.innerHTML = textSave;
                // it.style.color = paragraphChangeTrue.attributes.color || '#000000'
              }
              //------------funcion para eliminar imagenes en el editor----------//

              

              const fontFamily = getComputedStyle(it).fontFamily;
              let font_ = fontSize || 24;
              
              const primeraFuente = fontFamily.split(",")[0].trim();
              let cadenaSinEntidad = primeraFuente.replace(/&quot;/g, '"').trim();
              cadenaSinEntidad = cadenaSinEntidad.replace(/\s/g, "");
              // let fontSizeListStore = store.state.post.drag.fontSizeList;
              // if(!fontSizeListStore.includes(cadenaSinEntidad)) cadenaSinEntidad = "Arial"
              // console.log('---- item normal', cadenaSinEntidad);
              // console.log('---- item normal', );
              item.setAttribute("data-font-size", font_);
              item.setAttribute("data-font-family",cadenaSinEntidad);
              item.style.fontSize = `${font_}px`;
              item.style.fontFamily = fontFamily;

              if (it.innerText == "") {
                if(index > 1){
                  let PrevFontSize = paragraphList[index - 1].getAttribute("data-font-size");
                  item.setAttribute("data-font-size", PrevFontSize);
                }
              }
            }
          }
        });
      }
    });
  },
};
export default quillConfig;
