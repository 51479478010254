import { apollo } from "../../../../plugins/vue-apollo";
import { FIND_QUESTIONS } from "../graphql/query";
export default {
  /*
   *SIDEBAR ==== MESSAGE
   */
  EVERY_MESSAGE: ({ commit }, message) => {
    commit("SET_MESSAGE_ITEMS", message);
  },
  SELECTED_MESSAGE_BUTTON: ({ commit, state }, button) => {
    const message = state.message;
    message.button.name = button.name;
    commit("SET_MESSAGE_ITEMS", message);
  },
  SET_MESSAGE_BUTTON_URL: ({ commit, state }, url) => {
    const message = state.message;
    message.button.url = url;
    commit("SET_MESSAGE_ITEMS", message);
  },
  SELECTED_IMAGE_LOGO: ({ commit, state }, image) => {
    const message = state.message;
    message.logoPreview = image;
    message.logo = image.location;
    commit("SET_MESSAGE_ITEMS", message);
  },
  REMOVE_IMAGE_LOGO: ({ commit, state }) => {
    const message = state.message;
    message.logoPreview = null;
    message.logo = "";
    commit("SET_MESSAGE_ITEMS", message);
  },
  /*
   *SIDEBAR ==== DESIGN
   */
  SET_DESIGN_COLOR: ({ commit, state }, { color, type }) => {
    const design = state.design;
    switch (type) {
      case "background":
        design.backcolor = color;
        break;
      case "buttonColor":
        design.buttoncolor = color;
        break;
      case "buttonTextColor":
        design.buttontextcolor = color;
        break;
    }
    commit("SET_DESIGN_ITEMS", design);
  },
  /*
   *SIDEBAR ==== QUESTION
   */
  FIND_QUESTIONS: ({ commit, dispatch }) => {
    apollo
      .query({
        query: FIND_QUESTIONS,
      })
      .then(({ data: { listQuestions } }) => {
        commit("SET_LIST_QUESTIONS", listQuestions);
      })
      .catch((err) => {
        dispatch("snackbar/errors", err, { root: true });
      });
  },
  CHANGE_TYPE_QUESTION: ({ commit }, { element, type }) => {
    console.log(" selected before", element);
    let refactorQuestion = null;
    switch (type) {
      case 1:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          required: element.required,
          question: !element.question ? "" : element.question,
          answers: !element.answers ? [] : element.answers,
          other: !element.other ? false : element.other,
          other_answer: !element.other_answer ? "" : element.other_answer,
          position: element.position,
        };
        break;
      case 2:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          required: element.required,
          question: !element.question ? "" : element.question,
          answers: !element.answers ? [] : element.answers,
          position: element.position,
        };
        break;
      case 3:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          required: element.required,
          question: !element.question ? "" : element.question,
          highrange: !element.highrange ? 10 : element.highrange,
          lowrange: !element.lowrange ? 1 : element.lowrange,
          reaction: !element.reaction
            ? { name: "stars", icon: "mdi-star" }
            : element.reaction,
          rangeReaction: !element.rangeReaction ? 1 : element.rangeReaction,
          position: element.position,
        };
        break;
      case 4:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          required: element.required,
          question: !element.question ? "" : element.question,
          answer: !element.answer ? "" : element.answer,
          position: element.position,
        };
        break;
      case 5:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          question: !element.question ? "" : element.question,
          required: element.required,
          qr: !element.qr ? { url: null, codeQr: null } : element.qr,
          position: element.position,
        };
        break;
      case 6:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          required: element.required,
          question: !element.question ? "" : element.question,
          iFrames: !element.iFrames ? [] : element.iFrames,
          position: element.position,
        };
        break;
      case 7:
        refactorQuestion = {
          id: element.id,
          idQuestionDb: !element.idQuestionDb ? null : element.idQuestionDb,
          type: type,
          required: element.required,
          question: !element.question ? "" : element.question,
          date: !element.date ? "" : element.date,
          position: element.position,
        };
        break;

      default:
        break;
    }

    commit("SET_TYPE_QUESTION", refactorQuestion);
  },
  SET_QR_CODE: ({ commit }, { code, id }) => {
    commit("SET_QR", { code, id });
  },
  SET_QR_DESCRIPTION: ({ commit }, { description, id }) => {
    commit("SET_QR_DESCRIPTION", { description, id });
  },
  SET_RANGE_REACTION: ({ commit }, { id, item }) => {
    const swiftIcon = {
      name: item.name,
      icon: item.icon,
    };
    commit("SET_CHANGE_REACTION", { id, swiftIcon });
  },
  SET_NEW_DATE: ({ commit }, { questionItemId, date }) => {
    const data = {
      id: questionItemId,
      date,
    };
    commit("SET_DATE", data);
  },
  SET_RANGE_NUMBER_REACTION: ({ commit }, { id, range }) => {
    commit("SET_NUMBER_REACTION", { id, range });
  },
  ADD_BACKGROUND_IN_ADDON: ({ commit }, item) => {
    const { thumbnails } = item;
    const currentItem = thumbnails.find((item) => item.quality === "SCREEN_4");
    let bgWidth = 520;
    let bgHeight = (bgWidth * currentItem.height) / currentItem.width;

    commit("SET_BACKGROUND_ADDON", item);
    commit("SET_WIDTH", bgWidth);
    commit("SET_HEIGHT", bgHeight);
    
    const positionX = ((bgWidth - 491) / 2) * -1;
    const positionY = ((bgHeight - 256) / 2) * -1;

    commit("SET_HORIZONTAL", positionX);
    commit("SET_VERTICAL", positionY);

    commit("SET_ORIGIN_POSITION", {
      width: bgWidth,
      height: bgHeight,
      x: positionX,
      y: positionY
    })

    commit('SET_CROPPED', {
      width: bgWidth,
      height: bgHeight,
      x: 0,
      y: 0
    })
  },
  CHANGE_POSITION_IN_CROP: ({ commit }, { x, y }) => {
    commit("SET_CROPPED_POSITION", { x, y });
  },
  CHANGE_SIZE_IN_CROP: ({ commit }, { width, height }) => {
    commit("SET_CROPPED_SIZE", {
      width, 
      height
    });
  },
  REMOVE_BACKGROUND_IN_ADDON: ({ commit }) => {
    commit("SET_BACKGROUND_ADDON", { path: null });
  },
  /* 	SET_BACKGROUND_IN_ADDON_POST: ({ commit }, item) => {
	
		commit('SET_BACKGROUND_ADDON', item)
		commit('SET_WIDTH', item.width);
		commit('SET_HEIGHT', item.height);
	}, */
  CHANGE_FLIP_IN_IMAGE: ({ commit }, { x, y }) => {
    commit("SET_TRANSFORM_FLIP", { x, y });
  },
  CHANGE_SIZE_IN_IMAGE: ({ commit }, { width, height }) => {
    commit("SET_WIDTH", width);
    commit("SET_HEIGHT", height);
  },
  CHANGE_POSITION_IN_IMAGE: ({ commit }, { x, y }) => {
    commit("SET_HORIZONTAL", x);
    commit("SET_VERTICAL", y);

    // commit('SET_CROPPED_POSITION', {
    //   x,
    //   y
    // })
  },
  CHANGE_CROP_IN_IMAGE: ({ commit }, { width, height, x, y }) => {
    commit('SET_CROPPED', {
      width,
      height,
      x,
      y
    })
  },
  CHANGE_STATUS_LOCK: ({ commit }, isLocked) => {
    commit("SET_IS_LOCKED", isLocked);
  },
  CHANGE_ROTATION: ({ commit }, degree) => {
    commit("SET_ROTATE", degree);
  },
  SET_NAME_ADDON: ({ commit }, name) => {
    commit("SET_NAME", name);
  },
  SET_EDIT_ADDON: ({ commit, dispatch }, item) => {
    // set a questions array
    const {
      dataObjects: { image },
    } = item;
    if (image.path) dispatch("ADD_BACKGROUND_IN_ADDON", image);

    let questions = [];
    if (item.questions.length > 0) {
      item.questions.forEach((el, index) => {
        questions.push({
          id: index + 1,
          idQuestionDb: el.id,
          type: el.question,
          required: el.required,
          question: el.textQuestion,
          position: el.position,
          ...el.options,
        });
      });
      commit("SET_ADDON_EDIT", { item, questions });
    } else {
      commit("SET_ADDON_EDIT", { item, questions });
    }
  },
};
