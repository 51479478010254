<template>
  <isc-modal-alert
    v-model="isModalAlert"
    :title="title"
    :paragraph="paragraph"
    :icon="icon"
    :iconColor="iconColor"
    :showBtn="showBtn"
    :showBtnClose="true"
    :showMainIcon="showMainIcon"
    @clicked="closeModal"
  />
</template>

<script>
import { mapState, mapActions } from "vuex";
import IscModalAlert  from "./IscModalAlert.vue";
import { alertSetting }  from "../../mixins/alertSetting";
export default {
  name: "CpGlobalModalAlert",
  components: {
    IscModalAlert
  },
  mixins: [ alertSetting ],
  data(){
    return {
      isModalAlert: false
    }
  },
  //toDo: create a 
  computed: {
    ...mapState("global/util", ["respModalAlert"]),
  },
  watch: {
    "respModalAlert": {
      immediate: true,
      handler(val){
        this.triggerModalAlert(val);
      }
    }
  },
  methods: {
    triggerModalAlert({type, message, showModal}) {
      this.isModalAlert = showModal;
      if(type === "error"){
        let modifiedErrMsg = message.replace("GraphQL error:", "")
        this.errorModal(modifiedErrMsg);
      }
    },
    closeModal(){
      this.isModalAlert = false;
    }
  },
};
</script>

<style scoped >
</style>
