var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "subtitle-account" }, [
        _vm.icon
          ? _c("div", {
              staticClass: "subtitle-icon",
              domProps: { innerHTML: _vm._s(_vm.icon) },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("span", [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]),
      ]),
      _vm._v(" "),
      _c("v-select", {
        staticClass: "design-select",
        class: { opacity_: _vm.disabled },
        attrs: {
          "background-color": _vm.backgroundColor,
          "hide-details": "",
          placeholder: "Loading ..",
          items: _vm.items,
          disabled: _vm.disabled,
          height: "35px",
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }