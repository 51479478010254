const getters = {
  getTitlePage: (state) => state.title_page,
  getDisabledMenuItem: (state) => state.disabledMenuItem,
  getName: (state) => state.name,
  getToken: (state) => state.token,
  getRefresh: (state) => state.refresh,
  getObjUsuario: (state) => state.objUsuario,
  loading: (state) => state.loading,
  editProject: (state) => state.editProject,
};

export default getters;
