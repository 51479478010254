import gql from "graphql-tag";
import { FOLDER } from "./variables";

export const CREATE_FOLDER = gql(`
  mutation createFolder($folder: FolderDTO!){
    folder: createFolder(folder: $folder) {
      ${FOLDER}
    }
  }
`);

export const UPDATE_FOLDER = gql(`
  mutation updateFolder($id:Int!,$folder:FolderDTO!){
    updateFolder(id:$id,folder:$folder){
      ${FOLDER}
    }
  }
`);
