<template>
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="fill"
      d="M0.320312 5.10156C0.109374 5.3125 0.109374 5.66406 0.320312 5.875L4.86719 10.4453C5.10156 10.6563 5.45312 10.6563 5.66406 10.4453L6.20312 9.90625C6.41406 9.69531 6.41406 9.34375 6.20312 9.10938L2.59375 5.5L6.20312 1.86719C6.41406 1.63281 6.41406 1.28125 6.20312 1.07031L5.66406 0.53125C5.45312 0.320313 5.10156 0.320313 4.86719 0.53125L0.320312 5.10156Z"
    />
  </svg>
</template>
<script>
export default {
  name: "IcoArrowRight",
  props: {
    fill: {
      type: String,
      default: () => {
        return "#ffffff";
      },
    },
  },
};
</script>
