import gql from "graphql-tag";
import { POST } from "../../templates/graphql/variables";

export const FIND_ALl_SAVED = gql(`
	query find($take: Int!, $page: Int!, $clientId: Int!){
		savedAll: posts(take: $take, page: $page, clientId: $clientId) {
			${POST}
		}
	}
`);
