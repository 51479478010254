export default {
  UPDATE_IMAGE_SELECTED: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    uuid
  ) => {
    commit("SET_SELECTED_IMAGE", uuid);
    if (uuid) {
      const image = drag.images.find((i) => i.uuid === uuid);
      image.blocked = image.blocked || false;
      commit("post/sideControls/SET_IS_BLOCKED", image.blocked, { root: true });
      commit("SET_SELECTED_IMAGE_DETAIL", image);
    } else {
      commit("SET_SELECTED_IMAGE_DETAIL", null);
    }
  },
  UPDATE_SELECTED: ({ commit }, uuid) => {
    commit("SET_SELECTED_IMAGE", uuid);
  },
  SELECT_IMAGE_IN_CANVAS: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    uuid
  ) => {
    if (uuid) {
      const image = drag.images.find((i) => i.uuid === uuid);

      commit("SET_SELECTED_IMAGE", uuid);
      commit("SET_SELECTED_IMAGE_DETAIL", image);
    }
  },
};
