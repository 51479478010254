const getters = {
    canUndo: state => {
        return !(state.historyIndex >= 0)
    },
    canRedo: state => {
        return !(state.historyIndex < (state.history.length - 1) && state.history.length > 0)
    },
    history: state => state.history,
    historyIndex: state => state.historyIndex
}

export default getters;