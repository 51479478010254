export default {
  SET_SAVED_ALL: (state, savedAll) => {
    state.savedAll = savedAll.map((el) => {
      return el;
    });
  },
  SET_SAVED_BY_KEYWORD: (state, savedByKeywords) => {
    state.savedByKeywords = savedByKeywords;
  },
  SET_CLEAN_POSTS: (state, isClean) => {
    state.cleanSavedPost = isClean;
  },
  SET_ACTIVE: (state, isActive) => {
    state.active = isActive;
  },
  RESET_SAVED_POST: (state) => {
    state.savedAll = [];
  },
};
