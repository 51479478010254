var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "21",
        viewBox: "0 0 20 21",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.fill,
          d: "M9.96094 0.539062H7.46094V3.03906H9.96094V0.539062ZM0 3.03906V5.53906H2.5V3.03906H0ZM5 0.539062H2.5V3.03906H5V0.539062ZM7.5 10.5391V13.0391H10V10.5391H7.5ZM0 8.03906V10.5391H2.5V8.03906H0ZM14.9609 0.539062H12.4609V3.03906H14.9609V0.539062ZM19.9609 0.539062H17.4609V3.03906H19.9609V0.539062ZM5 10.5391H2.5V13.0391H5V10.5391ZM19.9609 18V15.5H17.4609V18H19.9609ZM19.9609 13V10.5H17.4609V13H19.9609ZM14.9609 20.5H17.4609V18H14.9609V20.5ZM19.9609 8.03906V5.53906H17.4609V8.03906H19.9609ZM5 20.5H7.5V18H5V20.5ZM0 20.5H2.5V18H0V20.5ZM9.96094 20.5H12.4609V18H9.96094V20.5ZM0 13.0391V15.5391H2.5V13.0391H0ZM12.4609 5.53906V3.03906H9.96094V5.53906H12.4609ZM9.96094 10.5391H12.4609V8.03906H9.96094V10.5391ZM7.46094 15.5391V18.0391H9.96094V15.5391H7.46094ZM12.4609 13.0391H14.9609V10.5391H12.4609V13.0391ZM12.4609 8.03906H14.9609V5.53906H12.4609V8.03906ZM12.4609 15.5391V18.0391H14.9609V15.5391H12.4609ZM2.5 15.5391V18.0391H5V15.5391H2.5ZM7.5 5.53906V3.03906H5V5.53906H7.5ZM14.9609 15.5391H17.4609V13.0391H14.9609V15.5391ZM9.96094 8.03906V5.53906H7.46094V8.03906H9.96094ZM14.9609 3.03906V5.53906H17.4609V3.03906H14.9609ZM9.96094 13.0391V15.5391H12.4609V13.0391H9.96094ZM7.46094 8.03906H5V10.5391H7.5L7.46094 8.03906ZM14.9609 10.5391H17.4609V8.03906H14.9609V10.5391ZM5 5.53906H2.5V8.03906H5V5.53906ZM5 13.0391V15.5391H7.5V13.0391H5Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }