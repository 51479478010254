import gql from "graphql-tag";
//import { FOLDER } from "../../../../store/post/folders/graphql/variables";
import { QUESTION } from "./variables";

export const FIND_QUESTIONS = gql(`
  query listQuestions{
   listQuestions {
			${QUESTION}
    }
  }
`);
