import gql from "graphql-tag";
import { IMAGE } from "./variables";

export const CREATE_IMAGES = gql(`
  mutation createImage($image: ImagesDTO!, $object: ObjectsDTO!){
    image: createImage(image: $image, object: $object) {
      ${IMAGE}
    }
  }
`);

/* export const UPDATE_TNC = gql(`
  mutation update($id: Int!,$data: TermAndConditionDTO!){
    updateTNC(id: $id, data: $data) {
      ${TNC}
      assignment {
        ${ASSIGNMENT}
      }
    }
  }
`);

export const DELETE_TNC = gql(`
  mutation delete($id: Int!) {
    deleteTNC(id: $id)
  }
`); */
