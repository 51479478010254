import { apollo } from "../../../../plugins/vue-apollo";
import { FIND_TEXTS, FIND_TEXT_BY_KEYWORDS } from "../graphql/query";
import { messagePopUp } from "@design/styleguide";

export default {
  FIND: ({ commit }) => {
    apollo
      .query({
        query: FIND_TEXTS,
      })
      .then(({ data: { texts } }) => {
        commit("SET_TEXTS", texts);
      })
      .catch((err) => {
        console.log(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
  FIND_WITH_KEYWORDS: ({ commit }, { keywords }) => {
    apollo
      .query({
        query: FIND_TEXT_BY_KEYWORDS,
        variables: { keyword: keywords },
      })
      .then(({ data: { textsByKeywords } }) => {
        commit("SET_TEXTS", textsByKeywords);
      })
      .catch((err) => {
        console.log(err);
        messagePopUp("Oops...", "Something went wrong!", "error", 1500);
      });
  },
};
