const state = {
    title_page: '',
    /* dateRange: {
        startDate: new Date().setDate(new Date().getDate() - 15),
        endDate: new Date()
    }, */
    openModalWord: false,
    datePickerActive: false,
    contextBoard: '',
    yourBoard: true,
    geoDrivers: null,
    disabledMenuItem: true,
    dateRange: [
        new Date(new Date().setDate(new Date().getDate() - 14)),
        new Date(new Date().setDate(new Date().getDate())),
    ],
    name: 'User',
    token: '',
    refresh: '',
    objUsuario: {},
    isResetRefInput: false,
    isVisible: false,
    respModalAlert: {
        type: null,
        message: null,
        showModal: false,
    },
};

export default state;