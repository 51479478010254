export default {
  CHANGE_FILL_IN_SHAPE: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { fill, uuid }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        const color = item.fillDefault;
        return {
          ...item,
          figure: item.figure.replaceAll(color, fill),
          fillDefault: fill,
        };
      } else {
        return item;
      }
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
  },
  CHANGE_STROKE_IN_SHAPE: (
    {
      commit,
      rootState: {
        post: { drag },
      },
    },
    { stroke: { color, width }, uuid }
  ) => {
    const images = drag.images.map((item) => {
      if (item.uuid === uuid) {
        return {
          ...item,
          strokeWidth: width,
          stroke: color
        };
      } else {
        return item;
      }
    });

    commit("post/drag/UPDATE_IMAGE", images, { root: true });
    
  },
};
