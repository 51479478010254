import create from "./create/index";
import folders from "./folders/index";
import zoom from "./zoom/index";
import saved from "./saved/index";
import question from "./question/index";
import lead from "./lead/index";
import changes from "./changes/index";
import editimage from "./editimage/index";

export default {
  modules: {
    create,
    folders,
    zoom,
    saved,
    question,
    lead,
    changes,
    editimage
  },
  namespaced: true,
};
