export default {
  SET_IS_ACTIVE: (state, isActive) => {
    state.isActive = isActive;
  },
  SET_IS_ACTIVE_HORIZONTAL: (state, isActiveHorizontal) => {
    state.isActiveHorizontal = isActiveHorizontal;
  },
  SET_IS_ACTIVE_VERTICAL: (state, isActiveVertical) => {
    state.isActiveVertical = isActiveVertical;
  },
  SET_IS_ACTIVE_SECONDARY: (state, isActiveSecondary) => {
    state.isActiveSecondary = isActiveSecondary;
  },
};
