const actions = {
  addTitle({ commit }, title) {
    commit("SET_TITLE_PAGE", title);
  },
  disabledMenuItem({ commit }, state) {
    commit("CHANGE_STATE_MENUSIDEBAR", state);
  },
  setName({ commit }, value) {
    commit("SET_NAME", value);
  },
  setToken({ commit }, value) {
    commit("SET_TOKEN", value);
  },
  setRefresh({ commit }, value) {
    commit("SET_REFRESH", value);
  },
  setObjUsuario({ commit }, obj) {
    commit("SET_OBJ_USUARIO", obj);
  },
  ENABLE_EDIT_PROJECT({ commit }, isEdit) {
    commit("SET_EDIT_PROJECT", isEdit);
  },
};

export default actions;
