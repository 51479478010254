import gql from "graphql-tag";
import { UPLOAD } from "./variables";
import { OBJECT } from "../../backgrounds/graphql/variables";
import { COMMON_VAR, POST } from "../../../addons/folders/graphql/variables";

export const FIND_UPLOADS = gql(`
  query ($take:Int, $page:Int, $clientId:Int!,$type:String) {
    uploads(take: $take, page: $page, clientId: $clientId,type:$type) {
      ${UPLOAD}
      header: objects {
        ${OBJECT}
      }
    }
  }
`);

export const FIND_WITH_KEYWORDS = gql(`
query ($keyword:[String!]!){
  imagesByKeywords(keyword:$keyword) {
    id
    uuid
    name
    createdAt
    updatedAt
    keywords{
      id
      name_en
      name_es
    }
  }
}
`);

export const FIND_POSTS_BY_FOLDER_ID = gql(`
  query postsByFolderPaginate($page: Int!, $take: Int!, $folderId: Int!, $clientId:Int!) {
    folders: postsByFolderPaginate(page:$page, take: $take, folderId: $folderId, clientId:$clientId) {
			${POST}
			${COMMON_VAR}
    }
  }
`);
export const FIND_UPLOADS_BY_KEYWORDS = gql(`
  query UploadsByKeywords($take:Int, $page:Int, $keywords:[String!]!, $typeUser: String, $clientId: Int, $type: String){
    uploads: uploadsByKeywords(take: $take, page: $page, keywords: $keywords, typeUser: $typeUser, clientId: $clientId, type: $type) {
      ${UPLOAD}
      header: objects {
        ${OBJECT}
      }
    }
  }
`);
