export default {
  latestUserAddons: [],
  latestAdminAddons: [],
  foldersWithAddons: {
    count: 0,
    data: [],
  },
  leads: [],
  isLoading: {
    latestUserAddons: false,
    foldersWithAddons: false
  }
};
