export default {
  element: null,
  elementsaved: null,
  background: "",
  addon: {
    addonUuid: null,
    addonId: null,
    name: "",
    question: [],
    width: 0,
    height: 0,
    path: null,
    x: 0,
    y: 0,
    transform: {
      scale: {
        x: 1,
        y: 1,
      },
      rotate: 0,
    },
    cropped: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
    origin: {
      width: 0,
      height: 0,
      x: 0,
      y: 0
    },
    locked: true
  }, // It gathers all rest of attributes when it creates addon
  addonsaved: {
    transform: {
      scale: {
        x: 1,
        y: 1,
      },
      rotate: 0,
    },
  },
  questions: [],
  answers: [],
  /* qr: {
		link: null,
		description: null,
  }, */
  message: {
    logoPreview: null,
    logo: "",
    title: "",
    description: "",
    confirmation: "",
    button: {
      name: "Send",
      url: "",
    },
  },
  design: {
    backcolor: "#F3F4F7",
    buttoncolor: "#00a5ff",
    buttontextcolor: "#FFFFFF",
  },
  listQuestions: [],
};
