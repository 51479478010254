var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "16",
        viewBox: "0 0 14 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.1562 8.375H4.89062V5.35742C4.89062 4.21484 5.79883 3.24805 6.9707 3.21875C8.14258 3.21875 9.10938 4.18555 9.10938 5.32812V5.79688C9.10938 6.20703 9.40234 6.5 9.8125 6.5H10.75C11.1309 6.5 11.4531 6.20703 11.4531 5.79688V5.32812C11.4531 2.86719 9.43164 0.875 6.9707 0.875C4.50977 0.904297 2.54688 2.92578 2.54688 5.38672V8.375H1.84375C1.05273 8.375 0.4375 9.01953 0.4375 9.78125V14.4688C0.4375 15.2598 1.05273 15.875 1.84375 15.875H12.1562C12.918 15.875 13.5625 15.2598 13.5625 14.4688V9.78125C13.5625 9.01953 12.918 8.375 12.1562 8.375ZM8.17188 12.8281C8.17188 13.502 7.64453 14 7 14C6.32617 14 5.82812 13.502 5.82812 12.8281V11.4219C5.82812 10.7773 6.32617 10.25 7 10.25C7.64453 10.25 8.17188 10.7773 8.17188 11.4219V12.8281Z",
          fill: _vm.fill,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }