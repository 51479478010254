
import axios from "axios";
import { getCredentialsHeader } from "../utils/utils"
import { setErrorAndShowModal } from "./handleErrorModal";
//import { handleError } from "../lib/handleError";

//* ======= SET PRODUCTION VARIABLES ==========
//* this url works form back-login(back-end)
const base_url_login = process.env.API_BACK_MONITOR + "/login/";
//* this url works form back-overview(back-end)
//const base_url_overview = `${process.env.API_BACK_MONITOR}/monitor/api/v1`;
//* this url works form back-campaign(back-end)
//const base_url_campaign = `${process.env.API_BACK_MONITOR}/campaign/api/v1`;

//* ======= SET LOCAL VARIABLES ============= 
//const base_url_login = "http://localhost:3000/";
//const base_url_overview = 'http://localhost:3000/api/v1'
//const base_url_campaign = 'http://localhost:3000/api/v1' 
export async function handlerRespAxiosMonitorLogin(operationType, url, data = null){
  let jsonAxios = {};
  try {
    if(operationType === "get"){
      jsonAxios = {
        method: 'get',
        url: base_url_login + url,
        params: data,
        headers: getCredentialsHeader(),
      }
    } else {
      jsonAxios = {
        method: operationType,
        url: base_url_login + url,
        data,
        headers: getCredentialsHeader()
      }
    }
    return await axios(jsonAxios)
  } catch (error) {
    console.log("[Error] in function: handler Response Axios LOGIN");
    setErrorAndShowModal(error);
    throw error;
  }
}
//* this function is for lambdas or apis without credentials
export async function simpleHandlerRespAxios(operationType, url, data ){
  let jsonAxios = {};
  try {
    if(operationType === "get"){
      jsonAxios = {
        method: operationType,
        url,
        params: data
      }
    } else {
      jsonAxios = {
        method: operationType,
        url,
        data,
      }
    }
    return await axios(jsonAxios)
  } catch (error) {
    console.log("[Error] in function: simpleHandlerRespAxios ", error);
    throw error;
  }
}

