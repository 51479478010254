 export const uploadFunctionsMixin = {
  created() {
    
  },
  methods: {
    validateMaxUploadsFiles(maxFiles, files) {
      let msgError= "";
      if (files.length > maxFiles) {
        msgError = `You can only upload a maximum of ${maxFiles} files`;
        this.$emit("msg-error", msgError);
        return true;
      }
      return false;
    } 
  },
};