import { cloneDeep } from 'lodash'

const actions = {
  CAN_REDO: ({ commit }, index) => {
    commit('CAN_REDO', index)
  },
  CAN_UNDO: ({ commit }, index) => {
    commit('CAN_UNDO', index)
  },
  REDO: ({ commit, getters }) => {
    const lstHistories = getters.newHistory
    const history = lstHistories[getters.historyIndex + 1]
    commit('UPDATE_INDEX', 1)
    commit('post/drag/REDO_UNDO_POST', history.images, { root: true })
    if (history.background)
      commit('post/background/SET_LIENZO_BACKGROUND', history.background, {
        root: true,
      })
    if (history.type)
      commit('post/background/SET_TYPE_BACKGROUND', history.type, {
        root: true,
      })
  },
  UNDO: ({ commit, getters }) => {
    const lstHistories = getters.newHistory
    const history = lstHistories[getters.historyIndex - 1]
    commit('UPDATE_INDEX', -1)
    commit('post/drag/REDO_UNDO_POST', history ? history.images : [], {
      root: true,
    })
    commit(
      'post/background/SET_LIENZO_BACKGROUND',
      history ? history?.background : '',
      {
        root: true,
      },
    )
    commit(
      'post/background/SET_TYPE_BACKGROUND',
      history ? history?.type : '',
      {
        root: true,
      },
    )
  },
  SAVE_CHANGE_HISTORY: ({ commit, rootState }) => {
    commit(
      'global/redoundo/ADD_HISTORY',
      cloneDeep({
        images: rootState.post.drag.images,
        background: rootState.post.background.background,
        type: rootState.post.background.type,
      }),
      { root: true },
    )
  },
}

export default actions
