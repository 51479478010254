
import Store from "../store/index";
import { MSG_ERRORS_NO_AUTH_SESSION, MSG_ERRORS_INVALID_TOKEN } from "./constants";
export const setErrorAndShowModal = (error) => {
  const msgError = error.response?.data?.message || "Unauthorized";
  //* SESSIONS
  if(MSG_ERRORS_NO_AUTH_SESSION.includes(msgError)){
    const msg = {
      title: "Invalid Session",
      message: `${msgError}, it will redirect to login.`,
    }
    Store.dispatch("global/util/MSG_NO_AUTH", msg);
    Store.dispatch("global/util/TRIGGER_MODAL_NO_AUTH");  
   //* TOKEN 
  } else if(MSG_ERRORS_INVALID_TOKEN.includes(msgError)){
    const msg = {
      title: "Invalid Token",
      message: `${msgError}, it will redirect to login.`,
    }
    Store.dispatch("global/util/MSG_NO_AUTH", msg);
    Store.dispatch("global/util/TRIGGER_MODAL_NO_AUTH");
  } else if (msgError.includes("Unauthorized")) {
    Store.dispatch("global/util/TRIGGER_MODAL_NO_AUTH");
  }
};