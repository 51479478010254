const actions = {
  setName({ commit }, value) {
    commit("SET_NAME", value);
  },
  setToken({ commit }, value) {
    commit("SET_TOKEN", value);
  },
  setRefresh({ commit }, value) {
    commit("SET_REFRESH", value);
  },
  setObjUsuario({ commit }, obj) {
    commit("SET_OBJ_USUARIO", obj);
  }
}
export default actions;