export default {
  addon: [],
  latesPosts: [],
  foldersWithPosts: {
    count: 0,
    data: [],
  },
  isLoading: {
    latestPosts: false,
    foldersWithPosts: false
  }
};
